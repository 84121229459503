import React from 'react'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import format from "date-fns/format"
import parseISO from "date-fns/parseISO"
import ru from "date-fns/locale/ru"
import { getPreviewWeekDays } from '../utils/week'
import styled from 'styled-components'
import PlanBuilderDayDroppable from './Droppable/PlanBuilderDayDroppable'
import { Paragraph } from '../components/UI/Form'
import { getWeekHead } from '../utils/time'

type PlanBuilderWeekProps = {
  workouts: Array<WorkoutSchedule>
  onDragStart?: (result: DropResult) => void
  onDragEnd?: (result: DropResult) => void
  isDropDisabled?: (workouts: Array<WorkoutSchedule>, date: string) => boolean
}

const Week = styled.div`
  display: flex;
  justify-content: space-between;
`

const PlanBuilderWeekWithDnD = ({ workouts, onDragStart, onDragEnd, isDropDisabled }: PlanBuilderWeekProps) => {
  const firstDateOfWeek = getWeekHead(workouts[0].date)
  const weekDays = getPreviewWeekDays(firstDateOfWeek)

  function getDayWorkouts(dayDate: string): Array<WorkoutSchedule> {
    return workouts.filter(({ date }) => date === dayDate)
  }

  if (!onDragStart || !onDragEnd || !isDropDisabled) {
    console.error('Component PlanBuilderWeekWithDnD doesn\'t receive "onDragStart" function in props')
    return <Paragraph>Error occurred</Paragraph>
  }

  return (
    <DragDropContext
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
    >
      <Week>
        {weekDays.map(({ date }) => {
          const workouts = getDayWorkouts(date)
          const dropDisabledStatus = isDropDisabled(workouts, date)
          const title = format(parseISO(date), 'EEEE', {locale: ru})
          return (
            <PlanBuilderDayDroppable
              key={date}
              isDropDisabled={dropDisabledStatus}
              title={title}
              id={date}
              workouts={workouts}
            />
          )
        })}
      </Week>
    </DragDropContext>
  )
}

export default PlanBuilderWeekWithDnD
