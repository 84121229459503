import React, {memo, useState} from 'react';
import {DragDropContext, DropResult} from 'react-beautiful-dnd';
import {useMutation} from '@apollo/client';
import parseISO from 'date-fns/parseISO';
import isSameDayFns from 'date-fns/isSameDay';
import {WithDnDProps} from './types';
import {UPDATE_WORKOUT} from '../graphql';

export const WithDnDContext: React.FC<WithDnDProps> = memo(function WithDnDContext(props) {
  const {children, weekWorkouts} = props

  const [drop, setDropState] = useState<DropState>({
    isDropping: false,
    draggableWorkoutId: null,
    sourceDate: null,
    droppingWorkoutSportId: null
  })

  const [updateWorkout] = useMutation(UPDATE_WORKOUT)

  function onDragStart(result: DropResult) {
    const { draggableId, source } = result
    const { droppableId } = source
    const [{ sport }] = weekWorkouts.filter(({id}: WorkoutDay) => +id === +draggableId)

    setDropState({
      isDropping: true,
      draggableWorkoutId: draggableId,
      sourceDate: parseISO(droppableId),
      droppingWorkoutSportId: +sport.id
    })
  }

  function onDragEnd(result: DropResult) {
    const { destination, source } = result

    setDropState({
      isDropping: false,
      draggableWorkoutId: null,
      sourceDate: null,
      droppingWorkoutSportId: null
    })

    if (!destination) {
      return
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return
    }
    if (source.droppableId === destination.droppableId) {
      const dayWorkouts = weekWorkouts.filter(({ date }) => (
        drop.sourceDate !== null && isSameDayFns(parseISO(date), drop.sourceDate)
      ))
      const changedWorkoutOrder = dayWorkouts.map((workout) => ({
        ...workout,
        order: workout.order === 1 ? 0 : 1
      }))
      changedWorkoutOrder.forEach((workout) => {
        const updatingData = {
          order: workout.order,
          completed: false
        }
        updateWorkout({
          variables: {
            input: {
              workoutId: workout.id,
              ...updatingData
            }
          },
          optimisticResponse: {
            __typename: "Mutation",
            updateWorkout: {
              workout: {
                id: workout.id,
                ...updatingData,
                __typename: "WorkoutDay"
              },
              __typename: "UpdateWorkout"
            }
          }
        })
      })
      return
    }

    let affectedWorkouts: Array<WorkoutDay> = []
    const sourceDayWorkouts = weekWorkouts.filter(({ date }) => (
      drop.sourceDate !== null && isSameDayFns(parseISO(date), drop.sourceDate)
    ))
    const destinationDayWorkouts = weekWorkouts.filter(({date}) => date === destination.droppableId)

    sourceDayWorkouts.forEach((workout) => (affectedWorkouts.push({
      ...workout,
      order: source.index === 1 ? 0 : 1
    })))

    destinationDayWorkouts.forEach((workout) => affectedWorkouts.push({
      ...workout,
      order: destination.index === 1 ? 0 : 1
    }))

    if (drop.draggableWorkoutId) {
      affectedWorkouts.forEach((workout) => {
        const updatingData = {
          date: `${workout.id}` === drop.draggableWorkoutId ? destination.droppableId : workout.date,
          order: `${workout.id}` === drop.draggableWorkoutId ? destination.index : workout.order,
          completed: `${workout.id}` === drop.draggableWorkoutId ? false : workout.completed
        }
        updateWorkout({
          variables: {
            input: {
              workoutId: workout.id,
              ...updatingData
            }
          },
          optimisticResponse: {
            __typename: "Mutation",
            updateWorkout: {
              workout: {
                id: workout.id,
                ...updatingData,
                __typename: "WorkoutDay",
              },
              __typename: "UpdateWorkout"
            }
          }
        })
      })
    }
  }

  function isDropDisabled(workouts: Array<WorkoutDay>, weekDay: Date) {
    if (drop.sourceDate) {
      const isSameDay = isSameDayFns(weekDay, drop.sourceDate)
      const hasSameTypedWorkouts = workouts.some(({ sport }:WorkoutDay) => +sport.id === drop.droppingWorkoutSportId)
      if (workouts.length !== 0 && workouts[0].isPaywall) {
        return true
      }
      if (hasSameTypedWorkouts && !isSameDay) {
        return true
      }

      return drop.isDropping && !isSameDay && workouts.length > 1;
    }
    return false
  }

  return (
    <DragDropContext
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
    >
      {children({isDropDisabled})}
    </DragDropContext>
  )
})
