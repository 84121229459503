import React, { SyntheticEvent } from 'react'
import styled from 'styled-components'
import ru from 'date-fns/locale/ru'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
import { Paragraph, Row, Col } from '../../UI/Modal'
import Skeleton from 'react-loading-skeleton'

type StatusProps = {
  switchTab: (event: SyntheticEvent<Element, Event> | string) => void
  user?: User
}

const Title = styled.h2`
  font-size: .875rem;
  font-weight: 500;
  color: #4F4F4F;
`

const Subtitle = styled.h3`
  color: #828282;
  font-weight: 500;
  font-size: .75rem;
  text-transform: uppercase;
  padding: 0 0 5px;
  margin: 0;
`

const LocalButton = styled.button`
cursor: pointer;
  border: none;
  background: none;
  padding: 0;
  text-transform: uppercase;
  color: #BDBDBD;
  text-decoration: underline;
  font-size: .6750rem;
  &:hover {
    border: none;
    text-decoration: none;
    color: #3E3E3E; 
  }
`

const HistoryList= styled.ul`
  max-height: 300px;
  overflow-y: auto;
`

const HistoryItem = styled.li`
  display: flex;
  justify-content: space-between;
  padding: 8px 10px;
  &:nth-child(odd) {
    background-color: #FFFFFF;
  }
  &:nth-child(even) {
    background-color: #F8F8F8;
  }
  & span {
    font-size: .75rem;
    color: #4F4F4F;
  }

  & span:nth-child(2) {
    flex: 1 1 120px;
    padding-left: 52px;
  }
  
  & span:last-child {
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-end;
  }
`

const Buttons = styled.div`
  display: flex;
  & > button:nth-child(2) {
    margin-left: 15px;
  }
`

const Status = ({ switchTab, user }: StatusProps) => {
  if (!user) {
    return <StatusSkeleton />
  }

  const { paymentSubscription, paymentMethod, paymentTransactions, giftCertificates } = user
  const plan = paymentSubscription?.plan

  if (!paymentSubscription) {
    return (
      <>
        <Title>Ошибка</Title>
        <Paragraph>Данные о текущей подписке недоступны.</Paragraph>
      </>
    )
  }

  function renderPlanStatus(status: string) {
    switch (status) {
      case 'ACTIVE': {
        return 'Активна'
      }
      case 'EXPIRING': {
        return 'Истекает'
      }
      case 'ENDED': {
        return 'Закончилась'
      }
      case 'CAPTURING': {
        return 'В обработке'
      }
      case 'SUSPENDED': {
        return 'Отменена'
      }
      default: {
        return ''
      }
    }
  }

  function renderButtons() {
    if (!paymentSubscription) {
      return null
    }
    if (paymentSubscription.status === 'ENDED') {
      return <LocalButton type='button' onClick={switchTab} data-tab='activate'>Активировать подписку</LocalButton>
    }

    if (paymentSubscription.status === 'ACTIVE') {
      return (
        <Buttons>
          <LocalButton type='button' onClick={switchTab} data-tab='plan'>Изменить план</LocalButton>
          <LocalButton type='button' onClick={switchTab} data-tab='cancel'>Отменить подписку
          </LocalButton>
        </Buttons>
      )
    }
    if (paymentSubscription.status === 'EXPIRING') {
      return <LocalButton type='button' onClick={switchTab} data-tab='activate'>Продлить подписку</LocalButton>
    }    
  }

  return (
    <>
      <Row>
        <Col>
          <Row>
            <Col>
              <Subtitle>План</Subtitle>
              <Paragraph> {plan ? plan.title : `Пробный период`} ({renderPlanStatus(paymentSubscription.status)})</Paragraph>
              {renderButtons()}
            </Col>
          </Row>
          {(paymentSubscription.status === 'EXPIRING' || paymentSubscription.status === 'ENDED') && (
            <Row>
              <Col>
                <Subtitle>Активна до</Subtitle>
                <Paragraph>{format(parseISO(paymentSubscription.nextPaymentDate), 'EEEE, dd MMM yyyy', {locale: ru})}</Paragraph>
              </Col>
            </Row>
          )}
          {plan && paymentSubscription.status === 'ACTIVE' && (
            <Row>
              <Col>
                <Subtitle>Следующее списание</Subtitle>
                <Paragraph>{format(parseISO(paymentSubscription.nextPaymentDate), 'iiii, dd MMM yyyy', {locale: ru})}</Paragraph>
              </Col>
            </Row>
          )}
          {paymentMethod && (
            <Row>
              <Col>
                <Subtitle>Способ оплаты</Subtitle>
                <Paragraph>Номер карты {paymentMethod.cardType} заканчивается на {paymentMethod.cardNumber}</Paragraph>
                <LocalButton type='button' onClick={switchTab} data-tab='paymentData'>Изменить платежные данные</LocalButton>
              </Col>
            </Row>
          )}
        </Col>
        <Col>
          {paymentTransactions && paymentTransactions.length > 0 && (
            <Row>
              <Col>
                <Subtitle>История оплаты</Subtitle>
                <HistoryList>
                  {paymentTransactions.map((transaction) => (
                    <HistoryItem key={transaction.id}>
                      <span>{transaction.date ? format(parseISO(transaction.date), 'dd.MM.yyyy') : 'No date'}</span>
                      <span>{transaction.amount ? `${transaction.amount.value} ${transaction.amount.currency}` : 'No amount'}</span>
                      <span>{transaction.status?.description ? transaction.status.description : 'No status'}</span>
                    </HistoryItem>
                  ))}
                </HistoryList>
              </Col>
            </Row>
          )} 
        {giftCertificates && giftCertificates.length > 0 && (
          <Row>
            <Col>
              <Subtitle>Сертификаты</Subtitle>
              <HistoryList>
                {giftCertificates.map((cert) => (
                  <HistoryItem key={cert.id}>
                    <span>{cert.date ? format(parseISO(cert.date), 'dd.MM.yyyy') : 'No date'}</span>
                    <span>{cert.code}</span>
                    <span>{cert.plan}</span>
                  </HistoryItem>
                ))}
              </HistoryList>
            </Col>
          </Row>
        )}                     
        
        </Col>

    
      </Row>
    </>
  )
}

export default Status

const StatusSkeleton = () => {
  return (
    <>
      <Row>
        <Col>
          <Row>
            <Skeleton height={50} />
          </Row>
          <Row>
            <Skeleton height={50} />
          </Row>
          <Row>
            <Skeleton height={50} />
          </Row>
        </Col>
        <Col>
          <Row>
            <Skeleton height={200} />
          </Row>
        </Col>
      </Row>
    </>
  )
}
