import React, { useEffect } from 'react'
import styled from 'styled-components'
import { ReactComponent as MaintenanceImg } from '../../assets/img/maintenance.svg'
import { useHistory } from 'react-router-dom'
import { routes } from '../../routeConfig'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 150px 0;
`

const ImageWrapper = styled.div`
  & svg {
    max-width: 300px;
    max-height: 300px
  }
`

const Title = styled.h1`
  padding: 15px 0;
`

const Subtitle = styled.h3`
  color: #909090;
  font-weight: normal;
`

const MaintenancePage = () => {
  const history = useHistory()
  useEffect(() => {
    const interval = setInterval(() => history.push(routes.calendar), 30000)
    return () => clearInterval(interval)
  })

  return (
    <Wrapper>
      <ImageWrapper>
        <MaintenanceImg />
      </ImageWrapper>
      <>
        <Title>Проводятся регламентные работы</Title>
        <Subtitle>Страница автоматически обновится по завершению.</Subtitle>
      </>
    </Wrapper>
  )
}

export default MaintenancePage
