import * as Yup from 'yup'

const NewPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(6, 'Слишком короткий новый пароль. Введите не менее 6 символов')
    .required('Укажите новый пароль'),
  repeatNewPassword: Yup.string()
    .when('newPassword', {
      is: (val) => !!val,
      then: Yup.string().required('Пожалуйста, укажите новый пароль еще раз'),
      otherwise: Yup.string()
    })
    .oneOf([Yup.ref('newPassword')], 'Новые пароли не совпадают'),
})

export default NewPasswordSchema