import {gql} from '@apollo/client';

export const FETCH_PROGRAMS = gql`
    query FetchPrograms($programType: TrainingProgrammType) {
        trainingProgramms(programmType: $programType) {
            id
            title
            plans {
                id
                title
                order
            }
            group {
                id
                title
                order
            }
        }
    }
`

export const CREATE_EVENT = gql`
    mutation CreateEvent($event: EventInput!) {
        eventCreate(event: $event) {
            event {
                id
                title
                date
                priority
                programm {
                    title
                }
                description {
                    title
                    content
                    type
                    testingProtocol {
                        title
                        methodId
                        input {
                            title
                            helpText
                            type
                            target {
                                id
                            }
                        }
                    }
                }
                notes
            }
        }
    }
`
