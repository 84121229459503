import React, { useEffect, useState } from 'react'
import { gql, useApolloClient } from '@apollo/client'
import TopLine from '../../UI/TopLine/TopLine'
import Step1 from './steps/_1'
import Step2 from './steps/_2'

const ConfirmEmail = () => {
  const [step, setStep] = useState(1)
  const [open, setOpen] = useState(false)

  const client = useApolloClient()
  const user: User | null = client.readFragment({
    id: 'User:{}',
    fragment: gql`
        fragment ReminderUser on User {
            emailValidated,
            email
        }
    `
  })

  useEffect(() => {
    if (!user?.emailValidated) {
      setOpen(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!user || user.emailValidated || !user.email) {
    return null
  }

  function switchStep(direction: 'prev' | 'next' = 'next') {
    if (direction === 'next') {
      setStep(step + 1)
    }
    if (direction === 'prev') {
      setStep(step - 1)
    }
  }

  function handleClose() {
    setOpen(!open)
  }

  const renderStep = () => {
    if (step === 1) {
      return <Step1 email={user.email} switchStep={switchStep} />
    }
    if (step === 2) {
      return <Step2 />
    }
  }

  return (
    <TopLine isOpen={open} onClose={handleClose} backgroundColor='#DD4747'>
      {renderStep()}
    </TopLine>
  )
}

export default ConfirmEmail
