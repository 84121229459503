import styled from 'styled-components';
import Button from '../../../UI/Button/Button';

export const Wrapper = styled.div`
  max-width: 400px;
  background-color: #FFFFFF;
  padding: 28px 30px 20px;
`

export const ModalStyles = {
  overlay: {
    alignItems: 'center'
  },
  content: {
    marginTop: 0
  }
}

export const Buttons = styled.div`
  width: 100%;
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  padding-right: 30px;
`

export const SubmitButton = styled(Button)`
  background-color: #EB5757;
  color: #FFFFFF;
  border: none;
  min-width: 140px;
  &:hover {
    background-color: #EB5757;
    filter: brightness(.9);
  }
`

export const BackButton = styled(Button)`
  border: none;
  color: #2F80ED;
  padding: 0;
  &:hover {
    background: none;
    border: none;
    color: #2A75DB;
    text-decoration: underline;
  }
`
