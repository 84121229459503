import React, {memo} from 'react';

import {CellEventsProps} from './types';
import {CalendarEventContainer} from '../../containers/CalendarEventContainer';

export const CellEvents: React.FC<CellEventsProps> = memo(function CellEvents({events}) {
  return (
    <>
      {events.map((event, index) => (
        <CalendarEventContainer key={index} event={event}/>
      ))}
    </>
  )
})
