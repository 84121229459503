import styled from 'styled-components';
import trashIcon from '../../../assets/img/icons/trash.svg';
import trashDisabledIcon from '../../../assets/img/icons/trash-disabled.svg';

export const Wrapper = styled.div`
  position: relative;
  & span {
    display: flex;
  }
`

export const DeleteButton = styled.button`
  cursor: pointer;
  border: none;
  width: 19px;
  height: 19px;
  background-image: url(${trashIcon});
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  &:active {
    transform: scale(.95);
  }
  &:disabled {
    cursor: default;
    background-image: url(${trashDisabledIcon});
  }
`
