import React, { useEffect } from 'react'
import styles from './Main.module.css'
import Logo from '../../Logo/Logo'
// import { ReactComponent as AppStoreBadge } from '../../../assets/img/app-store-badge.svg'
// import { ReactComponent as GooglePlayBadge } from '../../../assets/img/google-play-badge.svg'
// import MobileBadge from '../../UI/MobileBadge/MobileBadge'
import styled from 'styled-components'
import { routes } from '../../../routeConfig'
import { Link } from 'react-router-dom'

type MainLayoutProps = {
  children: JSX.Element
}

// const MobileBadges = styled.div`
//   display: flex;
//   justify-content: center;
//   width: 100%;
//   padding: 35px 0;
// `

const BottomLinks = styled.div`
  width: 100%;
`

const LinkList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const LinkItem = styled.li`
  font-size: .75rem;
  margin: 8px 15px;
`

const MainLayout = ({children}:MainLayoutProps) => {

  useEffect(() => {
    document.body.classList.add('cover__image')
    return () => document.body.classList.remove('cover__image')
  }, [])

  return (
    <div className="container">
      <div className={styles.wrapper}>
        <Logo className={styles.logo} />
        {children}
        {/*<div className={styles.bottom}>*/}
        {/*  <MobileBadges>*/}
        {/*    <MobileBadge width={140}>*/}
        {/*      <AppStoreBadge />*/}
        {/*    </MobileBadge>*/}
        {/*    <MobileBadge width={140}>*/}
        {/*      <GooglePlayBadge />*/}
        {/*    </MobileBadge>*/}
        {/*  </MobileBadges>*/}
        {/*</div>*/}
      </div>
      <BottomLinks>
        <LinkList>
          <LinkItem>
            <Link
              to={routes.privacy}
              className={styles.link}
            >
              Политика конфиденциальности
            </Link>
          </LinkItem>
          <LinkItem>
            <Link
              to={routes.tos}
              className={styles.link}
            >
              Условия использования
            </Link>
          </LinkItem>
          <LinkItem>
            <a
              className={styles.link}
              href='https://trisystems.atlassian.net/servicedesk/customer/portals'
              target='_blank'
              rel="noopener noreferrer"
            >
              Центр поддержки
            </a>
          </LinkItem>
        </LinkList>
      </BottomLinks>
    </div>
  )
}

export default MainLayout
