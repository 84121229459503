export function getScrollBarWidth(): number {
  return window.innerWidth - document.documentElement.clientWidth
}

export function addPaddingToShiftElements(scrollBarWidth: number) {
  if (!scrollBarWidth) {
    return null
  }
  const shiftElements = document.querySelectorAll('*[data-modal="shift"]')
  if (scrollBarWidth !== 0 && shiftElements.length !== 0) {
    shiftElements.forEach((el) => (
      el.setAttribute('style', `padding-right: ${scrollBarWidth}px`)
    ))
  }
}

export function removePaddingFromShiftElements() {
  const shiftElements = document.querySelectorAll('*[data-modal="shift"]')
  if (shiftElements.length !== 0) {
    shiftElements.forEach((el) => (
      el.removeAttribute('style')
    ))
  }
}
