import React, { useState, useRef, useMemo } from 'react'
import styled from 'styled-components'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { Title, Paragraph, DatePicker, Input, Row, Col, Select } from '../../UI/Form'
import Button from '../../UI/Button/Button'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import differenceInWeeks from 'date-fns/differenceInWeeks'
import addWeeks from 'date-fns/addWeeks'
import getDay from 'date-fns/getDay'
import ru from 'date-fns/locale/ru'
import { Option } from 'react-select/src/filters'
import ym from 'react-yandex-metrika'
import ymGoals from '../../../analytics/ymGoals'
import { getSelectorOptions } from '../../../utils/planbuilder'
import { PlanBuilderStepProps } from './types'
import { usePlanBuilder } from '../../../services/PlanBuilder'

type Step2Values = {
  eventName: string
  eventType: string
  eventDate: string
}

const LocalParagraph = styled(Paragraph)`
  padding: 15px 0 5px;
`

const LocalCol = styled(Col)`
  max-width: 300px;
`

const Buttons = styled.div`
  margin-top: 25px;
  display: flex;
  & button {
    margin-left: 25px;
    &:first-child {
      margin: 0;
    }
  }
`

const GreyButton = styled(Button)`
  background-color: #ECEAEA;
  border-color: #D1D1D1;
  color: #828282;
  &:hover {
    background-color: #ECEAEA;
    border-color: #444444;
    color: #444444;
  }
`

const BackButton = styled(Button)`
  border: none;
  color: #2F80ED;
  &:hover {
    background: none;
    border: none;
    color: #2A75DB;
    text-decoration: underline;
  }
`

const StartDate = styled.span<{error: boolean}>`
  display: inline-block;
  font-weight: ${props => props.error ? 700 : 500};
  margin-left: 5px;
  color: ${props => props.error && '#CC0000'};
`

const Step2 = ({ title, tabSwitcher }: PlanBuilderStepProps) => {
  const { store } = usePlanBuilder()
  const trainingStartDate = store.value.trainingStartDate
  const events = store.value.events
  const initEventDate = events ? parseISO(events[0].date) : null
  const programs = store.value.availablePrograms

  const currentProgram = useMemo(() => {
    if (Array.isArray(events) && events[0] && Array.isArray(programs)) {
      return programs.find(({ id }) => `${id}` === events[0].programm) || null
    }
    return null
  }, [events, programs])
  const [showCalendar, setShowCalendar] = useState(!currentProgram?.fillEventDate);
  const isFillEventTitle = useRef(currentProgram?.fillEventTitle);

  const [eventDate, setEventDate] = useState<Date | null>(initEventDate)

  function getMinEventDate(allPrograms: Array<TrainingProgram> | undefined): Date | undefined {
    if (allPrograms && trainingStartDate) {
      const allStartOffsets: Array<number> = allPrograms
        .filter(({duration}) => duration.min)
        .map(({duration}) => (duration?.min))
      const min = Math.min.apply(Math, allStartOffsets)
      return addWeeks(parseISO(trainingStartDate), min)
    }
  }

  function formSubmit({ eventName, eventType, eventDate }: Step2Values, { setSubmitting }: any): void {
    const difference = trainingStartDate ? Math.abs(differenceInWeeks(parseISO(eventDate), parseISO(trainingStartDate))) : 0
    setSubmitting(true)

    if (programs) {
      const targetProgram = programs.find(({ id }) => `${id}` === eventType)
      const testingRange = targetProgram?.includeTesting
      const schedule = targetProgram?.schedule
      const events: Array<UserEvent> = [
        {
          title: eventName,
          programm: eventType,
          date: eventDate,
          priority: 'A'
        }
      ]

      store.update({
        events,
        schedule,
        programmGroupId: targetProgram?.group.id
      })

      setSubmitting(false)

      if (testingRange && difference > testingRange[0] && difference < testingRange[1]) {
        ym('reachGoal', ymGoals.planbuilder.secondStepSuccess)
        tabSwitcher('2b')
        return
      }
      store.update({
        includeTesting: false
      })
      ym('reachGoal', ymGoals.planbuilder.secondStepSuccess)
      tabSwitcher('3')
    }
  }

  function isWeekDay(date: Date) {
    const dayNum = getDay(date)
    return dayNum !== 1 && dayNum !== 2 && dayNum !== 3 && dayNum !== 4
  }

  return (
    <Formik
      initialValues={{
        eventName: events ? events[0].title : '',
        eventType: events ? events[0].programm : '',
        eventDate: eventDate ? format(eventDate, 'yyyy-MM-dd') : ''
      }}
      onSubmit={formSubmit}
      validationSchema={Step2Schema}
      validateOnBlur={false}
    >
      {(props) => {
        const formikEventType = props.values['eventType']
        const targetProgram = programs && programs.find(({ id }) => `${id}` === formikEventType)
        const duration = targetProgram ? targetProgram.duration : null
        const minEventDate = getMinEventDate(programs)

        const minDate = duration && trainingStartDate ? addWeeks(parseISO(trainingStartDate), duration.min) : minEventDate
        const maxDate = duration && trainingStartDate ? addWeeks(parseISO(trainingStartDate), duration.max) : null

        const settedProgramId = events ? events[0].programm : null
        const settedProgramLabel = programs?.find(({id}) => id === settedProgramId)?.title
        const settedProgramValue = settedProgramId ? {value: settedProgramId, label: settedProgramLabel} : undefined

        function dateChange(date: Date) {
          setEventDate(date)
          const humanizeDate = format(date, 'yyyy-MM-dd')
          props.setFieldValue('eventDate', humanizeDate)
        }

        function selectChange(value: Option) {
          const program = programs?.find(({ id }) => `${id}` === value.value)
          if (program?.fillEventDate) {
            setEventDate(new Date(program?.fillEventDate))
            setShowCalendar(false)
            props.setFieldValue('eventDate', program?.fillEventDate, false)
          } else {
            setEventDate(null)
            setShowCalendar(true)
            props.setFieldValue('eventDate', '', false)
          }

          if (program?.fillEventTitle) {
            props.setFieldValue('eventName', `${value.label}`)
          } else if (isFillEventTitle.current) {
            props.setFieldValue('eventName', '', false)
          }

          isFillEventTitle.current = Boolean(program?.fillEventTitle)

          props.setFieldValue('eventType', `${value.value}`)
        }

        return (
          <Form>
            <Title style={{paddingBottom: 10}}>{title}</Title>
            <Row>
              <Col style={{maxWidth: 500}}>
                <Paragraph>Выберите ближайший главный старт к которому мы будем вести подготовку. Если Вы не знаете дату ближайшего главного старта нажмите Пропустить шаг.</Paragraph>
              </Col>
            </Row>
            <Row>
              <LocalCol>
                <Input
                  name='eventName'
                  label='Название события'
                  placeholder='Укажите название события'
                  formik={props}
                  withError
                />
              </LocalCol>
            </Row>
            <Row>
              <LocalCol>
                <Select
                  name='eventType'
                  label='Тип гонки'
                  placeholder='Выберите тип'
                  options={getSelectorOptions(programs)}
                  defaultValue={settedProgramValue}
                  onChange={selectChange}
                  formik={props}
                  isLoading={!programs}
                  withError
                />
              </LocalCol>
            </Row>
            <Row>
              <LocalCol>
                <LocalParagraph>
                  Дата старта:
                  <StartDate error={!!(props.touched['eventDate'] && !!props.errors['eventDate'])}>
                    {eventDate
                      ? format(eventDate, 'EEEE, d MMMM yyyy', {locale: ru})
                      : 'Выберите дату старта'
                    }
                  </StartDate>
                </LocalParagraph>
                {showCalendar && <DatePicker
                  name='eventDate'
                  selected={eventDate ? eventDate : null}
                  onChange={dateChange}
                  minDate={minDate}
                  maxDate={maxDate}
                  openToDate={eventDate ? eventDate : minDate}
                  inline
                  filterDate={isWeekDay}
                  showDisabledMonthNavigation
                  disabledKeyboardNavigation
                  fixedHeight
                />}
              </LocalCol>
            </Row>
            <Buttons>
              <Button type='submit' solid>Продолжить</Button>
              <GreyButton
                type='button'
                onClick={() => {
                  tabSwitcher('2a')
                  ym('reachGoal', ymGoals.planbuilder.noRaceBtnClick)
                }}
              >Я не готовлюсь к гонке</GreyButton>
              <BackButton type='button' onClick={() => tabSwitcher('1')} >Вернуться назад</BackButton>
            </Buttons>
          </Form>
        )
      }}
    </Formik>
  )

}

export default Step2

const Step2Schema = Yup.object().shape({
  eventName: Yup.string()
    .required('Укажите название события'),
  eventType: Yup.string()
    .required('Выберите тип гонки'),
  eventDate: Yup.string()
    .required('Укажите дату старта')
})
