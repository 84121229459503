export abstract class TrainingProgram {
  abstract getId(): string
  abstract isDropDisabled(
    dropState: DropState,
    schedule: Array<TempScheduleInput> | undefined,
    dayWorkouts: Array<TempScheduleInput>,
    dayISODate: string
  ): boolean

  abstract getWorkoutTitle(workout: WorkoutSchedule): string
  abstract getScheduleText(): string
}
