import {durationObjToString, getDurationInSeconds, getDurationObj, getDurationPercent} from '../../../../utils/time';
import format from 'date-fns/format';

export function countWeekHeadParams(workouts:Array<WorkoutDay> = [], sportsList: Array<Sport> | undefined):IWeekHeadParams | undefined {
  if (workouts.length === 0) {
    return
  }

  const workoutsBySport = getWorkoutsBySport(workouts)

  const total = countSportTimesInSeconds(workouts)
  const progress = getDurationPercent(total.completed, total.sum)

  const sportsDuration = Object.keys(workoutsBySport)
    .sort((sportSlug1, sportSlug2) => {
      const sport1 = sportsList?.find(({slug}) => slug === sportSlug1)
      const sport2 = sportsList?.find(({slug}) => slug === sportSlug2 )
      return Number(sport1?.order) - Number(sport2?.order)
    })
    .reduce((acc: any, key) => {
      acc[key] = getStringDuration(countSportTimesInSeconds(workoutsBySport[key]))
      return acc
    }, {})

  return {
    total: getStringDuration(total),
    progress: progress,
    ...sportsDuration
  }
}

export function getWorkoutsByDay(workouts: Array<WorkoutDay> = [], day: Date) {
  return workouts.filter((workout:WorkoutDay) => workout.date === format(day, 'yyyy-MM-dd'))
}

export function getEventsByDay(events: Array<UserEvent> = [], day: Date) {
  return events.filter((event: UserEvent) => event.date === format(day, 'yyyy-MM-dd'))
}

function getStringDuration(time:IWeekHeadTime):IWeekHeadTime | undefined {
  if (!time.completed && !time.sum) {
    return
  }

  const [completed, sum] = [time.completed, time.sum]
    .map((time) => getDurationObj(time))
    .map((duration: IWorkoutDuration) => durationObjToString(duration, 2).split(':').slice(0, 2).join(':'))

  return {
    completed,
    sum
  }
}

function getWorkoutsBySport(workouts:Array<WorkoutDay>):WorkoutsByType {
  const result: WorkoutsByType = {}

  return workouts.reduce((acc, workout) => {
    const { slug } = workout.sport
    if (acc[`${slug}`] === undefined) {
      acc[`${slug}`] = []
    }
    acc[`${slug}`].push(workout)
    return acc
  }, result)
}

function countSportTimesInSeconds(workouts: Array<WorkoutDay>):IWeekHeadTime {
  if (workouts === undefined || workouts.length === 0) {
    return {
      completed: 0,
      sum: 0
    }
  }

  function mapDurationsInSeconds(workouts: Array<WorkoutDay>):Array<WorkoutDay> {
    return workouts.map((workout) => ({
      ...workout,
      totalTimePlanned: getDurationInSeconds(`${workout.totalTimePlanned}`)
    }))
  }

  function getSumOfDurations(workouts: Array<WorkoutDay>):number {
    return workouts.reduce((acc, {totalTimePlanned}) => acc + +totalTimePlanned, 0)
  }

  const completedWorkouts = workouts.filter(({completed}) => completed)
  const completedInSeconds = mapDurationsInSeconds(completedWorkouts)
  const sumInSeconds = mapDurationsInSeconds(workouts)
  const completed = getSumOfDurations(completedInSeconds)
  const sum = getSumOfDurations(sumInSeconds)

  return {
    completed,
    sum
  }
}
