import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';

export const WorkoutsWrapper = styled.div<{isDraggingOver: boolean}>`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-image: ${({isDraggingOver}) => isDraggingOver && 'repeating-linear-gradient(-45deg, transparent 0%, transparent 40%, #60AED0 40%, #60AED0 50%, transparent 50%)'};
  background-size: ${({isDraggingOver}) => isDraggingOver && '.8rem .8rem'};
`

export const SkeletonStyled = styled(Skeleton)`
  position: relative;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  min-height: 110px;
`

export const NoWorkouts = styled.p`
  color: #909090;
  font-size: .75rem;
  font-weight: 500;
`
