import React, {memo, useEffect, useState} from 'react';

import {CalendarRow} from './row';
import {CalendarGridProps} from './types';
import {CalendarGridStyled, InitLoader} from './styled';
import {useCalendarTools} from '../../../hooks/useCalendar';
import Loading from '../../../assets/img/loading.gif';
import {WithInfinityLoader} from '../infinity-loader';

export const CalendarGrid: React.FC<CalendarGridProps> = memo(function CalendarGrid({ weeks }) {
  const [isInit, setInitState] = useState(true)
  const calendarTools = useCalendarTools()

  useEffect(() => {
    const timeout = setTimeout(() => {
      calendarTools.scrollToToday()
      setInitState(false)
    }, 1000)
    return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {isInit && (
        <InitLoader>
          <img src={Loading} alt="...loading"/>
        </InitLoader>
      )}
      <WithInfinityLoader isReady={!isInit}>
        <CalendarGridStyled data-modal='shift'>
          {weeks.map(([startDate, endDate], index) => (
            <CalendarRow
              key={index}
              startDate={startDate}
              endDate={endDate}
            />
          ))}
        </CalendarGridStyled>
      </WithInfinityLoader>
    </>
  )
})
