import React from 'react'
import { InputGroup } from '../../UI/Form'
import { FormikProps } from 'formik'

type ChangePasswordFormProps = {
  formikProps: FormikProps<any>
}

const ChangePasswordForm = ({formikProps}: ChangePasswordFormProps) => {
  return (
    <InputGroup groupName='password' formikProps={formikProps} values={[
      { name: 'currentPassword', placeholder: 'Введите текущий пароль', type: 'password', withError: true, autocomplete: 'off' },
      { name: 'newPassword', placeholder: 'Введите новый пароль', type: 'password', withError: true, autocomplete: 'new-password' },
      { name: 'repeatNewPassword', placeholder: 'Повторите новый пароль еще раз', type: 'password', withError: true, autocomplete: 'new-password' }
    ]}/>
  )
}

export default ChangePasswordForm
