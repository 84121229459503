import add from 'date-fns/add';
import endOfWeek from 'date-fns/endOfWeek';
import differenceInCalendarWeeks from 'date-fns/differenceInCalendarWeeks';

import {
  GenerateWeeksOptions,
  GenerateWeeksResult
} from './types';

/** Generate calendar week ranges */
export function generateCalendarWeeks(options: GenerateWeeksOptions): GenerateWeeksResult {
  const {start, end} = options
  const numOfWeek = differenceInCalendarWeeks(end, start, { weekStartsOn: 1 })

  if (numOfWeek < 1) {
    return []
  }

  return new Array(numOfWeek)
    .fill('')
    .map((week, index) => {
      const weekStart = add(start, { weeks: index })
      const weekEnd = endOfWeek(weekStart, { weekStartsOn: 1 })
      return [weekStart, weekEnd]
    })
}
