import React from 'react'
import styled from 'styled-components'
import Tab from '../Tab/Tab'
import { useTargets } from '../../hooks/useTargets'

type TabsProps = {
  tabsData: any
  sportSlug: string
  changeVisibleTarget: (targetName: string) => void
}

const TabsStyled = styled.div`
  max-width: 75%;
  padding: 15px 0;
`

const Header = styled.div`
  display: flex;
  padding: 0 0 10px;
`

const Selectors = styled.div`
  display: flex;
  justify-content: space-between;
`

const Selector = styled.div<{active: boolean}>`
  cursor: pointer;
  font-size: .75rem;
  font-weight: ${({active}) => active ? 'bold' : 'regular'};
  text-transform: uppercase;
  color: ${({active}) => active ? '#444444': '#909090'};
  padding-bottom: 1px;
  margin: 0 10px;
  &:first-child {
    margin-left: 0;
  }
`

const Tabs = ({ tabsData, sportSlug, changeVisibleTarget }: TabsProps) => {
  const [targets] = useTargets()

  if (!targets) {
    return null
  }

  function handleSelectorClick(event: React.SyntheticEvent<HTMLDivElement>) {
    const tab = event.currentTarget.getAttribute('data-tab')
    if (tab) {
      changeVisibleTarget(tab)
    }
  }

  return (
    <TabsStyled>
      <Header>
        <Selectors>
          {Object.keys(tabsData).map((key, index) => {
            const target = targets.find(({ slug: targetSlug }) => targetSlug === key)
            return (
              <Selector
                key={index + key}
                data-tab={key}
                onClick={handleSelectorClick}
                active={sportSlug === key}
              >
                {target && target.descriptionTarget}
              </Selector>
            )
          })}
        </Selectors>
      </Header>
      <Tab content={tabsData[sportSlug]}/>
    </TabsStyled>
  )
}

export default Tabs