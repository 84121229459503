import React from 'react'
import { useLocation } from 'react-router-dom'
import { routes } from '../../routeConfig'
import SettingsContainer from '../../containers/SettingsContainer'
import PlanBuilderContainer from '../../containers/PlanBuilderContainer'
import SubscriptionContainer from '../../containers/SubscriptionContainer'

const ModalPage = () => {
  const location = useLocation<any>()

  function modalRouter() {
    switch (location.pathname) {
      case routes.settings:
        return <SettingsContainer />
      case routes.planbuilder:
        return <PlanBuilderContainer />
      case routes.subscription:
        return <SubscriptionContainer />
    }
  }

  return (
    <>
      {modalRouter()}
    </>
  )
}

export default ModalPage
