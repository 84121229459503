import React from 'react'
import styles from './SmallLoader.module.css'

const SmallLoader = ():JSX.Element => {
  return (
    <div className={styles['small-loader-wrapper']}>
      <div className={styles['small-loader']}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  )
}

export default SmallLoader