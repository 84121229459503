import React from 'react'
import { PlanBuilderStore } from '../types/store'
import { canUseWeakMap } from '../../../utils/common'

export interface PlanBuilderContextValue {
  store?: PlanBuilderStore
}

const cache = new (canUseWeakMap ? WeakMap : Map)<
    typeof React.createContext,
  React.Context<PlanBuilderContextValue>
  >()

export function getPlanBuilderContext() {
  let context = cache.get(React.createContext)!
  if (!context) {
    context = React.createContext<PlanBuilderContextValue>({})
    context.displayName = 'PlanBuilderContext'
    cache.set(React.createContext, context)
  }
  return context
}
