import React, {memo, useState} from 'react';
import {useApolloClient, useQuery} from '@apollo/client';

import format from 'date-fns/format';

import {useSport} from '../../../../hooks/useSport';
import {
  countWeekHeadParams,
  getEventsByDay,
  getWorkoutsByDay
} from './utils';

import PaywallText from '../../../UI/PaywallText';
import WeekHead from '../../../UI/WeekHead/WeekHead';
import {CalendarCell} from '../cell';
import {CalendarRowWrapper} from './styled';
import {CalendarRowProps} from './types';
import {generateCalendarDays} from './generate-calendar-days';
import {isFirstPaywallWeek} from './is-first-paywall-week';
import {WithDnDContext} from './context/DnDContext';
import {FETCH_WEEK_CALENDAR, READ_ACTIVE_PLAN} from './graphql';

export const CalendarRow: React.FC<CalendarRowProps> = memo(function CalendarRow({startDate, endDate}) {
  const [skip, setSkip] = useState(false)
  const client = useApolloClient()
  const sport = useSport()

  const days = generateCalendarDays(startDate)

  const { loading, error, data} = useQuery(FETCH_WEEK_CALENDAR, {
    variables: {
      dateMin: format(startDate, 'yyyy-MM-dd'),
      dateMax: format(endDate, 'yyyy-MM-dd')
    },
    skip
  })

  if (error) {
    setSkip(true)
  }

  function getWeekTitle(): string {
    const [firstWorkout] = data?.calendar?.workouts || []
    if (!firstWorkout) {
      return ''
    }
    const planId = firstWorkout.plan?.id
    let plan: ActiveTrainingPlan | null = null
    try {
      plan = client.readFragment({
        id: `TrainingPlan:${planId}`,
        fragment: READ_ACTIVE_PLAN,
      })

    } catch (error) {
      console.error(error.message)
    }
    return plan ? plan.title : ''
  }

  const weekHeadParams = countWeekHeadParams(data?.calendar?.workouts, sport.getAll())

  const isPaywallText = isFirstPaywallWeek({
    startDate,
    weekWorkouts: data?.calendar?.workouts
  })

  return (
    <WithDnDContext weekWorkouts={data?.calendar?.workouts}>
      {({isDropDisabled}) => (
        <CalendarRowWrapper data-month={format(startDate, 'yyyy/MM')}>
          {isPaywallText && <PaywallText />}
          <WeekHead
            params={weekHeadParams}
            isLoading={loading}
            title={getWeekTitle()}
          />
          {days.map(({date}) => {
            const workouts = getWorkoutsByDay(data?.calendar?.workouts, date)
            const events = getEventsByDay(data?.calendar?.events, date)
            const dropDisabledState = isDropDisabled(workouts, date)
            return (
              <CalendarCell
                key={format(date, 'yyyy-MM-dd')}
                isLoading={loading}
                isDropDisabled={dropDisabledState}
                date={date}
                workouts={workouts}
                events={events}
              />
            )
          })}
        </CalendarRowWrapper>
      )}
    </WithDnDContext>
  )
})
