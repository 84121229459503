import React from 'react'
import styles from './SignInForm.module.css'
import { Form, Formik, FormikValues } from 'formik'
import Button from '../../UI/Button/Button'
import { Link, useHistory, useLocation } from 'react-router-dom'
import SignInSchema from './validation'
import { Wrapper, Input } from '../../UI/Form'
import SmallLoader from '../../UI/SmallLoader/SmallLoader'
import { loader } from 'graphql.macro'
import { useMutation } from '@apollo/client'
import { useSession } from '../../../hooks/auth/useSession'
import { routes } from '../../../routeConfig'
import {customHeaders} from '../../../apollo/constants';
import ym from "react-yandex-metrika";
import ymGoals from "../../../analytics/ymGoals";

interface stateType {
  from: { pathname: string }
}

const SIGN_IN = loader('../../../apollo/mutations/TokenAuth.graphql')

const SignInForm = () => {
  const history = useHistory()
  const session = useSession()
  const location = useLocation<stateType>()
  const [signIn] = useMutation(SIGN_IN, {
    context: {
      headers: {
        ...customHeaders,
      }
    }
  })

  const { from } = location.state || { from: { pathname: routes.calendar } }

  function formSubmit(values: FormikValues, { setSubmitting, setFieldError }: any) {
    const { email, password } = values
    setSubmitting(true)
    signIn({
      variables: {
        username: email,
        password
      }
    })
      .then((result) => {
        const { token, refreshToken }: SignInResult = result.data.tokenAuth
        session.login(token, refreshToken)
        history.push(from)
      })
      .catch((error) => {
        console.error(error.message)
        setFieldError('password', 'Неправильный логин или пароль')
        setSubmitting(false)
      })
  }

  return (
    <Formik
      initialValues={{
        email: '',
        password: ''
      }}
      validationSchema={SignInSchema}
      onSubmit={formSubmit}
      validateOnBlur={false}
    >
      {(props) => (
        <Wrapper>
          <Form>
            {history.action === 'REPLACE' && <p className={styles.replaced}>Пожалуйста, введите ваш логин и пароль</p>}
            <h2 className={styles.title}>Вход</h2>
            <div className={styles.row}>
              <div className={styles.col}>
                <Input name='email' placeholder='Адрес электронной почты' formik={props} withError/>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>
                <Input name='password' type='password' placeholder='Введите пароль' formik={props} withError/>
              </div>
            </div>
            <Button
              className={styles.submit}
              type='submit'
              disabled={props.isSubmitting}
              solid
            >
              {props.isSubmitting ? <SmallLoader /> : 'Войти'}
            </Button>
            <div className={styles.underline}>
              <Link
                to={routes.signUp}
                className={styles['underline__link']}
              >
                Зарегистрироваться
              </Link>
              <Link
                to={routes.resetPassword}
                className={styles['underline__link']}
                onClick={() => ym('reachGoal', ymGoals.login.forgetPasswordClick)}
              >
                Забыли пароль?
              </Link>
            </div>
          </Form>
        </Wrapper>
      )}
    </Formik>
  )
}

export default SignInForm
