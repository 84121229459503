import React, {memo, useState} from 'react';

import {AddEventPlus} from './styled';
import {AddCalendarEventProps} from './types';
import {EventContainer} from '../../../containers/EventContainer';

export const AddCalendarEvent: React.FC<AddCalendarEventProps> = memo(function AddCalendarEvent({visible, date}) {
  const [open, setOpen] = useState(false)

  function changeOpenState() {
    setOpen(!open)
  }

  if (open) {
    return <EventContainer closeHandler={changeOpenState} date={date}/>
  }

  return visible
    ? <AddEventPlus onClick={changeOpenState}>+</AddEventPlus>
    : null
})
