export function getPaymentPromocodeError(sourceMessage: string): string | undefined {
  if (!sourceMessage) {
    return
  }

  let message = sourceMessage

  if (sourceMessage === 'invalid_promocode') {
    message = 'Неправильный промокод'
  }

  return message
}
