import React from 'react'
import { ReactComponent as MaintenanceImg } from '../../../assets/img/maintenance.svg'
import styled from 'styled-components'

const Wrapper = styled.div`
  min-width: 1100px;
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px 25px;
`

const ImageWrapper = styled.div`
  & svg {
    max-width: 150px;
    max-height: 150px;
  }
`

const Title = styled.h2`
  
`

const PlanBuilderMaintenance = () => {
  return (
    <Wrapper>
      <ImageWrapper>
        <MaintenanceImg />
      </ImageWrapper>
      <Title>Создание планов временно недоступно</Title>
    </Wrapper>
  )
}

export default PlanBuilderMaintenance
