import React from 'react'
import ReactPlayer from 'react-player'
import styled from 'styled-components'
import WorkoutTest from '../WorkoutTest/WorkoutTest'
import { Markdown } from '../../Markdown'

type WorkoutDescriptionProps = {
  data: Array<WorkoutDescription>
  sport: Sport
}

const DescriptionWrapper = styled.div``

const SubTitle = styled.h3`
  color: #828282;
  font-weight: 500;
  margin: 0;
  padding: 4px 0 3px;
  font-size: .75rem;
  text-transform: uppercase;
`

const PlayerWrapper = styled.div`
  padding: 10px 0 0;
  margin: 10px 0;
  overflow: hidden;
`

const WorkoutDescription = ({ data, sport }: WorkoutDescriptionProps) => {
  return (
    <DescriptionWrapper>
      {data.length !== 0 && <SubTitle>Описание</SubTitle>}
      {data.map(({ type, title, content, testingProtocol }, index) => {
        if (type === 'text') {
          return (
            <div key={index + type}>
              {title && <SubTitle>{title}</SubTitle>}
              <Markdown content={content}/>
            </div>
          )
        }
        if (type === 'media') {
          return (
            <PlayerWrapper key={index + type}>
              {title && <SubTitle>{title}</SubTitle>}
              <ReactPlayer style={{marginTop: 8}} url={content} width='100%' height='480px' controls={true}/>
            </PlayerWrapper>
          )
        }
        return (
          <div key={index + type}>
            {title && <SubTitle>{title}</SubTitle>}
            <WorkoutTest protocol={testingProtocol} sportId={sport.id}/>
          </div>
        )
      })}
    </DescriptionWrapper>
  )
}

export default WorkoutDescription
