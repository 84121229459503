import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
import addDays from 'date-fns/addDays'

export function getPreviewWeekDays(startDate: string): Array<{ date: string }> {
  const toDate = parseISO(startDate)
  return new Array(7)
    .fill('')
    .map((day, index) => ({
      date: format(addDays(toDate, index), 'yyyy-MM-dd')
    }))
}
