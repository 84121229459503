import styled from 'styled-components';

export const CalendarGridStyled = styled.section`
  background-color: #ECE9E9;
  margin-top: 116px;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
`

export const InitLoader = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #D0D0D0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`
