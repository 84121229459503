import React, { useEffect } from 'react'
import ResetForm from '../../components/Forms/Reset/ResetForm'
import MainLayout from '../../components/Layouts/Main/MainLayout'
import { useHistory } from 'react-router-dom'
import { useSession } from '../../hooks/auth/useSession'
import { routes } from '../../routeConfig'
import ym from 'react-yandex-metrika'
import ReactGA from 'react-ga'

const ResetPage = () => {
  const session = useSession()
  const history = useHistory()

  useEffect(() => {
    ym('hit', '/reset')
    ReactGA.pageview('/reset')
  }, [])

  if (session.isValid()) {
    history.push(routes.calendar)
  }

  return (
    <MainLayout>
      <ResetForm/>
    </MainLayout>
  )
}

export default ResetPage
