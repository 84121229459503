import React, { useEffect } from 'react'
import styled from 'styled-components'
import ym from 'react-yandex-metrika'
import ReactGA from 'react-ga'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
`

const Header = styled.h1`
  font-size: 2rem;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
`

const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: normal;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0;
`

const PageNotFound = () => {
  useEffect(() => {
    ym('hit', '/404')
    ReactGA.pageview('/404')
  }, [])
  return (
    <Wrapper>
      <Header>404</Header>
      <Title>Page not found</Title>
    </Wrapper>
  )
}

export default PageNotFound
