import { durationToString } from './time'
import { getDefaultTargetsMap } from './workout'

interface ChartPoint {
  time: string
  hrt?: number | null
  pace?: number | null
  power?: number | null
  rpe?: number | null
}

function calculateAvg(low: number | null, high: number | null) {
  if (typeof low !== 'number' && typeof high !== 'number') return null
  if (typeof low !== 'number') return high
  if (typeof high !== 'number') return low
  return Math.floor((low + high) / 2)
}

function unwrapRepeatStep(steps: Array<WorkoutStep>, step: WorkoutStep, stepIndex: number): Array<WorkoutStep> {
  const stepStartIndex = step.targets[0].value
  const repeatCount = step.durationValue - 1  // -1 because we have source repeat steps in 'unwrappedSteps' already
  const repeatedSteps = []

  if (stepStartIndex !== null) {
    for (let i = 0; i < repeatCount; i += 1) {
      for (let j = stepStartIndex; j < stepIndex; j += 1) {
        repeatedSteps.push(steps[j])
      }
    }
  }

  return repeatedSteps
}

function unwrapSteps(steps: Array<WorkoutStep>) {
  let unwrappedSteps:Array<WorkoutStep> = []

  steps.forEach((step, stepIndex) => {
    if (step.durationType === 'REPEAT_UNTIL_STEPS_CMPLT') {
      unwrappedSteps.push(...unwrapRepeatStep(steps, step, stepIndex))
    } else {
      unwrappedSteps.push(step)
    }
  })
  return unwrappedSteps
}

function generateChartData(steps: Array<WorkoutStep>, targetsMap: Array<Target> = getDefaultTargetsMap()): Array<ChartPoint> {
  const unwrappedSteps = unwrapSteps(steps)
  let data: Array<ChartPoint> = []
  let timeCounter = 0

  unwrappedSteps.forEach(({ durationValue, targets}) => {
    for (let i = 0; i < durationValue; i += 1) {
      let result:{ [key: string]: number | null } = {}
      targets.forEach(({ id, low, high }) => {
        const mappedTarget = targetsMap.find(({ id: mapTargetId }) => mapTargetId === id)
        const avg = calculateAvg(low, high)
        if (mappedTarget !== undefined && avg && (mappedTarget.slug === 'pace' || mappedTarget.slug === 'swim')) {
          result[mappedTarget.slug] = 1000 / avg
          return
        }
        if (mappedTarget !== undefined) {
          result[mappedTarget.slug] = avg
          return
        }
      })

      const time = durationToString(timeCounter + i)
      const point = {
        time,
        ...result
      }

      data.push(point)
    }
    timeCounter = timeCounter + durationValue
  })

  return data
}

function memoizedGenerateChartData(workoutId: number) {
  const cache: {[key: number]: Array<ChartPoint>} = {}
  return (steps:Array<WorkoutStep>, targetsMap:Array<Target> | undefined) => {
    if (workoutId in cache) {
      return cache[workoutId]
    } else {
      const result = generateChartData(steps, targetsMap)
      cache[workoutId] = result
      return result
    }
  }
}

function getReferenceValue(zones: Array<UserZone>, chartTarget: string, sport: Sport) {
  const zone = zones.find((zone: UserZone) => zone.sportId === sport.id && zone.target?.slug === chartTarget)

  if (!zone) {
    return
  }

  const threshold = zone.threshold

  if (chartTarget === 'pace' || chartTarget === 'swim') {
    return 1000 / threshold
  }

  return threshold
}

export {
  calculateAvg,
  unwrapSteps,
  generateChartData,
  memoizedGenerateChartData,
  getReferenceValue
}
