import React, {memo} from 'react';

import {EventNotesProps} from './types';
import {SubTitle, Paragraph} from './styled';

export const EventNotes: React.FC<EventNotesProps> = memo(function EventNotes({notes}) {
  if (!notes) {
    return null
  }

  return (
    <>
      <SubTitle>Заметки</SubTitle>
      <Paragraph>{notes}</Paragraph>
    </>
  )
})
