import styled from 'styled-components';

export const Plans = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
`

export const PlansContent = styled.div`
  display: flex;
  flex-direction: column;
  & h4 {
    color: #828282;
    font-weight: normal;
    font-size: .75rem;
  }
`

export const PlansOptions = styled.div`
  display: flex;
  flex-direction: column;
`

export const PlansOptionsItem = styled.label`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
  color: #4F4F4F;
`

export const PlansOptionsItemPrice = styled.span`
  font-weight: 500;
  flex-basis: 80px;
`

export const PlansOptionsItemDescription = styled.div`
  display: flex;
  flex-basis: 135px;
  flex-direction: column;
  & p {
    margin: 0;
  }
  & span {
     color: #828282;
     font-size: .8125rem;
     padding-top: 4px;
  }
`
