import React from 'react'
import { Droppable } from 'react-beautiful-dnd'
import PlanBuilderWorkoutDraggable from '../Draggable/PlanBuilderWorkoutDraggable'
import styled from 'styled-components'
import { usePlanBuilder } from '../../services/PlanBuilder'


type PlanBuilderDayDroppableProps = {
  isDropDisabled: boolean
  id: string
  title: string
  workouts: Array<WorkoutSchedule>
}

const Day = styled.div<{dropDisabled: boolean}>`
  width: 13.8%;
  border: 1px solid #ECE9E9;
  padding: 10px 8px 8px;
  border-radius: 3px;
  background-image: ${({dropDisabled}) => dropDisabled && 'repeating-linear-gradient(-45deg, transparent 0%, transparent 40%, #FF9696 40%, #FF9696 50%, transparent 50%)'};
  background-size: ${({dropDisabled}) => dropDisabled && '.8rem .8rem'};
`

const Title = styled.div`
  color: #909090;
  font-size: .6250rem;
  text-transform: uppercase;
  padding-bottom: 8px;
`

const Workouts = styled.div<{draggingOver: boolean}>`
  min-height: 84%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: ${({draggingOver}) => draggingOver && 'repeating-linear-gradient(-45deg, transparent 0%, transparent 40%, #60AED0 40%, #60AED0 50%, transparent 50%)'};
  background-size: ${({draggingOver}) => draggingOver && '.8rem .8rem'};
`

const NoWorkouts = styled.p`
  color: #575757;
  font-size: .6850rem;
  font-weight: 500;
  padding: 0 1px;
`

const PlanBuilderDayDroppable = ({ isDropDisabled, workouts, id, title }: PlanBuilderDayDroppableProps) => {
  const planbuilder = usePlanBuilder()
  const sortWorkouts = workouts.sort((workout1, workout2) => (
    workout1.order < workout2.order ? -1 : 1
  ))

  return (
    <Droppable
      droppableId={id}
      isDropDisabled={isDropDisabled}
    >
      {(provided, snapshot) => {
        return (
          <Day
            dropDisabled={isDropDisabled}
          >
            <Title>{title}</Title>
            <Workouts
              ref={provided.innerRef}
              {...provided.droppableProps}
              draggingOver={snapshot.isDraggingOver}
            >
              {workouts.length === 0
                ? <NoWorkouts>День отдыха</NoWorkouts>
                : sortWorkouts.map((workout) => {
                  const { sport, plot, date, order , intensity} = workout
                  const workoutTitle = planbuilder.getWorkoutTitle(workout)
                  const slug = sport.slug
                  return (
                    <PlanBuilderWorkoutDraggable
                      key={date + order + sport.id}
                      index={order}
                      id={`${date}:${sport.id}:${intensity.id}`}
                      title={workoutTitle}
                      slug={slug}
                      plot={plot}
                    />
                  )
                })
              }
              {provided.placeholder}
            </Workouts>

          </Day>
        )
      }}
    </Droppable>
  )
}

export default PlanBuilderDayDroppable
