import React, { useEffect } from 'react'
import MainLayout from '../../components/Layouts/Main/MainLayout'
import SignInForm from '../../components/Forms/SignIn/SignInForm'
import { useHistory } from 'react-router-dom'
import { useSession } from '../../hooks/auth/useSession'
import { routes } from '../../routeConfig'
import ym from 'react-yandex-metrika'
import ReactGA from 'react-ga'

const SignInPage = () => {
  const session = useSession()
  const history = useHistory()

  useEffect(() => {
    ym('hit', '/login')
    ReactGA.pageview('/login')
  }, [])

  if (session.isValid()) {
    history.push(routes.calendar)
  }

  return (
    <MainLayout>
      <SignInForm/>
    </MainLayout>
  )
}

export default SignInPage
