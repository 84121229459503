import { TrainingProgram } from './TrainingProgram'
import parseISO from 'date-fns/parseISO'
//import { getNearDays } from '../../utils/planbuilder'
import isSameDayFns from 'date-fns/isSameDay'

export class BikeProgram extends TrainingProgram {
  private id: string = '2'

  public getId() {
    return this.id
  }

  public getWorkoutTitle(workout: TempScheduleInput): string {
    if (workout.sport.id !== '1') {
      return workout.sport.title
    }
    return workout.intensity.title
  }

  public isDropDisabled(
    dropState: DropState,
    schedule: Array<TempScheduleInput> | undefined,
    dayWorkouts: Array<TempScheduleInput>,
    dayISODate: string
  ): boolean {
    if (dropState.sourceDate) {
      const toDayDate = parseISO(dayISODate)
      // const nearDays = getNearDays(toDayDate, 2)

      const isSameDay = isSameDayFns(dropState.sourceDate, toDayDate)

      if (isSameDay) {
        return false
      }

      if (dayWorkouts.length > 1) {
        return true
      }

      if (dropState.droppingWorkoutSportId !== 1) {
        return false
      }

      const hasSameTypeWorkouts = dayWorkouts.some((workout) => (
        (+workout?.intensity.id === dropState.droppingWorkoutIntensityId && +workout?.sport.id === dropState.droppingWorkoutSportId) ||
        +workout?.sport.id === dropState.droppingWorkoutSportId
      ))

      if (hasSameTypeWorkouts) {
        return true
      }
    }

    return false
  }

  getScheduleText(): string {
    return 'Перетаскивайте тренировки и настройте план согласно вашим предпочтениям.'
  }
}
