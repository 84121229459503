const goals = {
  login: {
    loginBtnClick: 'Войти',
    forgetPasswordClick: 'Забыли пароль',
  },
  register: {
    registerBtnClick: 'REGISTER_BTN_CLICK',
    success: 'REGISTER_SUCCESS'
  },
  planbuilder: {
    open: 'PLANBUILDER_OPEN',
    firstStepSuccess: 'PLANBUILDER_FIRST_STEP_SUCCESS',
    secondStepSuccess: 'PLANBUILDER_SECOND_STEP_SUCCESS',
    thirdStepSuccess: 'PLANBUILDER_THIRD_STEP_SUCCESS',
    testingStepSuccess: 'PLANBUILDER_TESTING_STEP_SUCCESS',
    chooseTrainingProgram: 'PLANBUILDER_CHOOSE_TRAINING_PROGRAM',
    noRaceBtnClick: 'PLANBUILDER_NO_RACE_BTN_CLICK',
    activateProgramBtnClick: 'PLANBUILDER_ACTIVATE_PROGRAM_BTN_CLICK',
  },
  survey: {
    firstStepSuccess: 'SURVEY_FIRST_STEP_SUCCESS',
    complete: 'SURVEY_COMPLETE'
  }
}

export default goals
