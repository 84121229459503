import styled from 'styled-components';

export const SubTitle = styled.h3`
  color: #828282;
  font-weight: 500;
  margin: 0;
  padding: 4px 0 3px;
  font-size: .75rem;
  text-transform: uppercase;
`

export const Paragraph = styled.p`
  font-size: .875rem;
  color: #4F4F4F;
  line-height: 1.4;
  margin: 0;
  padding: 7px 0;
`
