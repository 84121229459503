import { PlanBuilderStoreValue } from '../types/store'
import { TriathlonProgram } from '../models/TriathlonProgram'
import { BikeProgram } from '../models/BikeProgram'
import { useContext } from 'react'
import { getPlanBuilderContext } from '../context/'
import { TrainingProgram } from '../models/TrainingProgram'

export function usePlanBuilder() {
  const { store } = useContext(getPlanBuilderContext())

  const trainingPrograms = [
    new TriathlonProgram(),
    new BikeProgram()
  ]

  if (!store?.value) {
    throw new Error('No PlanBuilder instance can be found. Please ensure that you have call <PlanBuilderProvider> higher up in your tree>')
  }

  function getWorkoutTitle(workout: WorkoutSchedule): string {
    const trainingProgram = getTrainingProgram(store?.value)
    if (trainingProgram) {
      return trainingProgram.getWorkoutTitle(workout)
    }
    return workout.sport.title
  }

  function isDropDisabled(dropState: DropState) {
    return (...args: [dayWorkouts: Array<TempScheduleInput>, dayDate: string]) => {
      const trainingProgram = getTrainingProgram(store?.value)
      if (trainingProgram) {
        return trainingProgram.isDropDisabled(dropState, store?.value['schedule'], ...args)
      }
      return false
    }
  }

  function getTrainingProgram(store: PlanBuilderStoreValue | undefined): TrainingProgram | null | undefined {
    if (!store) {
      return null
    }
    const currentProgramGroupId = store['programmGroupId']
    if (!currentProgramGroupId) {
      return null
    }
    return trainingPrograms.find((program) => program.getId() === currentProgramGroupId)
  }

  function getScheduleText(): string {
    const trainingProgram = getTrainingProgram(store?.value)
    if (trainingProgram) {
      return trainingProgram.getScheduleText()
    }
  return 'Перетаскивайте тренировки и настройте план согласно вашим предпочтениям.'
  }

  return {
    store,
    getWorkoutTitle,
    isDropDisabled,
    getScheduleText
  }
}
