import React from 'react'
import { Input, Row, Col } from './index'
import styled from 'styled-components'
import { FormikProps } from 'formik'

type InputGroupProps = {
  label?: string
  groupName: string
  values: Array<{
    name: string
    placeholder?: string
    type?: string
    withError?: boolean
    autocomplete?: string
  }>
  formikProps: FormikProps<any>
}

const InputGroupStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 6px 0;
  & > label {
    display: block;
    font-size: .75rem;
    color: #565656;
    width: 100%;
    padding-bottom: 6px;
  }
`

export const InputGroup = ({ label, groupName, values, formikProps }: InputGroupProps) => {
  return (
    <InputGroupStyled role='group' aria-labelledby={`${groupName}-group`}>
      {label && <label>{label}</label>}
      {values.map(({
        name,
        placeholder= '',
        type= 'text',
        withError = false,
        autocomplete
      }) => (
        <Row key={name}>
          <Col>
            <Input
              name={name}
              placeholder={placeholder}
              type={type}
              formik={formikProps}
              withError={withError}
              autocomplete={autocomplete}
            />
          </Col>
        </Row>
      ))}
    </InputGroupStyled>
  )
}
