import { FetchResult, useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'

const PAYMENT__CREATE_TRANSACTION = loader('../../apollo/mutations/payment/CreateTransaction.graphql')

export interface UseTransaction {
  create: (planId: string, external: boolean, promocode?: string) =>  Promise<FetchResult<any>>
  loading: boolean
}

export function useTransaction(): UseTransaction {
  const [paymentCreateTransaction, { loading }] = useMutation(PAYMENT__CREATE_TRANSACTION)

  function create(planId: string, external: boolean, promocode = ''): Promise<FetchResult<any>> {
    return paymentCreateTransaction({
      variables: {
        planId,
        external,
        promocode
      }
    })
  }

  return {
    create,
    loading
  }
}
