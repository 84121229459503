import React from 'react'
import CalendarWorkoutContainer from '../../containers/CalendarWorkoutContainer'
import { Draggable } from 'react-beautiful-dnd'

type CalendarWorkoutWithDnDProps = {
  index: number
  id: number
}

const CalendarWorkoutDraggable = ({ id, index }: CalendarWorkoutWithDnDProps) => {
  return (
    <Draggable
      draggableId={`${id}`}
      index={index}
    >
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <CalendarWorkoutContainer id={id} />
        </div>
      )}
    </Draggable>
  )
}

export default CalendarWorkoutDraggable