import {calendarCurrentMonth} from '../../apollo/cache';

/** Sets the name of the current month */
export function calendarOnScroll() {
  const week = document.elementFromPoint(0, 350)?.parentElement
  const monthTitle = week?.getAttribute('data-month')
  if (monthTitle && calendarCurrentMonth() !== monthTitle) {
    calendarCurrentMonth(monthTitle)
  }
}
