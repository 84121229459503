import { ApolloClient, ApolloLink, HttpLink, fromPromise, from, gql, Context } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { cache } from './cache'
import { routes } from '../routeConfig'
import { customHeaders } from './constants'

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_API,
})

const authMiddleWare = new ApolloLink((operation, forward) => {
  const accessToken = localStorage.getItem('Access Token')
  if (accessToken) {
    operation.setContext((req: never, prevContext: Context) => ({
      ...prevContext,
      headers: {
        ...prevContext?.headers,
        Authorization: accessToken ? `JWT ${accessToken}` : '',
        ...customHeaders
      }
    }))
  }
  return forward(operation)
})

let isRefreshing = false;
let pendingRequests: Array<any> = [];

const resolvePendingRequests = () => {
  pendingRequests.map(callback => callback());
  pendingRequests = [];
}

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      if (graphQLErrors[0].message === 'maintenance_mode') {
        window.location.replace(routes.maintenance)
        return
      }
      for (let err of graphQLErrors) {
        if (
          err.message === 'You do not have permission to perform this action' ||
          err.message === 'Error decoding signature' ||
          err.message === 'Signature has expired'
        ) {
          let forward$
          if (!isRefreshing) {
            isRefreshing = true
            forward$ = fromPromise(
              getNewToken()
                .then(({ data }) => {
                  const { token: accessToken, refreshToken } = data.refreshToken
                  const oldHeaders = operation.getContext().headers
                  operation.setContext({
                    headers: {
                      ...oldHeaders,
                      Authorization: `JWT ${accessToken}`
                    }
                  })
                  localStorage.setItem('Access Token', accessToken)
                  localStorage.setItem('Refresh Token', refreshToken)
                  resolvePendingRequests();
                  return accessToken
                })
                // eslint-disable-next-line no-loop-func
                .catch(() => {
                  pendingRequests = []
                  localStorage.removeItem('Access Token')
                  localStorage.removeItem('Refresh Token')
                  window.location.replace(routes.signIn)
                  return
                })
                // eslint-disable-next-line no-loop-func
                .finally(() => {
                  isRefreshing = false
                })
            ).filter(value => Boolean(value))
          } else {
            forward$ = fromPromise(
              // eslint-disable-next-line no-loop-func
              new Promise((resolve) => pendingRequests.push(() => resolve('')))
            )
          }
          return forward$.flatMap(() => forward(operation))
        }
      }
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      window.location.replace(routes.signIn)
    }
  }
);

export const apolloClient = new ApolloClient({
  cache,
  link: from([errorLink, authMiddleWare, httpLink]),
  connectToDevTools: true, // for Develop purpose only
})

const REFRESH_TOKEN = gql`
    mutation RefreshToken($refreshToken: String) {
        refreshToken(refreshToken: $refreshToken) {
            token
            refreshToken
        }
    }
`

function getNewToken(): Promise<any> {
  const refreshToken = localStorage.getItem('Refresh Token')
  return apolloClient.mutate({
    mutation: REFRESH_TOKEN,
    variables: {
      refreshToken
    }
  })
}
