import { useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import { useState } from 'react'

const GET_SPORTS = loader('../apollo/queries/Sports.graphql')

export function useSport() {
  const [skip, setSkip] = useState(false)

  const { error, data } = useQuery(GET_SPORTS, {
    skip,
  })

  if (error) {
    setSkip(true)
  }

  function getAll(): Array<Sport> | undefined {
    return data?.sports
  }

  function getById(id: string): Sport {
    const allSports = getAll()
    if (allSports) {
      const targetSport = allSports.find(({ id:sportId }: Sport) => sportId === id)
      if (targetSport) {
        return targetSport
      }
    }
    return {
      id: '',
      title: '',
      short: '',
      icon: '',
      order: '',
      showSurvey: false,
      slug: '',
      target: []
    }
  }

  function getBySlug(slug: string | undefined): Sport | undefined {
    if (!slug) {
      return
    }
    const allSports = getAll()
    if (allSports) {
      return allSports.find(({ slug:sportSlug }: Sport) => sportSlug === slug)
    }
  }

  return {
    getAll,
    getById,
    getBySlug
  }
}
