import React from 'react'
import { Form, Formik } from 'formik'
import { Col, Paragraph, Row, Select, Title } from '../../UI/Form'
import Button from '../../UI/Button/Button'
import styled from 'styled-components'
import * as Yup from 'yup'
import ym from 'react-yandex-metrika'
import ymGoals from '../../../analytics/ymGoals'
import { Option } from 'react-select/src/filters'
import { getSelectorOptionsWithoutEvents } from '../../../utils/planbuilder'
import { PlanBuilderStepProps } from './types'
import { usePlanBuilder } from '../../../services/PlanBuilder'

type Step2AValues = {
  eventType: string
}

const Buttons = styled.div`
  margin-top: 25px;
  display: flex;
  & button {
    margin-left: 25px;
    &:first-child {
      margin: 0;
    }
  }
`

const BackButton = styled(Button)`
  border: none;
  color: #2F80ED;
  &:hover {
    background: none;
    border: none;
    color: #2A75DB;
    text-decoration: underline;
  }
`

const LocalCol = styled(Col)`
  max-width: 300px;
  & > div > div > div > label {
    height: 55px;
    text-align: center;
  }
`

const Step2A = ({ tabSwitcher }: PlanBuilderStepProps) => {
  const { store } = usePlanBuilder()
  const programs = store.value.availablePrograms

  function formSubmit({ eventType }: Step2AValues, { setSubmitting }: any): void {
    setSubmitting(true)
    if (programs) {
      const targetProgram = programs.find(({ id }) => `${id}` === eventType)
      const programSchedule = targetProgram?.schedule
      const schedule = programSchedule?.map((workout) => ({
        ...workout,
        sportId: workout.sport.id,
      }))

      store.update({
        events: null,
        programm: eventType,
        programmGroupId: targetProgram?.group.id,
        schedule
      })
    }
    setSubmitting(false)
    ym('reachGoal', ymGoals.planbuilder.testingStepSuccess)
    tabSwitcher('2b')
  }

  return (
    <Formik
      initialValues={{
        eventType: '',
      }}
      onSubmit={formSubmit}
      validationSchema={Step2ASchema}
    >
      {(props) => {

        function selectChange(value: Option) {
          props.setFieldValue('eventType', `${value.value}`)
        }

        return (
          <Form>
            <Title style={{paddingBottom: 10}}>Программа подготовки</Title>
            <Row>
              <LocalCol style={{maxWidth: 500}}>
                <Paragraph>Нет событий - не проблема! Просто выберите желаемую дисциплину подготовки и мы начнем работу. Гонки можно будет добавить позднее.</Paragraph>
              </LocalCol>
            </Row>
            <Row>
              <LocalCol>
                <Select
                  name='eventType'
                  label='Тип гонки'
                  placeholder='Выберите тип'
                  options={getSelectorOptionsWithoutEvents(programs)}
                  onChange={selectChange}
                  formik={props}
                  isLoading={!programs}
                  withError
                />
              </LocalCol>
            </Row>
            <Buttons>
              <Button type='submit' solid>Продолжить</Button>
              <BackButton type='button' onClick={() => tabSwitcher('2')}>Вернуться назад</BackButton>
            </Buttons>
          </Form>
        )
      }}
    </Formik>
  )
}

export default Step2A

const Step2ASchema = Yup.object().shape({
  eventType: Yup.string()
    .required('Выберите программу подготовки'),
})
