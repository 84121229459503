import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'intersection-observer'
import packageJson from '../package.json'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import 'moment/locale/ru'
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import ReactGA from 'react-ga'
import { YMInitializer } from 'react-yandex-metrika'
import { apolloClient } from './apollo/config'
import { ApolloProvider } from '@apollo/client'

Sentry.init({
  dsn: process.env.REACT_APP_PUBLIC_SENTRY_DSN,
  autoSessionTracking: true,
  integrations: [
    new Integrations.BrowserTracing(),
  ],
  environment: process.env.REACT_APP_NODE_ENV,
  release: 'trisystems-web@' + packageJson.version,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
})

ReactGA.initialize(`${process.env.REACT_APP_GA}`)
ReactGA.pageview(window.location.pathname + window.location.search)

const app = (
  <>
    { process.env.REACT_APP_YA_METRIKA
      ? <YMInitializer accounts={[+process.env.REACT_APP_YA_METRIKA]} options={{ webvisor: true, defer: true, clickmap: true}} version='2'/>
      : <YMInitializer accounts={[70863205]} options={{ webvisor: true, defer: true }} version='2'/>
    }
    <ApolloProvider client={apolloClient}>
      <Router>
        <App />
      </Router>
    </ApolloProvider>
  </>
)

ReactDOM.render(
  app,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
