import React, { useState } from 'react'
import { Formik } from 'formik'
import { Row, Col, Input } from '../../UI/Form'
import Button from '../../UI/Button/Button'
import styled from 'styled-components'
import { getDurationInSeconds } from '../../../utils/time'
import { useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
import Modal from '../../UI/Modal/Modal'
import SubmitModal from '../SubmitModal/SubmitModal'
import * as Yup from 'yup'

const CALCULATE_ZONE = loader('../../../apollo/mutations/CalculateZone.graphql')

type WorkoutTestProps = {
  protocol: WorkoutTesting
  sportId: string
}

type TestValues = {
  [key: string]: string
}

type TestingInput = {
  type: string
  value: number
  targetId?: string
  sportId?: string
}

const LocalCol = styled(Col)`
  display: flex;
`

const InputWrapper = styled.div``

const SubmitButton = styled(Button)`
  width: 100%;
  &:disabled {
    border-color: #d8d8d8;
    color: #d8d8d8;
    &:hover {
      background: transparent;
    }
  }
`

const WorkoutTest = ({ protocol, sportId }: WorkoutTestProps) => {
  const [isOpenSubmit, setSubmitModalState] = useState(false)
  const [previewSubmitData, setPreviewSubmitData] = useState()
  const [calculateZone] = useMutation(CALCULATE_ZONE)

  if (!protocol) {
    return <p>Testing protocol not found!</p>
  }

  function openSubmitModal() {
    setSubmitModalState(true)
  }

  function closeSubmitModal() {
    setSubmitModalState(false)
  }

  function formSubmit(values: TestValues) {
    const { methodId } = protocol
    const input: Array<TestingInput> = Object.keys(values).map((name) => {
      let result: TestingInput
      const splitName = name.split(':')
      let value: string | number = values[name]

      if (splitName[0] === 'time' || splitName[1] === '3') {
        value = getDurationInSeconds(value)
      }

      result = {
        type: splitName[0],
        value: +value,
        sportId
      }

      if (splitName[1]) {
        result = {
          ...result,
          targetId: splitName[1],
        }
      }

      return result
    })

    calculateZone({
      variables: {
        input,
        methodId
      },
      fetchPolicy: 'no-cache'
    })
      .then((result) => {
        openSubmitModal()
        setPreviewSubmitData(result.data.calculateZone.zonePreview)
      })
      .catch((error) => console.error(error.message))
  }

  function getInputName(type: string, targetId: string): string {
    if (targetId) {
      return `${type}:${targetId}`
    }
    return type
  }

  function getInitialValues(): {[key: string]: string} {
    return protocol.input.reduce((acc:{[key: string]: string}, input) => {
      const key = getInputName(input.type, input.target?.id)
      if (key) {
        acc[key] = ''
      }
      return acc
    }, {})
  }

  return (
    <>
      <Formik
        initialValues={getInitialValues()}
        onSubmit={formSubmit}
        validationSchema={WorkoutTestSchema}
      >
        {(props) => {
          function getMask(input: any): string | undefined {
            if (input.type === 'time' || input.target?.isPace) {
              return '99:99'
            }
            if (input.type === 'distance' || input.target?.slug === 'hr' || input.target?.slug === 'power') {
              return '999'
            }
            return
          }

          function checkEmptyValues(values: TestValues) {
            let isEmpty = true
            Object.keys(values).forEach((key) => {
              if (values[key] !== '') {
                isEmpty = false
              }
            })
            return isEmpty
          }

          function getUnits(input: WorkoutTestingParams, units: string) {
            if (input.type === 'time') {
              return 'мм:сс'
            }
            if (input.type === 'distance') {
              return 'км'
            }
            return units
          }

          const isEmptyValues = checkEmptyValues(props.values)

          return (
            <>
              <Row>
                {protocol.input.map((input) => {
                  const name = getInputName(input.type, input.target?.id)
                  const units = input.target?.units
                  return (
                    <LocalCol key={name}>
                      <InputWrapper>
                        <Input
                          name={name}
                          mask={getMask(input)}
                          placeholder={input.type !== 'time' ? 'Не знаю' : '00:00'}
                          formik={props}
                          label={{text: input.title, tooltip: input.helpText}}
                          units={getUnits(input, units)}
                          withError
                        />
                      </InputWrapper>
                    </LocalCol>
                  )
                })}
              </Row>
              <Row>
                <SubmitButton type='submit' onClick={() => props.handleSubmit()} disabled={isEmptyValues}>
                  Рассчитать результаты тестирования
                </SubmitButton>
              </Row>
            </>
          )
        }}
      </Formik>
      {isOpenSubmit && (
        <Modal isOpen={isOpenSubmit} closeHandler={closeSubmitModal} portalName='TestSubmitModal'>
          <SubmitModal previewData={previewSubmitData} closeHandler={closeSubmitModal} />
        </Modal>
      )}
    </>
  )
}

export default WorkoutTest

const WorkoutTestSchema = Yup.object().shape({
  'target:1': Yup.string()
    .min(2, 'Слишком маленькая мощность')
    .max(3, 'Слишком большая мощность'),
  'target:2': Yup.string()
    .min(2, 'Слишком маленький пульс')
    .max(3, 'Слишком большой пульс'),
  'target:3': Yup.string()
    .matches(/^([0-5][0-9]:[0-5][0-9]|_{2}:_{2})$/, 'Укажите время в формате ММ:СС'),
  'time': Yup.string()
    .matches(/^([0-5][0-9]:[0-5][0-9]|_{2}:_{2})$/, 'Укажите время в формате ММ:СС'),
})
