import React from 'react'
import styles from './WorkoutChart.module.css'
import Skeleton from 'react-loading-skeleton'
import { Area, AreaChart, Label, ReferenceLine, ResponsiveContainer, Tooltip, YAxis } from 'recharts'
import CustomTooltip from '../CustomTooltip/CustomTooltip'
import { useTargets } from '../../../hooks/useTargets'
import { getReferenceValue, memoizedGenerateChartData } from '../../../utils/chart'
import { gql, useApolloClient } from '@apollo/client'

type WorkoutChartProps = {
  id: number
  steps: Array<WorkoutStep> | null
  sport: Sport
  chartTarget: string
}

const WorkoutChart = ({ id, steps, sport, chartTarget }: WorkoutChartProps) => {
  const [targets] = useTargets()
  const client = useApolloClient()
  const response = client.readFragment({
    id: 'User:{}',
    fragment: gql`
      fragment UserZones on User {
          zones {
              id
              threshold
              isCalculated
              targetId
              sportId
              target {
                  id
                  slug
              }
          }
      }
    `
  })
  const zones = response?.zones;

  if (!zones) {
    return <Skeleton height={200}/>
  }

  if (steps === null || steps.length === 0) {
    return null
  }

  const referenceValue = getReferenceValue(zones, chartTarget, sport)
  const chartData = memoizedGenerateChartData(id)(steps, targets)

  return (
    <div className={styles.chart}>
      <ResponsiveContainer width='100%' height={200}>
        <AreaChart
          data={chartData}
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
          style={{ background: '#000000' }}
        >
          <Tooltip content={<CustomTooltip />} isAnimationActive={false} />
          <YAxis hide domain={[0, referenceValue ? referenceValue * 1.5 : 10]} allowDataOverflow={true}/>
          <Area
            type='step'
            dataKey={chartTarget}
            stroke='#2F80ED'
            fill='#2F80ED'
            fillOpacity={1}
            strokeWidth={0}
            isAnimationActive={false}
          />
          <ReferenceLine y={referenceValue} stroke='#D0D0D0' ifOverflow='extendDomain'>
            <Label value='ПАНО' position='insideBottomRight' fontSize={12} fontWeight='bold' fill='#D0D0D0' />
          </ReferenceLine>
        </AreaChart>
      </ResponsiveContainer>
    </div>
  )
}

export default React.memo(WorkoutChart)
