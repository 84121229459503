import React, {memo} from 'react';
import {InView} from 'react-intersection-observer';
import {useCalendarTools} from '../../../hooks/useCalendar';
import {InfinityLoaderProps} from './types';

export const WithInfinityLoader: React.FC<InfinityLoaderProps> = memo(function WithInfinityLoader(
  {
    children,
    isReady = true
  }
) {
  const calendarTools = useCalendarTools()

  return isReady ? (
    <>
      <InView
        as='div'
        onChange={(inView) => inView && calendarTools.addToStart()}
        children={null}
        rootMargin='350px'
      />
      {children}
      <InView
        as='div'
        onChange={(inView) => inView && calendarTools.addToEnd()}
        children={null}
        rootMargin='300px'
      />
    </>
  ) : (
    <>
      {children}
    </>
  )
})
