import React from 'react'
import styles from './Button.module.css'

type ButtonProps = {
  className?: string
  id?: string
  children: JSX.Element | string
  type?: 'button' | 'submit' | 'reset'
  solid?: boolean
  onClick?: () => void
  disabled?: boolean
}

const Button = ({ className, id, children, type, solid, onClick, disabled }: ButtonProps) => {
  const cls = [styles.default]
  if (solid) {
    cls.push(styles.solid)
  }
  if (className) {
    cls.push(className)
  }
  return (
    <button
      id={id}
      className={cls.join(' ')}
      type={type || 'button'}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export default React.memo(Button)