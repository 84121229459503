import React from 'react'
import { ErrorMessage, Field, FormikProps} from 'formik'
import InputMask from 'react-input-mask'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'

type FormFieldProps = {
  as?: string
  name: string
  className?: string
  label?: string | {
    text: string
    tooltip?: string
  }
  type?: string
  maskChar?: string | null
  placeholder?: string
  value?: string
  onChange?: ((e: React.ChangeEvent<any>) => void) | any
  onBlur?: (e: React.FocusEvent<any>, opt?: any) => void
  formik: FormikProps<any>
  mask?: string
  units?: string
  withError?: boolean
  disabled?: boolean
  autocomplete?: string
}

const FieldStyled = styled.div<{hasValue: boolean}>`
  position: relative;
  display: flex;
  width: 100%;
  background: ${props => props.hasValue ? '#FFFFFF' : '#F7F7F7'};
  border: 1px solid #C4C4C4;
  border-radius: 2px;
  color: #3E3E3E;
  outline-color: #C4C4C4;
  margin: 0;
  &:focus-within {
    background-color: #FFFFFF;
  }
  & input, & select, & textarea {
    width: 100%;
    min-width: 0;
    border: none;
    background: transparent;
    font-size: .875rem;
    height: 37px;
    padding: 0 10px;
    &::placeholder {
      color: #8E8E8E;
      opacity: 1;
    }
    &:focus {
      outline: none;
    }
    &:disabled {
      background: #E3E3E3;
      color: #8E8E8E;
    }
   }
  & textarea {
    padding: 10px;
    min-width: 100%;
    min-height: 100px;
  }
`

const LabelStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 7px;
  & label {
    font-size: .75rem;
    line-height: 1.3;
    color: #565656;
  }
`

const Prompt = styled.div`
  cursor: help;
  color: #D8D8D8;
  font-size: .5rem;
  min-width: 15px;
  min-height: 15px;
  border: 1px solid #D8D8D8;
  border-radius: 50%;
  &:hover {
    color: #444444;
    border-color: #444444;
  }
  & p {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 13px;
    margin: 0;
  }
`

const UnitsStyled = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: .75rem;
  padding-right: 10px;
`

const ErrorStyled = styled(ErrorMessage)`
  font-size: .75rem;
  color: #CC0000;
  padding: 3px 2px;
  line-height: 1.3;
`

const InputTooltip = styled(ReactTooltip)`
  max-width: 320px;
  & p {
    line-height: 1.35;
  }
`

export const Input = (props: FormFieldProps) => {
  const {
    as,
    className,
    label,
    name,
    type,
    maskChar,
    placeholder,
    mask,
    value,
    units,
    onChange,
    onBlur,
    formik,
    withError,
    disabled = false,
    autocomplete
  } = props
  const { values, handleChange, handleBlur } = formik
  return (
    <>
      {label && typeof label === 'string'
        ? (
          <LabelStyled>
            <label>{label}</label>
          </LabelStyled>
        )
        : typeof label === 'object' && (
        <LabelStyled>
          <label>{label.text}</label>
          {label.tooltip && (
            <>
              <Prompt data-tip data-for={`${name}-prompt`}>
                <p>?</p>
              </Prompt>
              <InputTooltip id={`${name}-prompt`} place='top' effect='solid'>
                <p>{label.tooltip}</p>
              </InputTooltip>
            </>
          )}
        </LabelStyled>
        )
      }
      <FieldStyled className={className} hasValue={disabled ? false : !!formik.values[name]}>
        {mask
          ? (
            <InputMask
              mask={mask}
              name={name}
              maskChar={maskChar ? maskChar : null}
              placeholder={placeholder}
              defaultValue={value ? value : values[name]}
              onChange={onChange ? onChange : handleChange}
              onBlur={onBlur ? onBlur : handleBlur}
              disabled={disabled}
              autoComplete={autocomplete}
            />
          )
          : (
            <Field
              as={as}
              name={name}
              type={type}
              placeholder={placeholder}
              value={values[name] || ''}
              onChange={onChange ? onChange : handleChange}
              onBlur={onBlur ? onBlur : handleBlur}
              autoComplete={autocomplete}
              disabled={disabled}
            />
          )
        }
        {units ? <UnitsStyled >{units}</UnitsStyled> : null }
      </FieldStyled>
      {withError && <ErrorStyled name={name} component='div'/> }
    </>
  )
}
