import styled from 'styled-components'

export const Tab = styled.li<{active: boolean}>`
  cursor: pointer;
  background-color: ${(props => props.active ? '#FFFFFF' : null)};
  padding: 14px 20px 12px 28px;
  text-transform: uppercase;
  color: ${(props) => props.active ? '#3E3E3E' : '#8F8F8F'};
  font-size: .75rem;
  font-weight: 500;
  &:hover {
    color: #3E3E3E;
    background-color: ${(props => props.active ? '#FFFFFF' : '#F3F3F3')};
  }
`