import React from 'react'
import styled from 'styled-components'

type TabProps = {
  content: {
    [key in 'WARMUP' | 'ACTIVE' | 'COOLDOWN']: any
  }
}

const StageList = styled.div`
  margin: 0;
  padding: 5px 0;
`

const Stage = styled.div`
  padding: 5px 0;
`

const Subtitle = styled.h3`
  color: #828282;
  font-weight: 500;
  margin: 0;
  padding: 4px 0 3px;
  font-size: .75rem;
  text-transform: uppercase;
`

const StepList = styled.ul`
  padding: 0;
  margin: 0;
`

const Tab = ({ content }:TabProps) => {
  if (!content) {
    return null
  }
  return (
    <StageList>
      {content['WARMUP'] && !isEmptyObj(content['WARMUP']) && (
        <Stage>
          <Subtitle>Разминка</Subtitle>
          <StepList>{ generateView(content['WARMUP']) }</StepList>
        </Stage>
      )}
      {content['ACTIVE'] && !isEmptyObj(content['ACTIVE']) && (
        <Stage>
          <Subtitle>Основная работа</Subtitle>
          <StepList>{ generateView(content['ACTIVE']) }</StepList>
        </Stage>
      )}
      {content['COOLDOWN'] && !isEmptyObj(content['COOLDOWN']) && (
        <Stage>
          <Subtitle>Заминка</Subtitle>
          <StepList>{ generateView(content['COOLDOWN']) }</StepList>
        </Stage>
      )}
    </StageList>
  )
}

export default Tab

function generateView(arg:string | Array<any>, index?: number) {
  if (arg instanceof Array) {
    if (isInterval(arg)) {
      const [count, ...steps] = arg
      if (steps.some(isInterval)) {
        return generateSequenceView(count, steps)
      }
      return generateIntervalView(count, steps)
    }
    return generateStepsView(arg)
  }
  return generateStepView(arg, index)
}

function generateSequenceView(count:number, steps: Array<any>) {
  const complexInterval:any = steps.map(generateView)
  const sequenceOfIntervals = []
  for (let i = 0; i < count; i += 1) {
    sequenceOfIntervals.push(complexInterval)
  }
  return sequenceOfIntervals
}

const ComplexStep = styled.li`
  display: flex;
  padding: 3px 0;
`

const Count = styled.span`
  padding: 4px 7px 0 0;
  color: #4F4F4F;
  font-size: .875rem;
  &:before {
    content: "–";
    padding-right: 4px;
    width: 15px;
  }
`

const RepeatSteps = styled.ul`
  padding-left: 7px;
  border-left: 1px solid #D8D8D8;
  & li:before {
    content: none;
  }
`

const keyCache: {[key: number]: string} = {}

function getUniqueKey(num: number) {
  if (!keyCache[num]) {
    keyCache[num] = String(num)
    return keyCache[num]
  }
  keyCache[num] = String(+keyCache[num] + 1)
  return keyCache[num]

}

function generateIntervalView(count: number, steps: Array<any>) {
  return (
    <ComplexStep key={getUniqueKey(count)}>
      <Count>{`${count}x`}</Count>
      <RepeatSteps>{steps.map(generateView)}</RepeatSteps>
    </ComplexStep>
  )
}

function generateStepsView(steps: Array<any>): any {
  return steps.map(generateView)
}

const SingleStep = styled.li`
  padding: 4px 0;
  position: relative;
  color: #4F4F4F;
  font-size: .875rem;
  &:before {
    content: "–";
    padding-right: 4px;
    width: 15px;
  }
`

function generateStepView(step: string, index?: number) {
  return <SingleStep key={step + index}>{step}</SingleStep>
}

function isInterval(arg: Array<any>) {
  const [first] = arg
  return typeof first === 'number'
}

function isEmptyObj(obj: object) {
  return Object.keys(obj).length === 0
}
