import React from 'react'
import { Paragraph } from '../../../UI/Form'
import format from "date-fns/format"
import parseISO from "date-fns/parseISO"
import ru from "date-fns/locale/ru"
import styled from 'styled-components'
import differenceInWeeks from "date-fns/differenceInWeeks"
import addWeeks from 'date-fns/addWeeks'
import PlanBuilderWeek from '../../PlanBuilderWeek/PlanBuilderWeek'
import startOfWeek from "date-fns/startOfWeek"
import endOfWeek from "date-fns/endOfWeek"
import isWithinInterval from "date-fns/isWithinInterval"
import { useSport } from '../../../../hooks/useSport'

const MIN_PERIOD_DURATION = 1

type PlanPreviewPeriodAllWorkoutsProps = {
  workouts: Array<PreviewWorkout>
  events: Array<UserEvent>
  title: string
  startDate: string
  endDate: string
}

const Wrapper = styled.div`
  padding: 15px 0;
`

const Title = styled.span`
  font-weight: 500;
  display: inline-block;
`

const Range = styled.span`
  margin-left: 5px;
  display: inline-block;
  position: relative;
  padding-left: 8px;
  &:before {
    content: '·';
    position: absolute;
    left: 0;
  }
`

const PlanPreviewPeriodAllWorkouts = ({ workouts, title, startDate, endDate, events }: PlanPreviewPeriodAllWorkoutsProps) => {
  const sport = useSport()
  const duration = differenceInWeeks(parseISO(endDate), startOfWeek(parseISO(startDate))) || MIN_PERIOD_DURATION

  function getWeekWorkouts(startDate: string, endDate: string): Array<TempScheduleInput> {
    return workouts
      .filter(({ date }) => (
        isWithinInterval(parseISO(date), {start: parseISO(startDate), end: parseISO(endDate)})
      ))
      .map((workout) => ({
        ...workout,
        sport: sport.getById(workout.sport.id),
        plot: workout.plot || []
      }))
  }

  function renderPeriodWeeks() {
    const toStartDate = parseISO(startDate)
    const toEndDate = parseISO(endDate)
    let weeks: Array<string[]> = []
    for (
      let start = toStartDate;
      isWithinInterval(start, {start: toStartDate, end: toEndDate});
      start = addWeeks(start, 1)
    ) {
      const weekStart = format(startOfWeek(start, { weekStartsOn: 1 }), 'yyyy-MM-dd')
      const weekEnd = format(endOfWeek(start, { weekStartsOn: 1 }), 'yyyy-MM-dd')
      weeks.push([weekStart, weekEnd])
    }
    return weeks.map(([weekStartDate, weekEndDate]) => {
      const weekWorkouts = getWeekWorkouts(weekStartDate, weekEndDate)
      return <PlanBuilderWeek key={weekStartDate + weekEndDate} workouts={weekWorkouts} events={events} />
    })
  }

  return (
    <Wrapper>
      <Paragraph>
        <Title>{`${title} (${duration} нед)`}</Title>
        <Range>
          {`
            ${format(parseISO(startDate), 'd MMM yyyy', {locale: ru})}
            –
            ${format(parseISO(endDate), 'd MMM yyyy', {locale: ru})}
          `}
        </Range>
      </Paragraph>
      {renderPeriodWeeks()}
    </Wrapper>
  )
}

export default PlanPreviewPeriodAllWorkouts
