import {gql} from '@apollo/client';

export const UPDATE_WORKOUT = gql`
    mutation UpdateWorkout($input: UpdateWorkoutInput!) {
        updateWorkout(input: $input) {
            workout {
                id
                date
                completed
                order
            }
        }
    }
`
