import React, { useState } from 'react'
import styled from 'styled-components'
import exportIcon from '../../../assets/img/icons/export.png'
import ReactTooltip from 'react-tooltip'
import ExportModal from './ExportModal/ExportModal'

type ExportProps = {
  download: Array<WorkoutExport>
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
`

const ExportButton = styled.button`
  cursor: pointer;
  border: none;
  width: 19px;
  height: 19px;
  background-image: url(${exportIcon});
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  &:active {
    transform: scale(.95);
  }
`

const Export = ({ download }: ExportProps) => {
  const [isOpenModal, setOpenModal] = useState(false)

  function handleExportButtonClick() {
    setOpenModal(!isOpenModal)
  }

  return (
    <Wrapper>
      <ExportButton type='button' onClick={handleExportButtonClick} data-tip data-for='export-button'/>
      <ReactTooltip id='export-button' place='top' effect='solid'>
        <p style={{textAlign: 'center'}}>Экспортировать тренировку</p>
      </ReactTooltip>
      {isOpenModal && <ExportModal isOpen={isOpenModal} closeHandler={handleExportButtonClick} downloadLinks={download}/>}
    </Wrapper>
  )
}

export default Export