import React, { useEffect, useState } from 'react'
import AppHeaderContainer from '../../containers/AppHeaderContainer'
import { Calendar } from '../../components/Calendar';
import ym from 'react-yandex-metrika'
import ReactGA from 'react-ga'
import { useQuery } from '@apollo/client'
import { userEvents, userProgramms } from '../../apollo/cache'
import { loader } from 'graphql.macro'
import {FETCH_USER_PROGRAMMS} from './graphql';

const GET_USER = loader('../../apollo/queries/User.graphql')
const GET_USER_EVENTS = loader('../../apollo/queries/Events.graphql')

const AppPage = () => {
  const [skip, setSkip] = useState(false)
  const { loading:userLoading, error:userError } = useQuery(GET_USER, {
    onError: (error) => console.error(error.message),
    skip
  })
  const { error:userEventsError } = useQuery(GET_USER_EVENTS, {
    onCompleted: (result) => userEvents(result.events),
    skip
  })

  const { error:userProgrammsError } = useQuery(FETCH_USER_PROGRAMMS, {
    onCompleted: (result) => userProgramms(result.programms),
    skip
  })

  useEffect(() => {
    ym('hit', '/')
    ReactGA.pageview('/')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (userError || userEventsError || userProgrammsError) {
    setSkip(true)
  }

  if (userLoading) {
    return null
  }

  return (
    <>
      <AppHeaderContainer />
      <Calendar />
    </>
  )
}

export default AppPage
