import React from 'react'
import ReactModal from 'react-modal'
import styled from 'styled-components'

ReactModal.setAppElement('#root')

type ModalProps = {
  isOpen: boolean
  portalName?: string
  children: JSX.Element
  closeHandler: () => void
  customStyles?: {
    overlay?: any,
    content?: any
  }
}

const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  font-size: 2rem;
  top: 25px;
  right: 20px;
  line-height: .5;
`

const modalStyles: {overlay: any, content: any} = {
  overlay: {
    display: 'flex',
    alignItems: 'flex-start',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    overflowY: 'scroll',
    WebkitOverflowScrolling: 'touch',
    zIndex: 1000
  },
  content: {
    position: 'relative',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    border: 'none',
    borderRadius: 0,
    background: '#FFFFFF',
    padding: 0,
    overflow: 'none',
    margin: '100px auto 20px'
  }
}

const Modal = ({ isOpen, closeHandler, children, portalName, customStyles }: ModalProps) => {
  let styles = modalStyles

  if (customStyles) {
    styles = {
      overlay: {
        ...styles.overlay,
        ...customStyles.overlay
      },
      content: {
        ...styles.content,
        ...customStyles.content
      }
    }
  }

  function closeModal() {
    closeHandler()
  }

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      htmlOpenClassName='ReactModal__Html--open'
      portalClassName={portalName}
      shouldFocusAfterRender={false}
      bodyOpenClassName={null}
      style={styles}
    >
      {children}
      <CloseButton onClick={closeModal}>&times;</CloseButton>
    </ReactModal>
  )
}

export default Modal
