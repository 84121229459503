import { loadAsyncScript } from '../utils/loadAsyncScript'

type LivechatConstructor = {
  appId: string
  initState?: boolean
  afterLoad: (...args: any) => void
}

export class Livechat {
  private readonly appId: string
  constructor ({ appId, afterLoad }: LivechatConstructor) {
    this.appId = appId

    if (!window.LiveChatWidget) {
      window.__lc = window.__lc || {}
      window.__lc.license = appId
      this.load(afterLoad)
    }
  }

  // Load Livechat script with defer
  load(afterLoad: (...args: any) => void) {
    if (!window || !document) {
      return
    }

    const defLoad = () => loadAsyncScript(afterLoad)

    if (document.readyState === 'complete') {
      loadAsyncScript(afterLoad)
    }
    else {
      window.addEventListener('load', defLoad, false)
    }
  }

  call(title: string, options?: any) {
    if (window.LiveChatWidget) {
      window.LiveChatWidget.call(title, options)
    }
  }

  showMinimize() {
    if (window.LiveChatWidget) {
      this.call('minimize')
    }
  }

  showMaximize() {
    if (window.LiveChatWidget) {
      this.call('maximize')
    }
  }

  hideGreeting() {
    if (window.LiveChatWidget) {
      this.call('hide_greeting')
    }
  }

  destroy() {
    if (window.LiveChatWidget) {
      this.call('destroy')
    }
  }
}
