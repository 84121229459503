import React from 'react'
import Modal from '../../../UI/Modal/Modal'
import styled from 'styled-components'
import { Col, Paragraph, Row, Title } from '../../../UI/Form'
import zwiftLogo from '../../../../assets/img/zwift.png'
import garminLogo from '../../../../assets/img/garmin.png'
import { useToken } from '../../../../hooks/auth/useToken'

type ExportModalProps = {
  isOpen: boolean
  closeHandler: () => void
  downloadLinks: Array<WorkoutExport>
}

const ExportModalWrapper = styled.div`
  min-width: 300px;
  background-color: #FFFFFF;
  padding: 28px 30px 20px;
  
`

const LinksList = styled.ul`
  max-width: 400px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  grid-gap: 28px 20px;
  overflow: hidden;
  justify-items: center;
`

const LinkItem = styled.li`
  display: flex;
  max-width: 118px;
  flex-direction: column;
  align-items: center;
  & a {
    display: flex;
    width: 118px;
    height: 52px;
    background-color: #EBEBEB;
    color: #000000;
    justify-content: center;
    align-items: center;
    &:hover {
      text-decoration: none;
      background-color: #E4E4E4;
    }
  }
`

const Logo = styled.div`
  width: 118px;
  height: 48px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ModalStyles = {
  overlay: {
    alignItems: 'center'
  },
  content: {
    marginTop: 0
  }
}

function getExportItem(type: string) {
  const toLowerType = type.toLowerCase()
  switch (toLowerType) {
    case 'zwift': {
      return { anchor: 'zwo', logo: zwiftLogo }
    }
    case 'garmin': {
      return { anchor: 'fit', logo: garminLogo }
    }
    default: {
      return { anchor: type }
    }
  }
}

const ExportModal = ({ isOpen, closeHandler, downloadLinks }: ExportModalProps) => {
  const [accessToken] = useToken('access')
  return (
    <Modal isOpen={isOpen} closeHandler={closeHandler} portalName='ExportModal' customStyles={ModalStyles}>
      <ExportModalWrapper>
        <Title>Экспорт тренировки</Title>
        <Row>
          <Col>
            <Paragraph style={{maxWidth: 400}}>Выберите тип файла который совместим с вашим устройством или приложением.</Paragraph>
          </Col>
        </Row>
        <Row>
          <Col>
            <LinksList>
              {downloadLinks.map(({ type, url }, index) => {
                const item = getExportItem(type)
                const urlWithAccess = url + `&token=${accessToken}`
                return (
                  <LinkItem key={type + index}>
                    <a target="_blank" rel="noreferrer" href={urlWithAccess}>{item.anchor.toUpperCase()}</a>
                    {item.logo && (
                      <Logo>
                        <img src={item.logo} alt={`${type}-logo`}/>
                      </Logo>
                    )}
                  </LinkItem>
                )
              })}
            </LinksList>
          </Col>
        </Row>
      </ExportModalWrapper>
    </Modal>
  )
}

export default ExportModal
