export function loadAsyncScript (afterLoad: (...args: any) => void) {
  const c = [].slice

  function i(n: any) {
    // @ts-ignore
    return e._h ? e._h.apply(null, n) : e._q.push(n)
  }
  const e = {
    _q: [],
    _h: null,
    _v: "2.0",
    on: function() {
      i(["on", c.call(arguments)])
    },
    once: function() {
      i(["once", c.call(arguments)])
    },
    off: function() {
      i(["off", c.call(arguments)])
    },
    get: function() {
      if (!e._h) {
        throw new Error("[LiveChatWidget] You can't use getters before load.")
      }
      return i(["get", c.call(arguments)])
    },
    call: function() {
      i(["call", c.call(arguments)])
    },
    init: function() {
      const n = document.createElement("script");
      n.async = !0
      n.type = "text/javascript"
      n.src = "https://cdn.livechatinc.com/tracking.js"
      n.onload = afterLoad
      document.head.appendChild(n)
    }
  }
  !window.__lc.asyncInit && e.init()
  window.LiveChatWidget = window.LiveChatWidget || e
}
