type UseTokenResult = [
  string | null,
  (token: string) => void,
  () => void
]

export function useToken(type: 'access' | 'refresh' = 'access'): UseTokenResult {
  const storageKey = type === 'access' ? 'Access Token' : 'Refresh Token'

  function getToken(): string | null {
    return localStorage.getItem(storageKey)
  }

  function setToken(token: string): void {
    localStorage.setItem(storageKey, token)
  }

  function removeToken(): void {
    localStorage.removeItem(storageKey)
  }

  const token = getToken()

  return [token, setToken, removeToken]
}