import { useToken } from './useToken'
import { gql, useApolloClient } from '@apollo/client'

const REVOKE_TOKEN = gql`
    mutation RevokeToken($refreshToken: String) {
        revokeToken(refreshToken: $refreshToken) {
            revoked
        }
    }
`

type Session = {
  login: (token: string, refreshToken: string) => void
  logout: () => void
  isValid: () => boolean
}

export function useSession(): Session {
  const [accessToken, setAccessToken, removeAccessToken] = useToken('access')
  const [refreshToken, setRefreshToken, removeRefreshToken] = useToken('refresh')
  const client = useApolloClient()

  function revokeRefreshToken(token: string | null) {
    client.mutate({
      mutation: REVOKE_TOKEN,
      variables: {
        refreshToken: token
      }
    })
  }

  function login(token: string, refreshToken: string) {
    setAccessToken(token)
    setRefreshToken(refreshToken)
  }

  async function logout() {
    removeAccessToken()
    if (refreshToken) {
      await revokeRefreshToken(refreshToken)
      await client.clearStore()
    }
    removeRefreshToken()
  }

  function isValid() {
    const isAccessExist = Boolean(accessToken)
    if (!isAccessExist) {
      removeRefreshToken()
      client.clearStore()
    }
    return isAccessExist
  }

  return {
    login,
    logout,
    isValid,
  }
}

