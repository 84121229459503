import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import Step1 from './steps/_1'
import Step2 from './steps/_2'
import Step2A from './steps/_2a'
import Step2B from './steps/_2b'
import Step3 from './steps/_3'
import Step4 from './steps/_4'
import ym from 'react-yandex-metrika'
import ymGoals from '../../analytics/ymGoals'
import { PlanBuilderProvider, PlanBuilderStoreValue } from '../../services/PlanBuilder/'
import PlanBuilderMaintenance from './maintenance/PlanBuilderMaintenance'

const GET_TRAINING_PROGRAMMS = loader('../../apollo/queries/TrainingProgramms.graphql')

type PlanBuilderProps = {
  closeHandler: () => void
}

const Wrapper = styled.div`
  padding: 25px 28px;
  min-width: 1100px;
`

const Header = styled.h2`
  font-size: .875rem;
  color: #B0B0B0;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;
`

const TabPicker = styled.div`
  padding: 30px 0;
`

const TabPickerList = styled.ul`
  display: flex;
`

const TabPickerListItem = styled.li<{active?:boolean}>`
  display: flex;
  justify-content: center;
  font-size: .75rem;
  color: ${props => props.active ? '#3E3E3E' : '#BDBDBD'};
  font-weight: 500;
  padding: 0 30px 4px;
  border-bottom: 3px solid;
  border-color: ${props => props.active ? '#2CAB00': 'inherit'};
  &:first-child {
    justify-content: flex-start;
    padding-left: 0;
  }
`

export type Tabs = {
  [keyof in '1' | '2' | '2a' | '2b' | '3' | '4']: {
    name: string
    title: string
    hide?: boolean
  }
}

const tabs: Tabs = {
  '1': {
    name: 'Начало тренировок',
    title: 'Укажите дату начала тренировок',
  },
  '2': {
    name: 'События',
    title: 'Добавьте события'
  },
  '2a': {
    name: 'Нет событий',
    title: 'Выберите тип программы',
    hide: true
  },
  '2b': {
    name: 'Тестирование',
    title: 'Добавьте тестирование',
    hide: true
  },
  '3': {
    name: 'Расписание',
    title: 'Настройте свой календарь'
  },
  '4': {
    name: 'Утверждение',
    title: 'Ваша тренировочная программа'
  }
}

const PlanBuilder = ({ closeHandler }: PlanBuilderProps) => {
  const [store, setStore] = useState<PlanBuilderStoreValue>({})
  const [activeTab, setActiveTab] = useState<keyof Tabs>('1')

  const { error } = useQuery(GET_TRAINING_PROGRAMMS, {
    onCompleted: (data) => addToStore({ availablePrograms: data.trainingProgramms }),
    onError: (error => console.error(error.message)),
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    ym('reachGoal', ymGoals.planbuilder.open)
  }, [])

  if (error) {
    return <PlanBuilderMaintenance />
  }

  function stepRender(tab: string) {
    switch (tab) {
      case '1':
        return (
          <Step1
            key={activeTab}
            title={tabs[activeTab].title}
            tabSwitcher={setActiveTab}
          />
        )
      case '2':
        return (
          <Step2
            key={activeTab}
            title={tabs[activeTab].title}
            tabSwitcher={setActiveTab}
          />
        )
      case '2a':
        return (
          <Step2A
            key={activeTab}
            title={tabs[activeTab].title}
            tabSwitcher={setActiveTab}
          />
        )
      case '2b':
        return (
          <Step2B
            key={activeTab}
            title={tabs[activeTab].title}
            tabSwitcher={setActiveTab}
          />
        )
      case '3':
        return (
          <Step3
            key={activeTab}
            title={tabs[activeTab].title}
            tabSwitcher={setActiveTab}
          />
        )
      case '4':
        return (
          <Step4
            key={activeTab}
            title={tabs[activeTab].title}
            tabSwitcher={setActiveTab}
            closeHandler={closeHandler}
          />
        )
      default:
        return null
    }
  }

  function addToStore(newState:Partial<PlanBuilderStoreValue>) {
    setStore(prevState => ({
      ...prevState,
      ...newState
    }))
  }

  return (
    <PlanBuilderProvider store={{value: store, update: addToStore}}>
      <Wrapper>
        <Header>Конструктор плана</Header>
        <TabPicker>
          <TabPickerList>
            {Object.keys(tabs).map((key) => (
              // @ts-ignore
              !tabs[key].hide && <TabPickerListItem key={key} active={activeTab === key}>{tabs[key].name}</TabPickerListItem>
            ))}
          </TabPickerList>
        </TabPicker>
        {stepRender(activeTab)}
      </Wrapper>
    </PlanBuilderProvider>
  )
}

export default PlanBuilder
