import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import styled from 'styled-components'
import Workout from '../components/Workout/Workout'
import Modal from '../components/UI/Modal/Modal'
import { addPaddingToShiftElements, getScrollBarWidth, removePaddingFromShiftElements } from '../utils/window'
import Skeleton from 'react-loading-skeleton'

const SingleWorkout = loader('../apollo/queries/SingleWorkout.graphql')

type WorkoutContainerProps = {
  id: number
  closeHandler: () => void
}

const WorkoutWrapper = styled.div`
  max-width: 950px;
  padding: 25px 20px;
`

const WorkoutContainer = ({ id, closeHandler }: WorkoutContainerProps) => {
  const [workout, setWorkout] = useState<WorkoutDay>()
  const [isOpen, setIsOpen] = useState(true)
  const scrollBarWidth = getScrollBarWidth()

  const { loading } = useQuery(SingleWorkout, {
    variables: {
      id
    },
    onCompleted: (result) => result && setWorkout(result.workout),
    onError: (error) => console.error(error.message)
  })

  useEffect(() => {
    addPaddingToShiftElements(scrollBarWidth)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function closeWorkoutModal() {
    removePaddingFromShiftElements()
    setIsOpen(false)
    closeHandler()
  }

  return (
    <Modal
      isOpen={isOpen}
      closeHandler={closeWorkoutModal}
      portalName='WorkoutModal'
    >
      <WorkoutWrapper>
        {loading || !workout
          ? <WorkoutSkeleton />
          : <Workout {...workout}/>
        }
      </WorkoutWrapper>
    </Modal>
  )
}

export default WorkoutContainer

const WorkoutSkeleton = () => {
  return (
    <div style={{width: 910}}>
      <Skeleton height={20} style={{marginBottom: 7}}/>
      <Skeleton height={40} style={{marginBottom: 15}}/>
      <Skeleton height={200} style={{marginBottom: 15}}/>
      <Skeleton height={30} count={7} style={{marginBottom: 10}}/>
    </div>
  )
}
