import styled from 'styled-components';
import editIcon from '../../../assets/img/icons/edit.svg';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
`

export const EditButton = styled.button`
  cursor: pointer;
  border: none;
  width: 19px;
  height: 19px;
  background-image: url(${editIcon});
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  &:active {
    transform: scale(.95);
  }
`
