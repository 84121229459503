import moment  from 'moment'
moment.locale('ru')

function getDurationInSeconds(duration: string):number {
  if (!duration || duration === '') {
    return 0
  }

  let mappedTime: Array<number> = [0]
  const splittedTime = duration.split(':')

  if (splittedTime.length === 1) {
    console.error(`Function 'getDurationInSeconds' get invalid input variable – '${duration}'. Check input value, please.`)
  }

  if (splittedTime.length === 2) {
    mappedTime = splittedTime
      .map((timeUnit, index) => {
        switch (index) {
          case 0: {
            return +timeUnit * 60
          }
          case 1: {
            return +timeUnit
          }
          default:
            return 0
        }
      })
  }

  if (splittedTime.length === 3) {
    mappedTime = splittedTime
      .map((timeUnit, index) => {
        switch (index) {
          case 0: {
            return +timeUnit * 3600
          }
          case 1: {
            return +timeUnit * 60
          }
          case 2: {
            return +timeUnit
          }
          default:
            return 0
        }
      })
  }

  return mappedTime.reduce((acc, unit) => acc + unit, 0)
}

function durationToString(duration: number, mode: number = 1): string {
  const durationObj = getDurationObj(duration)
  return durationObjToString(durationObj, mode)
}

function durationObjToString(duration: IWorkoutDuration, mode: number = 1): string {
  return Object.keys(duration)
    .filter((key) => !key.startsWith('__'))
    .map((key) => {
      if ((mode === 2 || mode === 3) && key === 'hours') {
        return timeToString(duration[key], mode)
      }
      return timeToString(duration[key], 1)
    })
    .filter((value => !!value))
    .join(':')
}

export function getDurationObj(src:number | string): IWorkoutDuration {
  const hours = Math.floor(+src / 3600)
  const minutes = Math.floor((+src - (hours * 3600)) / 60)
  const seconds = Math.floor(+src - (hours * 3600 + minutes * 60))
  return {
    hours,
    minutes,
    seconds,
  }
}

function getDurationPercent(devident:number | string , divisor: number | string ):number {
  let [dev, div] = [+devident, +divisor]

  if (div < dev) {
    [dev, div] = [div, dev]
  }
  return Math.floor(100 * (dev / div))
}

function timeToString(value:number | null, mode: number): string | null {
  const strValue = String(value)

  if (mode === 1 && !value) {
    return '00'
  }

  if (mode === 1 && strValue.length < 2) {
    return '0' + strValue
  }

  if (mode === 3) {
    return null
  }

  return value ? strValue : '0'
}

function getWeekHead(day: string): string {
  const momentDay = moment(day)
  return momentDay.weekday() === 0
    ? momentDay.format('YYYY-MM-DD')
    : momentDay.subtract(momentDay.weekday(), 'day').format('YYYY-MM-DD')
}

function getWeekTail(day: string): string {
  const momentDay = moment(day)
  return moment(day).weekday() === 7
    ? momentDay.format('YYYY-MM-DD')
    : momentDay.add(7 - momentDay.weekday(), 'day').format('YYYY-MM-DD')
}

export {
  getDurationInSeconds,
  durationToString,
  durationObjToString,
  getDurationPercent,
  getWeekHead,
  getWeekTail
}
