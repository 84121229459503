import React from 'react'
import { Area, AreaChart, ResponsiveContainer } from 'recharts'
import styled from 'styled-components'

interface CalendarWorkoutChartProps {
  chartData: Array<number[]>
  cursorStyle: 'pointer' | 'grab' | 'default'
}

function generateChartData(sourceData:Array<number[]>) {
  const result:Array<{height: number, point: number}> = []
  sourceData.forEach((rawPoint) => {
    const pointValue = Math.floor(rawPoint[0])
    for (let i = 0; i < Math.ceil(rawPoint[1]); i += 1) {
      result.push({
        height: pointValue,
        point: i
      })
    }
  })
  return result
}

const Wrapper = styled(ResponsiveContainer)<{cursorStyle: 'pointer' | 'grab' | 'default'}>`
  cursor: ${({cursorStyle}) => cursorStyle};
  & > .recharts-wrapper {
    cursor: ${({cursorStyle}) => cursorStyle};
  }
  & svg {
    cursor: ${({cursorStyle}) => cursorStyle};
  }
`

const CalendarWorkoutChart = ({ chartData, cursorStyle }: CalendarWorkoutChartProps) => {
  const data = generateChartData(chartData)
  return (
    <Wrapper cursorStyle={cursorStyle}>
      <AreaChart
        data={data}
        margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
      >
        <Area
          type='step'
          dataKey='height'
          stroke='#2F80ED'
          fill='#2F80ED'
          fillOpacity={1}
          strokeWidth={0}
          isAnimationActive={false}
        />
      </AreaChart>
    </Wrapper>
  )
}

export default React.memo(CalendarWorkoutChart)
