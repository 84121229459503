import React, { SyntheticEvent } from 'react'
import { Col, Paragraph, Row } from '../../UI/Modal'
import styled from 'styled-components'
import format from "date-fns/format"
import parseJSON from "date-fns/parseJSON"
import ru from "date-fns/locale/ru"
import Button from '../../UI/Button/Button'
import { useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'

const CANCEL_SUBSCRIPTION = loader('../../../apollo/mutations/payment/CancelSubscription.graphql')

const Title = styled.h2`
  font-size: .875rem;
  font-weight: 500;
  color: #4F4F4F;
`

type CancelProps = {
  switchTab: (event: SyntheticEvent<Element, Event> | string) => void
  user?: User
}

const Cancel = ({ switchTab, user }: CancelProps) => {
  const [cancelSubscriptionMutation] = useMutation(CANCEL_SUBSCRIPTION)

  if (!user?.paymentSubscription) {
    return (
      <>
        <Title>Ошибка</Title>
        <Paragraph>Данные о подписке недоступны. Попробуйте еще раз или обратитесь в поддержку.</Paragraph>
      </>
    )
  }

  const { paymentSubscription } = user
  const { plan } = paymentSubscription
  const expireDate = format(parseJSON(paymentSubscription.nextPaymentDate), 'd.MM.yyyy', {locale: ru})

  function cancelSubscription() {
    cancelSubscriptionMutation()
      .then(() => switchTab('status'))
      .catch((error) => console.error(error.message))
  }

  function isTestSubscription() {
    return plan.id === '4' || plan.title.toLocaleLowerCase() === 'test'
  }

  return (
    <>
      <Row>
        <Col>
          <Title>Отменить подписку</Title>
          {isTestSubscription()
            ? (
              <>
                <Paragraph>Сейчас у Вас development подписка.</Paragraph>
                <Paragraph>Для отмены и смены типа подписки обратитесь к разработчикам</Paragraph>
              </>
            )
            : (
              <>
                <Paragraph>Текущая подписка будет активна до {expireDate}</Paragraph>
                <Paragraph>После отмены мы больше не будем автоматически списывать средства за подписку и удалим Ваши платежные данные из системы. Вы сможете пользоваться текущей подпиской до {expireDate}</Paragraph>
              </>
            )}
        </Col>
      </Row>
      {!isTestSubscription() && (
        <Row>
          <Col>
            <Button type='button' onClick={() => cancelSubscription()}>Отменить подписку</Button>
          </Col>
        </Row>
      )}
    </>
  )
}

export default Cancel
