import React, { useEffect } from 'react'
import ym from 'react-yandex-metrika'
import ReactGA from 'react-ga'
import ToS from '../../components/ToS/ToS'

const ToSPage = () => {
  useEffect(() => {
    ym('hit', '/')
    ReactGA.pageview('/')
  }, [])
  return <ToS />
}

export default ToSPage
