import styled from 'styled-components';

export const CalendarCellWrapper = styled.div<{isDropDisabled: boolean, isFirstDayOfMonth: boolean}>`
  display: flex;
  flex-direction: column;
  width: 12.5%;
  min-height: 340px;
  padding: 10px;
  background-color: #F8F8F8;
  border: 1px solid #ECE9E9;
  border-left: ${({isFirstDayOfMonth}) => isFirstDayOfMonth && '2px solid #BEBBBB'};
  user-select: none;
  background-image: ${({isDropDisabled}) => isDropDisabled && 'repeating-linear-gradient(-45deg, transparent 0%, transparent 40%, #FF9696 40%, #FF9696 50%, transparent 50%)'};
  background-size: ${({isDropDisabled}) => isDropDisabled && '.8rem .8rem'};
`

export const Title = styled.span<{isToday: boolean}>`
  display: flex;
  align-items: center;
  position: relative;
  background-color: ${({isToday}) => isToday && '#0e9612'};
  color: ${({isToday}) => isToday ? '#FFFFFF' : '#909090'};
  font-size: .75rem;
  padding: 4px 10px;
  margin-left: -11px;
  margin-bottom: 8px;
  max-width: 100px;
  height: 22px;
  &:after {
    content: ${({isToday}) => isToday && ''};
    position: ${({isToday}) => isToday && 'absolute'};
    top: ${({isToday}) => isToday && 0};
    right: ${({isToday}) => isToday && '-8px'};;
    border-bottom: ${({isToday}) => isToday && '11px solid #0e9612'};
    border-top: ${({isToday}) => isToday && '11px solid #0e9612'};
    border-right: ${({isToday}) => isToday && '8px solid transparent'};
  }
`
