import React, {memo} from 'react';

import {
  Plans,
  PlansContent,
  PlansOptions,
  PlansOptionsItem,
  PlansOptionsItemDescription,
  PlansOptionsItemPrice
} from './styled';
import {PlansProps} from './types';
import {TEXTS} from './texts';
import { getCurrencyFormatter } from 'src/utils/getCurrencyFormatter';

export const ActivatePlans: React.FC<PlansProps> = memo(function({ plans, changeCurrentPlan }) {


  return (
    <Plans>
      <PlansContent>
        <h4>{TEXTS.contentText}</h4>
        <PlansOptions role='group'>
          {plans?.map((plan,index) => {
            return (
              <PlansOptionsItem key={plan.id}>
                <input
                  type='radio'
                  id={plan.id}
                  name='subscriptionPlan'
                  value={plan.price.value}
                  defaultChecked={index === 0}
                  onClick={() => changeCurrentPlan(plan)}
                />
                <PlansOptionsItemPrice>{getCurrencyFormatter(plan.price.currency).format(plan.price.value)}</PlansOptionsItemPrice>
                <PlansOptionsItemDescription>
                  <p>{plan.title}</p>
                  <span>{`${plan.durationText}`}</span>
                </PlansOptionsItemDescription>
              </PlansOptionsItem>
            )
          })}
        </PlansOptions>
      </PlansContent>
    </Plans>
  )
})
