import styled from 'styled-components';
import Button from '../../../../UI/Button/Button';

export const PaymentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const PaymentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid #F2F2F2;
  & span {
    font-weight: 500;
    color: #4F4F4F;
  }
`

export const PaymentContent = styled.div`
  display: inherit;
  flex-direction: inherit;
  height: inherit;
`

export const PaymentSumAfter = styled.div`
  padding: 8px 0;
  font-size: .875rem;
  display: flex;
  justify-content: space-between;
`

export const PaymentPromocode = styled.div`
  padding: 10px 0;
`

export const StyledInputPromocodeWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledInputPromocodeError = styled.p`
  margin: 4px 0 0;
  color: #CC0000;
  font-size: .75rem;
`

export const ButtonWrapper = styled.div`
  padding-left: 12px;
`

export const PromocodeButton = styled(Button)<{activated?: boolean}>`
  padding: 0 20px;
  background: ${({activated}) => activated ? '#219653' : 'rgb(229, 229, 229)'};
  border: none;
  color: ${({activated}) => activated ? '#FFFFFF' : '#4F4F4F'};
  min-width: 115px;
  &:hover {
    cursor: ${({activated}) => activated ? 'default' : 'pointer'};
    background: ${({activated}) => activated ? '#219653' : 'rgb(204, 204, 204)'};
    color: ${({activated}) => activated ? '#FFFFFF' : '#4F4F4F'};
  }
  &:active {
    transform: ${({activated}) => activated ? 'none' : 'translateY(1px)'};
  }
  &:disabled {
    color: #4F4F4F;
    &:hover {
      cursor: default;
      background: rgb(229, 229, 229);
      color: #4F4F4F;
    }
  }
`

export const PaymentActionBtn = styled.div`
  margin-top: auto;
`

export const PaymentProviderLogo = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 0 0;
  & svg {
    width: 110px;
    height: 35px;
  }
`

export const ContactText = styled.p`
  font-size: .75rem;
  color: #909090;
  padding: 0 5px;
  line-height: 1.5;
`

export const StyledPrices = styled.div`
  display: flex;
  align-items: center;
`

export const StyledOldPrice = styled.p`
  font-size: .875rem;
  color: #909090;
  text-decoration: line-through;
  margin: 0 10px 0 0;
`
