import React, {memo} from 'react';
import {Form, Formik} from 'formik';
import { ReactComponent as YooKassaLogo } from '../../../../../assets/img/logo-black-yookassa.svg'

import PayButton from '../../../../UI/Subscription/Button';
import {
  ContactText,
  PaymentHeader,
  PaymentContent,
  PaymentPromocode,
  ButtonWrapper,
  PromocodeButton,
  PaymentActionBtn,
  PaymentWrapper,
  PaymentProviderLogo,
  StyledPrices,
  StyledOldPrice,
  StyledInputPromocodeWrapper,
  StyledInputPromocodeError,
  PaymentSumAfter,
} from './styled';
import {ActivatePaymentProps} from './types';
import {TEXTS} from './texts';
import {Input, Row} from '../../../../UI/Form';
import { getCurrencyFormatter } from 'src/utils/getCurrencyFormatter';

export const ActivatePayment: React.FC<ActivatePaymentProps> = memo(function(
  {
    oldPrice,
    priceToday,
    transaction,
    appliedPromocode,
    applyPromocode,
    currentPlan,
    handlePay,
    inputError,
    resetInputError
  }
) {

  function handleSubmit({promocode}: Record<'promocode', string>) {
    applyPromocode(promocode)
    resetInputError()
  }

  let nextPriceElement: JSX.Element | null = null;

  if (oldPrice) {
    nextPriceElement = (
      <PaymentSumAfter>
        <span>{`${TEXTS.afterText} ${currentPlan?.durationTextPeriod}`}</span>
        <StyledPrices>
          <span>{getCurrencyFormatter(currentPlan?.price.currency).format(oldPrice)}</span>
        </StyledPrices>
      </PaymentSumAfter>
    )
  }

  return (
    <PaymentWrapper>
      <PaymentHeader>
        <span>{TEXTS.headerText}</span>
        <StyledPrices>
          {oldPrice && <StyledOldPrice>{getCurrencyFormatter().format(oldPrice)}</StyledOldPrice>}
          <span>{priceToday}</span>
        </StyledPrices>
      </PaymentHeader>
      <PaymentContent>
        {nextPriceElement}
        <PaymentPromocode>
          <Formik
            onSubmit={handleSubmit}
            initialValues={{
              promocode: ''
            }}
          >
            {(props) => {
              function onChange(event: any) {
                props.handleChange(event)
                if (inputError) {
                  resetInputError()
                }
              }
              function isPromocodeActivated() {
                return (appliedPromocode === props.values.promocode)
              }
              return (
                <Form>
                  <Row>
                    <StyledInputPromocodeWrapper>
                      <Input
                        name='promocode'
                        placeholder={TEXTS.promocodePlaceholder}
                        formik={props}
                        onChange={onChange}
                        withError
                      />
                      {inputError && <StyledInputPromocodeError>{inputError}</StyledInputPromocodeError>}
                    </StyledInputPromocodeWrapper>
                    <ButtonWrapper>
                      <PromocodeButton type='submit' disabled={!props.values.promocode} activated={isPromocodeActivated()}>
                        {isPromocodeActivated() ? '✓' : TEXTS.promocodeApply}
                      </PromocodeButton>
                    </ButtonWrapper>
                  </Row>
                </Form>
              )
            }}
          </Formik>
        </PaymentPromocode>
        <PaymentActionBtn>
          <PayButton href={transaction?.redirect} onClick={handlePay} target='_blank' >
            {TEXTS.btnPayText}
          </PayButton>
          <PaymentProviderLogo>
            <YooKassaLogo />
          </PaymentProviderLogo>
          <ContactText>
            {TEXTS.contactText} <a href="mailto: support@trisystems.ru">support@trisystems.ru</a>
          </ContactText>
        </PaymentActionBtn>
      </PaymentContent>
    </PaymentWrapper>
  )
})
