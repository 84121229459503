import React from 'react';
import {useMutation} from '@apollo/client';
import {loader} from 'graphql.macro';

import format from 'date-fns/format';
import parseJSON from 'date-fns/parseJSON';
import ru from 'date-fns/locale/ru';

import {useSubscription} from '../../../../hooks/subscription/useSubscription';
import {Col, Paragraph, Row} from '../../../UI/Modal';
import {PaymentPlanSkeleton} from './skeleton';
import {PaymentPlanProps} from './types';
import {Title, SuccessButton} from './styled';
import { getCurrencyFormatter } from 'src/utils/getCurrencyFormatter';

const CHANGE_PAYMENT_PLAN = loader('../../../../apollo/mutations/payment/ChangePlan.graphql')

export const PaymentPlan = ({ switchTab, user }: PaymentPlanProps) => {
  const { plans } = useSubscription()
  const [changePlan] = useMutation(CHANGE_PAYMENT_PLAN)

  if (!user?.paymentSubscription || plans.length === 0) {
    return <PaymentPlanSkeleton />
  }

  const { paymentSubscription } = user
  const { plan } = paymentSubscription
  const alternativePlan = plans.find(({id}) => id !== plan.id)
  const expireDate = format(parseJSON(paymentSubscription.nextPaymentDate), 'd.MM.yyyy', {locale: ru})
  const buttonText = `Изменить подписку на ${getCurrencyFormatter(alternativePlan?.price.currency).format(alternativePlan?.price.value || 0)} ${alternativePlan?.durationText}`

  function changePaymentPlan(planId: string) {
    changePlan({
      variables: {
        planId
      }
    })
      .then(() => switchTab('status'))
      .catch((error) => console.error(error.message))
  }

  function isTestSubscription() {
    return plan.id === '4' || plan.title.toLocaleLowerCase() === 'test'
  }


  return (
    <>
      <Row>
        <Col>
          <Title>Выбор плана подписки</Title>
          {isTestSubscription()
            ? (
              <>
                <Paragraph>Сейчас у Вас development подписка</Paragraph>
                <Paragraph>Для изменения типа подписки, обратитесь к разработчикам</Paragraph>
              </>
            )
            : (
              <>
                <Paragraph>Ваша текущая подписка {plan.title} ({getCurrencyFormatter(plan.price.currency).format(plan.price.value)} {plan.durationText}) оплачена до {expireDate}</Paragraph>
                <Paragraph>После смены плана подписки ближайшее списание: {expireDate}</Paragraph>
              </>
            )}
        </Col>
      </Row>
      {!isTestSubscription() && alternativePlan && (
        <Row>
          <Col>
            <SuccessButton type='button' onClick={() => changePaymentPlan(alternativePlan.id)}>{buttonText}</SuccessButton>
          </Col>
        </Row>
      )}
    </>
  )
}
