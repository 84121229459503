import React, { useState } from 'react'
import Button from '../../UI/Button/Button'
import styled from 'styled-components'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import ru from 'date-fns/locale/ru'
import { Col, Paragraph, Row, Title } from '../../UI/Form'
import { gql, useApolloClient, useMutation } from '@apollo/client'
import { userCurrentProgram, userEvents } from '../../../apollo/cache'
import { loader } from 'graphql.macro'
import Switch from "react-switch"
import PlanPreview from '../PlanPreview/PlanPreview'
import Loader from 'react-loader-spinner'
import ym from 'react-yandex-metrika'
import ymGoals from '../../../analytics/ymGoals'
import { usePlanBuilder } from '../../../services/PlanBuilder'
import { PlanBuilderStepProps } from './types'

const PLAN_PREVIEW = loader('../../../apollo/mutations/PlanPreview.graphql')

const Buttons = styled.div`
  margin-top: 25px;
  display: flex;
  & button {
    margin-left: 25px;
    &:first-child {
      margin: 0;
    }
  }
`

const ActivateButton = styled(Button)`
  background: #219653;
  border: none;
  &:hover {
    background: #1d8045;
  }
  &:disabled {
    background-color: #219653 !important;
  }
`

const BackButton = styled(Button)`
  border: none;
  color: #2F80ED;
  &:hover {
    background: none;
    border: none;
    color: #2A75DB;
    text-decoration: underline;
  }
`

const ProgramTitle = styled.span`
  font-weight: 500;
  display: inline-block;
`

const ProgramRange = styled.span`
  margin-left: 5px;
  display: inline-block;
  position: relative;
  padding-left: 8px;
  &:before {
    content: '·';
    position: absolute;
    left: 0;
  }
`

const ShowAllWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & span {
    display: inline-block;
    font-weight: 500;
    font-size: .75rem;
    margin-right: 15px;
  }
`

const Step4 = ({ title, tabSwitcher, closeHandler }: PlanBuilderStepProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [showAllWorkouts, setShowAllWorkouts] = useState(false)
  const { store } = usePlanBuilder()

  const schedule = store.value.schedule
  const preview = store.value.preview

  const [planPreview] = useMutation(PLAN_PREVIEW)

  const client = useApolloClient()

  const user = client.readFragment({
    id: 'User:{}',
    fragment: gql`
        fragment IsCoach on User {
            isCoach
        }
    `
  })

  const isAdmin = user && user.isCoach

  if (!preview) {
    return null
  }

  const program = {
    title: preview.programs[0].title,
    startDate: preview.programs[0].startDate,
    endDate: preview.programs[0].endDate
  }
  const periods = preview.programs[0].plans
  const workouts = preview.workouts
  const events = preview.events

  function activateProgram() {
    ym('reachGoal', ymGoals.planbuilder.activateProgramBtnClick)
    setIsLoading(true)
    const cleanedSchedule = schedule?.map(({ date, sport, order, intensity}) => ({
      date,
      sportId: sport.id,
      order,
      workoutIntensityId: intensity.id
    }))
    const input = {
      trainingStartDate: store.value.trainingStartDate,
      events: store.value.events,
      programm: store.value.programm,
      includeTesting: store.value.includeTesting || false,
      schedule: cleanedSchedule,
    }

    planPreview({
      variables: {
        activatePlan: true,
        input
      }
    })
      .then((result) => {
        const { programms, events } = result.data.planPreview.calendar
        userCurrentProgram(programms[0].title)
        userEvents(events)
        client.resetStore().then(() => {
          setIsLoading(false)
          store.update({})
          closeHandler!()
        })
      })
      .catch((error) => {
        setIsLoading(false)
        console.error(error.message)
      })
  }

  return (
    <div>
      <Title style={{paddingBottom: 10}}>{title}</Title>
      <Row>
        <Col>
          <Paragraph>
            <ProgramTitle>{program.title}</ProgramTitle>
            <ProgramRange>
              {`
                ${format(parseISO(program.startDate), 'd MMM yyyy', {locale: ru})}
                –
                ${format(parseISO(program.endDate), 'd MMM yyyy', {locale: ru})}
              `}
            </ProgramRange>
          </Paragraph>
        </Col>
        {isAdmin && (
          <Col>
            <ShowAllWrapper>
              <span>Показать все тренировки: </span>
              <Switch
                onChange={() => setShowAllWorkouts(!showAllWorkouts)}
                checked={showAllWorkouts}
                width={44}
                height={22}
              />
            </ShowAllWrapper>
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          <PlanPreview
            periods={periods}
            workouts={workouts}
            events={events}
            showAllWorkouts={showAllWorkouts}
          />
        </Col>
      </Row>
      <Buttons>
        <ActivateButton type='button' onClick={activateProgram} disabled={isLoading} solid>
          {isLoading ? <Loader type='ThreeDots' color='#FFFFFF' height={10}/> : 'Активировать программу'}
        </ActivateButton>
        <BackButton type='button' onClick={() => tabSwitcher('3')} disabled={isLoading}>Вернуться назад</BackButton>
      </Buttons>
    </div>
  )
}

export default Step4
