import React from 'react'
import styles from './ResetForm.module.css'
import { Form, Formik, FormikValues } from 'formik'
import Button from '../../UI/Button/Button'
import { Link, useHistory } from 'react-router-dom'
import { Wrapper, Input } from '../../UI/Form'
import SmallLoader from '../../UI/SmallLoader/SmallLoader'
import ResetSchema from './validation'
import { loader } from 'graphql.macro'
import { useMutation } from '@apollo/client'
import { routes } from '../../../routeConfig'

const SEND_CODE = loader('../../../apollo/mutations/SendCode.graphql')

const ResetForm = () => {
  const history = useHistory()
  const [sendCode] = useMutation(SEND_CODE)

  function formSubmit(values: FormikValues, { setSubmitting, setFieldError }: any):void {
    const { email } = values
    setSubmitting(true)
    sendCode({
      variables: {
        email,
        action:'reset_password'
      }
    })
      .then((result) => {
        setSubmitting(false)
        if (result.data.sendCode.ok) {
          history.push(`/code`)
        } else {
          setFieldError('email', 'Что-то пошло не так. Обратитесь в поддержку.')
        }
      })
      .catch((error) => {
        setSubmitting(false)
        console.error(error.message)
        if (error.message === 'rate_limited') {
          setFieldError('email', 'Превышено количество запросов. Попробуйте повторить позднее.')
        }
        if (error.message === 'too_many_requests') {
          setFieldError('email', 'Большое количество попыток на сброс. Попробуйте через 30 минут.')
        }
        if (error.message === 'incorrect_email') {
          setFieldError('email', 'Такой email не зарегистрирован в системе')
        }
      })
  }

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      onSubmit={formSubmit}
      validationSchema={ResetSchema}
    >
      {(props) => (
        <Wrapper>
          <Form>
            <h2 className={styles.title}>Восстановление пароля</h2>
            <div className={styles.row}>
              <div className={styles.col}>
                <Input name='email' placeholder='Адрес электронной почты' formik={props} withError/>
              </div>
            </div>
            <p className={styles.text}>
              На указанный адрес будет отправлен код для восстановления пароля.
            </p>
            <Button
              className={styles.submit}
              type='submit'
              solid
              disabled={props.isSubmitting}
            >
              {props.isSubmitting ? <SmallLoader /> : 'Сбросить пароль'}
            </Button>
            <div className={styles.underline}>
              <Link
                to={routes.signIn}
                className={styles['underline__link']}
              >
                Войти
              </Link>
            </div>
          </Form>
        </Wrapper>
      )}
    </Formik>
  )
}

export default ResetForm
