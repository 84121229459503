import React from 'react'
import styled from 'styled-components'
import { gql, useApolloClient } from '@apollo/client'

const PaywallTextWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 35px;
  z-index: 90;
  font-size: .75rem;
  font-weight: 500;
`

const PaywallText = () => {
  const client = useApolloClient()

  const user = client.readFragment({
    id: 'User:{}',
    fragment: gql`
        fragment CurrentUser on User {
            firstName
            lastName
            email
            paymentSubscription {
                status
            }
        }
    `
  })

  if (user?.paymentSubscription?.status === 'ENDED') {
    return null
  }

  return (
    <PaywallTextWrapper>Сейчас мы отображаем ближайшие две недели программы. Эти тренировки будут сгенерированы на следующей неделе.</PaywallTextWrapper>
  )
}

export default PaywallText
