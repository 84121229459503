import styled from 'styled-components';
import Button from '../../../UI/Button/Button';

export const Title = styled.h2`
  font-size: .875rem;
  font-weight: 500;
  color: #4F4F4F;
`

export const SuccessButton = styled(Button)`
  background: #219653;
  color: #FFFFFF;
  border: none;
  &:hover {
    background: #1d8045;
  }
  &:disabled {
    background-color: #219653 !important;
  }
`
