import React from 'react'
import styles from './CustomTooltip.module.css'
import { durationToString } from '../../../utils/time'

const CustomTooltip = (props:any) => {
  const {payload, label} = props
  if (payload && payload.length !== 0) {
    return (
      <div className={styles.tooltip}>
        {payload[0].payload.power !== undefined && <p className={styles.label}><span>Мощность:</span> {payload[0].payload.power} Вт</p>}
        {payload[0].payload.pace !== undefined && <p className={styles.label}><span>Темп:</span> {durationToString(1000 * (1 / payload[0].payload.pace), 3)} мин/км</p>}
        {payload[0].payload.swim !== undefined && <p className={styles.label}><span>Темп:</span> {durationToString(1000 * (1 / payload[0].payload.swim), 3)} мин/100м</p>}
        {payload[0].payload.hr !== undefined && <p className={styles.label}><span>Пульс:</span> {payload[0].payload.hr} уд/мин</p>}
        {payload[0].payload.rpe !== undefined && <p className={styles.label}><span>RPE:</span> {payload[0].payload.rpe} из 10</p>}
        <p className={styles.label}>Время: {durationToString(label)}</p>
      </div>
    )
  }
  return null
}

export default CustomTooltip