import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import PlanBuilderWorkout from '../../components/PlanBuilder/PlanBuilderWorkout/PlanBuilderWorkout'
import styled from 'styled-components'

type PlanBuilderWorkoutDraggableProps = {
  index: number
  id: string
  title?: string
  sportId?: string,
  slug?: string
  plot: Array<number[]>
}

const DraggrableWorkout = styled.div`
  cursor: grab;
  &:hover > div, &:active > div{
    background-color: #f5f5f5;
  }
`

const PlanBuilderWorkoutDraggable = ({ id, index, ...rest }: PlanBuilderWorkoutDraggableProps) => {
  return (
    <Draggable
      draggableId={id}
      index={index}
    >
      {(provided) => (
        <DraggrableWorkout
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <PlanBuilderWorkout {...rest} isDnD={true}/>
        </DraggrableWorkout>
      )}
    </Draggable>
  )
}

export default PlanBuilderWorkoutDraggable
