import React, {memo, useState} from 'react';
import ReactTooltip from 'react-tooltip'

import {Wrapper, DeleteButton} from './styled';
import {CloseOptions, DeleteEventProps} from './types';
import {DeleteEventModal} from './modal';

export const DeleteEvent:React.FC<DeleteEventProps> = memo(function DeleteEvent({active, eventId, closeEventModal}) {
  const [isOpenModal, setOpenModal] = useState(false)

  function handleExportButtonClick() {
    setOpenModal(true);
  }

  function handleClose(options?: CloseOptions) {
    if (options?.quite) {
      closeEventModal()
      return
    }
    setOpenModal(false)
  }

  return (
    <Wrapper>
      <span
        data-tip
        data-for='delete-button'
        data-tip-disable={false}
      >
        <DeleteButton
          type='button'
          onClick={handleExportButtonClick}
          disabled={!active}
        />
      </span>
      <ReactTooltip id='delete-button' place='top' effect='solid'>
        <p style={{textAlign: 'center'}}>
          {active
            ? 'Удалить событие'
            : 'Удаление основных событий сейчас недоступно - воспользуйтесь конструктором плана.'
          }
        </p>
      </ReactTooltip>
      {isOpenModal && (
        <DeleteEventModal
          isOpen={isOpenModal}
          closeHandler={handleClose}
          eventId={eventId}
        />
      )}
    </Wrapper>
  )
})
