import React from 'react'
import styles from './Logo.module.css'
import { ReactComponent as LogoImg } from '../../assets/img/logo-light.svg'

type LogoProps = {
  className?: string
}

const Logo = ({ className }: LogoProps) => {
  const cls = [styles.logo]

  if (className) {
    cls.push(className)
  }

  function getLogoAddon() {
    return process.env.REACT_APP_LOGO_ADDON
  }

  const addon = getLogoAddon()

  return (
    <div className={cls.join(' ')}>
      <LogoImg />
      {addon && <sup className={styles.addon}>{addon}</sup>}
    </div>
  )
}

export default Logo
