import React, {memo, useState} from 'react';
import {
  EventWrapper,
  Header,
  HeaderLeft,
  ActionButtons,
  Title,
  TopStyled,
  ContentStyled
} from './styled';

import {EventProps} from './types';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import ru from 'date-fns/locale/ru';
import {DeleteEvent} from './delete-event';
import {EditEventButton} from './edit-event-button';
import {AddEvent} from './add-event';
import {EditEvent} from './edit-event';
import {EventDescription} from './event-description';
import {EventNotes} from './event-notes';

export const Event: React.FC<EventProps> = memo(function Event({event, setCurrentEvent, date, closeEventModal}) {
  const [edit, setEdit] = useState(false);

  function handleEditClick() {
    setEdit(!edit)
  }

  function handleCloseEdit() {
    setEdit(false)
  }

  if (!event) {
    return (
      <EventWrapper>
        <AddEvent setCurrentEvent={setCurrentEvent} date={date!}/>
      </EventWrapper>
    )
  }

  if (edit) {
    return (
      <EventWrapper>
        <EditEvent setCurrentEvent={setCurrentEvent} event={event} onClose={handleCloseEdit} />
      </EventWrapper>
    )
  }

  return (
    <EventWrapper>
      <Header>
        <HeaderLeft>
          <TopStyled>
            <span>{(event.programm as unknown as TrainingProgram)?.title || 'Событие'} </span>
            <span>{`— ${format(parseISO(event.date), 'dd MMMM yyyy', {locale: ru})}`}</span>
          </TopStyled>
          <Title>{event.title}</Title>
        </HeaderLeft>
        <ActionButtons>
          <EditEventButton handleClick={handleEditClick}/>
          <DeleteEvent
            active={event.priority !== 'A'}
            eventId={event.id!}
            closeEventModal={closeEventModal}
          />
        </ActionButtons>
      </Header>
      <ContentStyled>
        <EventDescription description={event.description} />
        <EventNotes notes={event.notes} />
      </ContentStyled>
    </EventWrapper>
  )
})
