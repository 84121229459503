import React from 'react'
import styled from 'styled-components'
import Button from '../../UI/Button/Button'

type TestChooserProps = {
  alternatives: Array<WorkoutAlternative>
  handleClick: (id: number) => void
  onClose: () => void
}

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(86, 86, 86, 0.8);
  z-index: 100;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 130px;
`

const Title = styled.h2`
  color: #FFFFFF;
  font-size: 1rem;
`

const List = styled.ul`
  max-width: 500px;
  margin-top: 15px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 20px;
`

const ButtonItem = styled(Button)`
  border: none;
  &:hover {
    background-color: #e6e4e4;
    color: #000000;
  }
`

const TestChooser = ({ alternatives, handleClick, onClose }: TestChooserProps) => {
  return (
    <Overlay>
      <Wrapper>
        <Title>Как вы планируете выполнить тренировку?</Title>
        <List>
          {alternatives
            .map((alternative) => {
              if (!alternative.workout) {
                return <ButtonItem key={alternative.title} onClick={onClose}>{alternative.title}</ButtonItem>
              }
              return <ButtonItem key={alternative.title} onClick={() => handleClick(alternative.workout.id)}>{alternative.title}</ButtonItem>
            }

          ) }
        </List>
      </Wrapper>
    </Overlay>
  )
}

export default TestChooser
