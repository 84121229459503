import React, { useEffect } from 'react'
import { gql, useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { useSession } from '../hooks/auth/useSession'
import { routes } from '../routeConfig'

const LoginAsMutation = gql`
  mutation LoginAs($email: String!) {
      loginAs(email: $email) {
          token
          refreshToken
      }
  }
`

const ReloginContainer = () => {
  const history = useHistory()
  const session = useSession()
  const urlParams = new URLSearchParams(history.location.search)
  const email = urlParams.get('email')

  const [loginAs] = useMutation(LoginAsMutation, {
    variables: {
      email: email
    }
  })

  function relogin() {
    loginAs()
      .then((result) => {
        const token = result.data.loginAs.token
        const refreshToken = result.data.loginAs.refreshToken
        session.login(token, refreshToken)
        history.push(routes.calendar)
      })
      .catch((error) => console.error(error.message))
  }

  useEffect(relogin, [loginAs, history, session])

  return (
    <>
    </>
  )
}

export default ReloginContainer
