import React, { useState } from 'react'
import Step1 from './steps/_1'
import Step2 from './steps/_2'

const SurveyForm = () => {
  const [tab, setTab] = useState(1)

  function pageHandler(addStepNum: number) {
    setTab(tab + addStepNum)
  }

  return tab === 1
    ? <Step1 handler={pageHandler} />
    : <Step2 handler={pageHandler} />
}

export default SurveyForm
