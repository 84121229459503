import startOfWeek from 'date-fns/startOfWeek';
import isSameDay from 'date-fns/isSameDay';
import addWeeks from 'date-fns/addWeeks';

import {IsFirstPaywallWeekOptions} from './types';

export function isFirstPaywallWeek(options: IsFirstPaywallWeekOptions): boolean {
  const {startDate, weekWorkouts = []} = options
  if (weekWorkouts.length !== 0 && weekWorkouts[0].isPaywall) {
    const startOfCurrentWeek = startOfWeek(new Date(), {weekStartsOn: 1})
    return isSameDay(addWeeks(startOfCurrentWeek, 3), startDate)
  }
  return false
}
