import React from 'react'
import styled from 'styled-components'
import AppHeader from '../components/AppHeader/AppHeader'
import CalendarTop from '../components/CalendarTop/CalendarTop'
import Reminder from '../components/Reminder/Reminder'

const Header = styled.header`
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 100;
`

const AppHeaderContainer = () => {
  return (
    <Header>
      <Reminder/>
      <AppHeader/>
      <CalendarTop/>
    </Header>
  )
}

export default AppHeaderContainer
