import React from 'react'
import { Route, Redirect, useLocation } from 'react-router-dom'
import { useSession } from '../../hooks/auth/useSession'
import { routes } from '../../routeConfig'

type PrivateProps = {
  children: JSX.Element
  path: string
  exact: boolean
  location?: any
}

const Private:React.FC<PrivateProps> = ({ children, path, exact, location }) => {
  const session = useSession()
  const replaceLocation = useLocation()

  return session.isValid()
    ? <Route path={path} exact={exact} location={location}>{children}</Route>
    : <Redirect to={{
        pathname: routes.signIn,
        state: { from: replaceLocation }
      }}/>
}

export default Private
