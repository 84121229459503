import { InMemoryCache, makeVar } from '@apollo/client'
import { getCalendarEndDate, getCalendarStartDate } from '../utils/calendar'
import format from "date-fns/format"

export const calendarStartDate = makeVar<Date>(getCalendarStartDate())
export const calendarEndDate = makeVar<Date>(getCalendarEndDate())
export const calendarCurrentMonth = makeVar<string>(format(new Date(), 'yyyy/MM'))

export const userCurrentProgram = makeVar<string | null>(null)
export const userEvents = makeVar<Array<UserEvent>>([])
export const userProgramms = makeVar<Array<UserProgramm>>([]);

export const isAfterSurvey = makeVar<boolean>(false)

export const cache = new InMemoryCache({
  typePolicies: {
    WorkoutStepTarget: {
      keyFields: false
    },
    User: {
      keyFields: []
    },
    Zone: {
      keyFields: ['sportId', 'targetId']
    },
    Query: {
      fields: {
        calendarStartDate: {
          read() {
            return calendarStartDate()
          }
        },
        calendarEndDate: {
          read() {
            return calendarEndDate()
          }
        }
      }
    }
  }
})
