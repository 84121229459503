import React, { useEffect, useState } from 'react'
import { Col, Paragraph, Row } from '../../UI/Modal'
import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'
import { useSubscription } from '../../../hooks/subscription/useSubscription'
import Button from '../../UI/Subscription/Button'
import StatusTransaction from './StatusTransaction'

const PAYMENT_COST = 5

type PaymentDataProps = {
  user?: User
}

const Title = styled.h2`
  font-size: .875rem;
  font-weight: 500;
  color: #4F4F4F;
`

const PaymentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`

const ContactText = styled.p`
  font-size: .75rem;
  color: #909090;
  text-align: center;
  padding: 20px 40px;
  line-height: 1.4;
  
`

const PaymentData = ({ user }: PaymentDataProps) => {
  const { plans, paymentData } = useSubscription()
  const [tid, setTid] = useState<string>()
  const [planTransaction, setPlanTransaction] = useState<PaymentData>()

  useEffect(() => {
    createTransaction()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function createTransaction() {
    paymentData.change()
      .then((result) => {
        const transaction = result.data.paymentChangePaymentMethod.paymentData
        setPlanTransaction(transaction)
      })
      .catch((error) => console.error(error.message))
  }

  function handlePay() {
    setTid(planTransaction?.transactionId)
  }

  if (!user?.paymentMethod || plans.length === 0) {
    return <PaymentDataSkeleton />
  }

  if (tid) {
    return <StatusTransaction id={tid} resetTransaction={resetTransaction}/>
  }

  function resetTransaction() {
    setTid(undefined)
  }

  const { paymentMethod } = user
  const cardLast4Digits = paymentMethod.cardNumber.slice(-4)
  return (
    <>
      <Row>
        <Col>
          <Title>Изменить способ оплаты</Title>
          <Paragraph>Сейчас у нас есть информация о карте {paymentMethod.cardType} заканчивающейся на <strong>{cardLast4Digits}</strong>.</Paragraph>
          <Paragraph>Чтобы изменить способ оплаты необходимо проверить, что новая карта является действующей, и ее владельцем являетесь вы. Для подтверждения карты с ее счета будет списана сумма в {PAYMENT_COST} рублей.</Paragraph>
          <Paragraph>После подтверждения карты возмещение указанной суммы будет зачислено на вашу карту в течении 2-5 рабочих дней и иногда до 30 дней, в зависимости от компании-эмитента карты.</Paragraph>
        </Col>
        <Col>
          <PaymentWrapper>
            <Button href={planTransaction?.redirect} onClick={handlePay} target='_blank' loading={paymentData.loading}>
              {`Заплатить ${PAYMENT_COST} рублей`}
            </Button>
            <ContactText>
              При возникновении вопросов или проблем с оплатой свяжитесь с нами по <a href="mailto: support@tri.systems">support@tri.systems</a>
            </ContactText>
          </PaymentWrapper>
        </Col>
      </Row>
    </>
  )
}

export default PaymentData

const PaymentDataSkeleton = () => {
  return (
    <>
      <Row>
        <Col>
          <Row>
            <Skeleton height={50} />
          </Row>
          <Row>
            <Skeleton height={50} />
          </Row>
          <Row>
            <Skeleton height={50} />
          </Row>
        </Col>
        <Col>
          <Row>
            <Skeleton height={200} />
          </Row>
        </Col>
      </Row>
    </>
  )
}
