import React from 'react'
import { PlanBuilderStore } from '../types/store'
import { getPlanBuilderContext } from './PlanBuilderContext'

export interface PlanBuilderProviderProps {
  store: PlanBuilderStore
  children: React.ReactNode | React.ReactNode[] | null
}

export function PlanBuilderProvider({ store, children }: PlanBuilderProviderProps) {
  const PlanBuilderContext = getPlanBuilderContext()
  return (
    <PlanBuilderContext.Consumer>
      {(context) => {
        if (store && context.store !== store) {
          context = Object.assign({}, context, { store })
        }
        return (
          <PlanBuilderContext.Provider value={context}>
            {children}
          </PlanBuilderContext.Provider>
        )
      }}
    </PlanBuilderContext.Consumer>
  )
}
