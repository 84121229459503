import React, {memo} from 'react';
import Skeleton from 'react-loading-skeleton';
import {Col, Row} from '../../../../UI/Modal';

export const ActivateSkeleton = memo(function() {
  return (
    <Row>
      <Col>
        <Row>
          <Skeleton height={50} />
        </Row>
        <Row>
          <Skeleton height={50} />
        </Row>
        <Row>
          <Skeleton height={50} />
        </Row>
      </Col>
      <Col>
        <Row>
          <Skeleton height={200} />
        </Row>
      </Col>
    </Row>
  )
})
