import React from 'react'
import styled from 'styled-components'
import { Formik } from 'formik'
import { Col, Paragraph, RadioTabGroup, Row, Title } from '../../UI/Form'
import Button from '../../UI/Button/Button'
import { durationToString, getDurationInSeconds } from '../../../utils/time'
import { useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'

const UPDATE_ZONE = loader('../../../apollo/mutations/UpdateZone.graphql')

type SubmitModalProps = {
  closeHandler: () => void
  previewData?: Array<ZonePreview>
}

type SubmitModalValues = {
  [key: string]: string
}


const SubmitModalWrapper = styled.div`
  min-width: 300px;
  background-color: #FFFFFF;
  padding: 28px 30px 20px;
`

const LocalRow = styled(Row)`
  padding: 0;
  & > div > div > div > label {
    width: 200px;
    height: 39px;
    text-align: center;
    white-space: nowrap;
  }
`

const LocalTitle = styled(Title)`
  padding: 15px 0 10px;
`

const SubmitButton = styled(Button)`
  white-space: nowrap;
`

const BackButton = styled(Button)`
  border: none;
  color: #2F80ED;
  &:hover {
    background: none;
    border: none;
    color: #2A75DB;
    text-decoration: underline;
  }
`

const SubmitModal = ({ closeHandler, previewData }: SubmitModalProps) => {
  const [updateZone] = useMutation(UPDATE_ZONE)

  if (!previewData) {
    return null
  }

  const mappedBySport = mapBySport(previewData)

  function mapBySport(preview: Array<ZonePreview>): {[key: string]: Array<{target: Target, newThreshold: number, oldThreshold: number}>} {
    return preview.reduce((acc: {[key: string]: Array<{target: Target, newThreshold: number, oldThreshold: number}>}, {sport, newThreshold, oldThreshold, target}) => {
      if (newThreshold !== 0) {
        const key = `${sport.title}:${sport.id}`
        if (!acc[key]) {
          acc[key] = []
        }
        acc[key].push({
          target,
          newThreshold,
          oldThreshold
        })
      }
      return acc
    }, {})
  }

  function getInitialValues(preview: Array<ZonePreview>) {
    return preview.reduce((acc: {[key: string]: string}, {sport, target, newThreshold}) => {
      const key = `${sport.id}:${target.id}`
      if (newThreshold !== 0) {
        if (target.isPace) {
          acc[key] = `${durationToString(newThreshold, 3)}`
        } else {
          acc[key] = `${newThreshold}`
        }
      }
      return acc
    }, {})
  }

  function getThresholdValue(arg: number, target: Target) {
    return target.isPace ? durationToString(arg, 3) : arg
  }

  async function formSubmit(values: SubmitModalValues, { setSubmitting }: any) {
    setSubmitting(true)
    const filterEmptyValues = Object.keys(values).filter((key) => values[key] !== '')

    if (filterEmptyValues.length === 0) {
      setSubmitting(false)
      closeHandler()
    }

    const mappedValues = filterEmptyValues.map((key) => {
      const [sportId, targetId] = key.split(':')
      const threshold = targetId === '3' || targetId === '5' ? getDurationInSeconds(values[key]) : Number(values[key])
      return {
        sportId,
        targetId,
        threshold,
        isTest: true
      }
    })

    const updater = new Promise(async function(resolve, reject) {
      mappedValues.forEach((zone) => {
        updateZone({
          variables: {
            zone
          }
        }).catch((error) => reject(error))
      })
      resolve('')
    })

    try {
      await updater
      closeHandler()
    } catch (error) {
      console.error(error.message)
    }
  }

  return (
    <Formik
      initialValues={getInitialValues(previewData)}
      onSubmit={formSubmit}
      enableReinitialize={true}
    >
      {(props) => {
        return (
          <SubmitModalWrapper>
            <Title>Новые пороговые значения</Title>
            <Paragraph>Функциональные параметры атлета (FTP, ПАНО, АнП).</Paragraph>
            {Object.keys(mappedBySport).map((key) => {
              const [sportTitle, sportId] = key.split(':')
              const targets = mappedBySport[key]
              return (
                <div key={key}>
                  <LocalTitle>{sportTitle}</LocalTitle>
                  {targets.map((target) => {
                    const inputName = `${sportId}:${target.target.id}`
                    const newThresholdValue = getThresholdValue(target.newThreshold, target.target)
                    const oldThresholdValue = getThresholdValue(target.oldThreshold, target.target)
                    function getRadioOptions() {
                      const newValue = {
                        id: `${inputName}-1`,
                        value: `${newThresholdValue}`,
                        labelValue: `Применить ${newThresholdValue} ${target.target.units}`
                      }
                      const oldValue = {
                        id: `${inputName}-2`,
                        value: '',
                        labelValue: `Оставить ${oldThresholdValue} ${target.target.units}`
                      }
                      return [newValue, oldValue]
                    }
                    return (
                      <LocalRow key={target.target.id}>
                        <RadioTabGroup
                          name={inputName}
                          label={target.target.title}
                          values={getRadioOptions()}
                          withError
                        />
                      </LocalRow>
                    )
                  })}
                </div>
              )
            })}
            <Row>
              <Col>
                <Paragraph>После применения зон данный тест будет недоступен для повторения.</Paragraph>
              </Col>
            </Row>
            <Row>
              <Col>
                <SubmitButton type='submit' onClick={props.handleSubmit} solid>Применить значения</SubmitButton>
              </Col>
              <Col>
                <BackButton type='button' onClick={closeHandler}>Вернуться назад</BackButton>
              </Col>
            </Row>
          </SubmitModalWrapper>
        )
      } }
    </Formik>
  )
}

export default SubmitModal
