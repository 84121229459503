import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const ReminderNoticeStyled = styled.p`
  padding: 8px 0 7px;
  margin: 0;
  font-size: .75rem;
  color: #FFFFFF;
`

const Step2 = () => {
  return (
    <Wrapper>
      <ReminderNoticeStyled>Мы отправили письмо с указаниями на вашу почту. Проверьте почтовый ящик.</ReminderNoticeStyled>
    </Wrapper>
  )
}

export default Step2