import styled from 'styled-components';
import calendar from '../../assets/img/icons/calendar.png';

export const Wrapper = styled.div<{
  cursorStyle: 'pointer' | 'grab' | 'default'
}>`
  cursor: ${({cursorStyle}) => cursorStyle};
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 120px;
  background-color: #FFFFFF;
  padding: 8px 9px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  margin-bottom: 10px;
  &:first-child {
    margin-top: 0;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Title = styled.h2`
  font-size: .75rem;
  font-weight: 500;
  line-height: 1.3;
  margin: 0;
  padding: 4px 0 0 22px;
  word-wrap: break-word;
  background-repeat: no-repeat;
  background-position: center left;
  background-image: url(${calendar});
`

export const Content = styled.p`
  font-size: .75rem;
  font-weight: bold;
  margin: 0;
  padding: 10px 0 0;
`
