import React, { useEffect } from 'react'
import SignUpForm from '../../components/Forms/SignUp/SignUpForm'
import MainLayout from '../../components/Layouts/Main/MainLayout'
import ym from 'react-yandex-metrika'
import ReactGA from 'react-ga'

const SignUpPage = () => {
  useEffect(() => {
    ym('hit', '/register')
    ReactGA.pageview('/register')
  }, [])
  return (
    <MainLayout>
      <SignUpForm />
    </MainLayout>
  )
}

export default SignUpPage
