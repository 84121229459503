import React from 'react'
import styled from 'styled-components'
import CalendarWorkoutChart from '../../Rechart/CalendarWorkoutChart/CalendarWorkoutChart'
import swim from '../../../assets/img/icons/swim-sport.png'
import bike from '../../../assets/img/icons/bike-sport.png'
import run from '../../../assets/img/icons/run-sport.png'
import gym from '../../../assets/img/icons/gym-sport.png'
import event from '../../../assets/img/icons/calendar.png'

type PlanBuilderWorkoutProps = {
  sportId?: string,
  plot?: Array<number[]>
  title?: string
  content?: string
  slug?: string
  eventTitle?: string
  eventContent?: string
  isDnD?: boolean
}

const Workout = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 128px;
  min-height: 80px;
  background-color: #FFFFFF;
  padding: 8px 9px;
  margin: 6px 0;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  &:first-child {
    margin-top: 0;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

const Title = styled.span`
  font-size: .6250rem;
  font-weight: 500;
`

const Content = styled.p`
  margin: 0;
  padding: 4px 0 0;
  font-weight: bold;
  font-size: .6250rem;
`

const Icon = styled.span<{slug: string}>`
  background-image: ${({slug}) => {
    const icon = function() {
      switch (slug) {
        case 'swim': {
          return swim
        }
        case 'bike': {
          return bike
        }
        case 'run': {
          return run
        }
        case 'gym': {
          return gym
        }
        case 'event': {
          return event
        }
      }
    }
    return `url(${icon()})`
}};
  background-repeat: no-repeat;
  background-position: center center;
  width: 18px;
  height: 18px;
`

const Chart = styled.div`
  height: 25px;
  margin: auto -8px -10px;
`

const PlanBuilderWorkout = ({ title, content, slug, plot, isDnD = false }: PlanBuilderWorkoutProps) => {
  return (
    <Workout>
      <Header>
        <Title>{title}</Title>
        {slug && <Icon slug={slug}/>}
      </Header>
      {content && <Content>{content}</Content>}
      {plot && (
        <Chart>
          <CalendarWorkoutChart chartData={plot} cursorStyle={isDnD ? 'grab' : 'default'}/>
        </Chart>
      )}
    </Workout>
  )
}

export default PlanBuilderWorkout
