/**
 *
 * Markdown
 *
 */

import { memo } from 'react';
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'

const remarkPlugins = [gfm]

const StyledMarkdown = styled(ReactMarkdown)`
  font-size: .875rem;
  line-height: 1.4;
  color: #4F4F4F;
  p {
    margin: 0;
    padding: 7px 0;
  }
`

interface MarkdownProps {
    content?: string | null | undefined;
}

export const Markdown = memo(
  ({ content }: MarkdownProps): JSX.Element | null => {
      if (!content) {
          return null;
      }
    return (
        <StyledMarkdown remarkPlugins={remarkPlugins}>{content}</StyledMarkdown>
    );
  },
);
