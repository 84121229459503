import React, { useEffect, useState } from 'react'
import MainLayout from '../../components/Layouts/Main/MainLayout'
import SurveyForm from '../../components/Forms/Survey/SurveyForm'
import { Redirect } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import { routes } from '../../routeConfig'
import ym from 'react-yandex-metrika'
import ReactGA from 'react-ga'

const GET_USER = loader('../../apollo/queries/User.graphql')

const SurveyPage = () => {
  const [user, setUser] = useState<User>()
  const [skip, setSkip] = useState(false)
  const { error } = useQuery(GET_USER, {
    skip,
    onCompleted: (result) => setUser(result.user),
    onError: (error) => console.error(error.message)
  })

  useEffect(() => {
    ym('hit', '/survey')
    ReactGA.pageview('/survey')
  }, [])

  if (error) {
    setSkip(true)
  }

  if (user && user.surveyDone) {
    return <Redirect to={routes.calendar} />
  }

  return (
    <MainLayout>
      <SurveyForm />
    </MainLayout>
  )
}

export default SurveyPage
