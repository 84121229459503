import React, {memo, useCallback, useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom'

import { useSubscription } from '../../../../hooks/subscription/useSubscription'

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import {ActivatePlans} from './plans';
import {ActivatePayment} from './payment';
import {ActivateSkeleton} from './skeleton'
import {Paragraph} from '../../../UI/Modal'
import StatusTransaction from '../StatusTransaction'
import {TEXTS} from './texts';
import {
  StyledWrapper,
  StyledLeftWrapper,
  StyledRightWrapper,
  Title
} from './styled'
import {TransactionCache} from './types';
import {getPaymentPromocodeError} from './errors';
import { getCurrencyFormatter } from 'src/utils/getCurrencyFormatter';

let transactionCache: TransactionCache = {}

const Activate = memo(function() {
  const { plans, transaction } = useSubscription()
  const [currentPlan, setCurrentPlan] = useState<PaymentPlan>(plans[0])
  const [currentTransaction, setCurrentTransaction] = useState<PaymentData>()
  const [prices, updatePrices] = useState<{old?: number, current: string}>()
  const [promocodeError, setError] = useState<string>()

  const location = useLocation()
  const [tid, setTid] = useState<string | null>(new URLSearchParams(location.search).get('tid'))

  useEffect(() => {
    const current = getCurrencyFormatter(currentPlan?.price.currency).format(currentPlan?.price.value)
    updatePrices({ current })
  }, [currentPlan?.id, currentPlan?.price.value, currentPlan?.price.currency])

  useEffect(() => {
    if (plans.length > 0) {
      setCurrentPlan(plans[0])
      createTransaction(plans[0].id)
    }
    return () => {
      transactionCache = {}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plans.length])

  const createTransaction = useCallback((planId: string, promocode?: string) => {
    if (transactionCache[planId] && transactionCache[planId].promocode === promocode) {
      setCurrentTransaction(transactionCache[planId].transaction)
      return
    }
    if (promocode) {
      transaction.create(planId, true, promocode)
        .then((result) => {
          const transaction: PaymentData = result.data.paymentCreateTransaction.paymentData
          transactionCache[planId] = {
            transaction,
            promocode
          }
          setCurrentTransaction(transaction)
          if (transaction?.price?.value && transaction?.price?.value < currentPlan?.price?.value) {
            const current = getCurrencyFormatter(transaction.price?.currency).format(transaction.price?.value)
            updatePrices({
              current,
              old: currentPlan.price.value,
            })
          }
        })
        .catch((error) => {
          console.error(error.message)
          const errorMessage = getPaymentPromocodeError(error.message)
          setError(errorMessage)
        })
      return
    }
    transaction.create(planId, true)
      .then((result) => {
        const transaction: PaymentData = result.data.paymentCreateTransaction.paymentData
        transactionCache[planId] = {
          transaction
        }
        setCurrentTransaction(transaction)
      })
      .catch((error) => console.error(error.message))
  }, [transaction, currentPlan?.price?.value])

  const changeCurrentPlan = useCallback((newPlan: PaymentPlan) => {

    createTransaction(newPlan.id)
    setCurrentPlan(newPlan)
  }, [createTransaction])

  const applyPromocode = useCallback((promocode: string) => {
    createTransaction(currentPlan.id, promocode)
  }, [createTransaction, currentPlan?.id])

  function resetTransaction() {
    setTid(null)
  }

  const handlePay = useCallback(() => {
    if (currentTransaction?.transactionId) {
      setTid(currentTransaction?.transactionId)
    }
  }, [currentTransaction?.transactionId])

  const resetInputError = useCallback(() => {
    setError(undefined)
  }, [])

  if (tid) {
    return <StatusTransaction id={tid} resetTransaction={resetTransaction}/>
  }

  if (!currentPlan) {
    return <ActivateSkeleton />
  }

  return (
    <StyledWrapper>
      <StyledLeftWrapper>
        <Title>{TEXTS.title}</Title>
        <Paragraph>{TEXTS.paragraph}</Paragraph>
        <ActivatePlans
          plans={plans}
          currentPlan={currentPlan}
          changeCurrentPlan={changeCurrentPlan}
        />
        <Paragraph>Для оплаты картами, выпущенными зарубежными банками, оформите подписку через платежную систему Prodamus на <a href='https://payform.ru/lqOrEP/' target="_blank" rel="noreferrer">1 месяц</a> или <a href='https://payform.ru/qaOsdu/' target="_blank" rel="noreferrer">1 год</a>.</Paragraph>
      </StyledLeftWrapper>
      <StyledRightWrapper>
        <ActivatePayment
          oldPrice={prices?.old}
          priceToday={prices?.current}
          transaction={currentTransaction}
          currentPlan={currentPlan}
          appliedPromocode={transactionCache[currentPlan.id]?.promocode}
          applyPromocode={applyPromocode}
          handlePay={handlePay}
          inputError={promocodeError}
          resetInputError={resetInputError}
        />
      </StyledRightWrapper>
    </StyledWrapper>
  )
})

export default Activate
