import styled from 'styled-components';
import swim from '../../assets/img/icons/swim-sport.png';
import bike from '../../assets/img/icons/bike-sport.png';
import run from '../../assets/img/icons/run-sport.png';
import gym from '../../assets/img/icons/gym-sport.png';

export const Wrapper = styled.div<{
  cursorStyle: 'pointer' | 'grab' | 'default'
  borderStyle?: string
}>`
  cursor: ${({cursorStyle}) => cursorStyle};
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 120px;
  background-color: #FFFFFF;
  padding: 8px 9px;
  margin: 10px 0;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  border: ${({borderStyle}) => borderStyle ? borderStyle : 'none'};
  overflow: hidden;
  &:active {
    box-shadow: ${({cursorStyle}) => cursorStyle === 'pointer' || cursorStyle === 'grab' ? '0 1px 3px rgba(0, 0, 0, 0.1)': null};
  }
  &:first-child {
    margin-top: 0;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Sport = styled.span<{sportSlug: string}>`
  display: flex;
  align-items: center;
  font-size: .75rem;
  font-weight: 500;
  text-transform: lowercase;
  background-repeat: no-repeat;
  background-position: center left;
  padding-left: 22px;
  background-image: ${({sportSlug}) => {
  const icon = function() {
    switch (sportSlug) {
      case 'swim': {
        return swim
      }
      case 'bike': {
        return bike
      }
      case 'run': {
        return run
      }
      case 'gym': {
        return gym
      }
    }
  }
  return `url(${icon()})`
}};
`

export const CompleteButton = styled.button<{completed: boolean}>`
  display: ${({completed}) => completed ? 'block' : 'none'};
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  width: 25px;
  height: 25px;
  padding: 0;
  font-size: .875rem;
  transition: all .15s ease-in;
  &:before {
    content: '';
    display: inline-block;
    width: 18px;
    height: 18px;
    background-position: 2px 4px;
    background-repeat: no-repeat;
    background-size: 13px;
    background-image: ${({completed}) => completed
  ? 'url("data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 8 8\'%3e%3cpath fill=\'%2387d068\' d=\'M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z\'/%3e%3c/svg%3e")'
  : 'url("data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 8 8\'%3e%3cpath fill=\'%23D8D8D8\' d=\'M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z\'/%3e%3c/svg%3e")'
}
  }
  &:hover:before {
    background-image: ${({completed}) => completed
  ? 'url("data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 8 8\'%3e%3cpath fill=\'%2387d068\' d=\'M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z\'/%3e%3c/svg%3e")'
  : 'url("data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 8 8\'%3e%3cpath fill=\'%23909090\' d=\'M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z\'/%3e%3c/svg%3e")'
}
  }
  &:focus {
    outline: none;
  }
  &:active:before {
    transform: scale(.9);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='green' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e")
  }
  ${Wrapper}:hover & {
    display: block;
  }
  ${Wrapper}:active & {
    display: block;
  }
`

export const Title = styled.h2`
  font-size: .75rem;
  font-weight: normal;
  line-height: 1.3;
  margin: 0;
  padding: 6px 0;
  word-wrap: break-word;
`

export const Time = styled.span`
  font-size: .75rem;
  font-weight: bold;
  padding-bottom: 10px;
`

export const Chart = styled.div`
  margin-top: auto;
  height: 25px;
`
