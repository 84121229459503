import React, { SyntheticEvent, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { loader } from 'graphql.macro'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'
import AccountForm from './Account/AccountForm'
import ZonesForm from './Zones/ZonesForm'
import { Content, Header, Main, Sidebar, Tabs, Tab } from '../UI/Modal'
import Skeleton from 'react-loading-skeleton'

const GET_USER = loader('../../apollo/queries/User.graphql')

type SettingsProps = {
  closeHandler: () => void
}

const Wrapper = styled.div`
  max-width: 950px;
`

const SettingsContent = styled(Content)`
  min-width: 730px;
`

const tabs = [
  { slug: 'account', title: 'Аккаунт'},
  { slug: 'zones', title: 'Зоны' }
]

const Settings = ({ closeHandler }: SettingsProps) => {
  const history = useHistory()
  const urlParams = new URLSearchParams(history.location.search)
  const activeTab = urlParams.get('tab') || tabs[0].slug
  const location = useLocation()

  const [skip, setSkip] = useState(false)
  const { loading: getUserLoading, error, data } = useQuery(GET_USER, {
    skip,
    onError: (error) => console.error(error.message)
  })

  useEffect(() => {
    if (!urlParams.get('tab')) {
      history.push({
        pathname: location.pathname,
        search: `?tab=${activeTab}`
      })
    }
  })

  if (error) {
    setSkip(true)
  }

  const user = data?.user

  function tabsRender() {
    if (getUserLoading) {
      return <Skeleton height={30} count={2} style={{margin: '15px 0 0 20px'}} width={180}/>
    }
    return (
      <Tabs>
        {tabs.map((tab) => (
          <Tab key={tab.slug} active={tab.slug === activeTab} onClick={switchTab} data-tab={tab.slug}>{tab.title}</Tab>
        ))}
      </Tabs>
    )
  }

  function contentRender() {
    switch (activeTab) {
      case 'account':
        return <AccountForm user={user} closeHandler={closeHandler} />
      case 'zones':
        return <ZonesForm zones={user?.zones} closeHandler={closeHandler} />
      default: {
        return null
      }
    }
  }

  function switchTab(event: SyntheticEvent | string) {
    let newTab
    if (typeof event !== 'string') {
      newTab = event.currentTarget.getAttribute('data-tab')
    } else {
      newTab = event
    }

    history.push({
      pathname: location.pathname,
      search: `?tab=${newTab}`
    })
  }

  return (
    <Wrapper>
      <Header>Настройки</Header>
      <Main>
        <Sidebar>
          { tabsRender() }
        </Sidebar>
        <SettingsContent>
          { contentRender() }
        </SettingsContent>
      </Main>
    </Wrapper>
  )
}

export default Settings
