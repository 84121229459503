import formatDuration from 'date-fns/formatDuration'
import { durationToString, getDurationObj } from './time'
import ru from 'date-fns/locale/ru'

type WorkoutStepDesc = Omit<WorkoutStep, 'targets'> & {
  targets: Array<Omit<WorkoutStepTarget, 'target'> & {
    target?: Pick<Target, 'slug' | 'titleDesc' | 'units'>
  }>
}

export type WorkoutSteps = Array<WorkoutStepDesc>

type UnwrappedSteps = Array<WorkoutStepDesc | [number, Array<WorkoutStepDesc>]>

type TabKey = 'power' | 'hr' | 'pace' | 'swim' | 'rpe'
type ContentKey = 'WARMUP' | 'ACTIVE' | 'COOLDOWN'

export type TabsData = {
  [key in TabKey]?: {
    [key in ContentKey]?: Array<any>
  }
}

function makeWorkoutTabsData(workoutSteps: WorkoutSteps | null): TabsData | null {

  if (!workoutSteps || workoutSteps.length === 0) {
    return null
  }

  function unwrapSteps(steps: WorkoutSteps): UnwrappedSteps  {
    return steps.reduce((acc: UnwrappedSteps, step, currentStepIndex) => {
      if (step.durationType === 'REPEAT_UNTIL_STEPS_CMPLT') {
        const repeatStartIndex = step.targets[0].value
        if (repeatStartIndex || repeatStartIndex === 0) {
          const beforeRepeatStep = acc.filter((step) => (
            Array.isArray(step) || step.index < repeatStartIndex
          ))
          const repeatedSteps = acc.filter((step) => (
            !Array.isArray(step) && step.index >= repeatStartIndex && step.index <= currentStepIndex
          ))
          // @ts-ignore
          acc = [...beforeRepeatStep, [step.durationValue, repeatedSteps]]
        }
        return acc
      }
      acc.push(step)
      return acc
    }, [])
  }

  function getStepValue(value: number, stepType: 'TIME' | 'DISTANCE' | 'REPEAT_UNTIL_STEPS_CMPLT') {
    switch (stepType) {
      case 'TIME':
        const time = getDurationObj(value)
        return formatDuration({ hours: time.hours, minutes: time.minutes, seconds: time.seconds }, { locale: ru })
      case 'DISTANCE':
        return `${value} метров`
      default:
        return null
    }
  }

  function getStepText(text: string | null): string {
    return text ? ` ${text}` : ''
  }

  function mapRepeated(repeatedStep: [number, Array<WorkoutStepDesc>], acc: TabsData): TabsData {
    const repeatedStepsResult: any = {}
    const [repeatCount, repeatedSteps] = repeatedStep

    repeatedSteps.forEach((step) => {
      const { durationValue, durationType, title, intensity, targets, cadence } = step
      const actualIntensity = intensity === 'REST' ? 'ACTIVE' : intensity
      const stepValue = getStepValue(durationValue, durationType)
      const stepText = getStepText(title)

      targets.forEach(({ low, high, hideValue, target }) => {
        // @ts-ignore
        if (target?.slug) {
          if (!repeatedStepsResult[target.slug]) {
            repeatedStepsResult[target.slug] = {}
          }
          // @ts-ignore
          if (!repeatedStepsResult[target.slug][actualIntensity]) {
            // @ts-ignore
            repeatedStepsResult[target.slug][actualIntensity] = []
          }

          if (low === 0 && high === 0) {
            // @ts-ignore
            repeatedStepsResult[target.slug][actualIntensity].push(`${stepValue} – пассивный отдых`)
            return
          }
          const cadenceStr = cadence ? `и каденсом ${cadence[0]} – ${cadence[1]} oб/мин`: undefined
          let lowStr: any = low
          let highStr: any = high

          if (low && high) {
            lowStr = target.slug === 'pace' || target.slug === 'swim' ? durationToString(low,3) : low
            highStr = target.slug === 'pace' || target.slug === 'swim' ? durationToString(high,3) : high
          }

          if (hideValue) {
            const strNoValue = `${stepValue} ${title} ${cadenceStr ? ` ${cadenceStr}` : ''}`
            // @ts-ignore
            repeatedStepsResult[target.slug][actualIntensity].push(strNoValue)
            return
          }

          const valueLowHigh = lowStr === highStr ? lowStr : `${lowStr} – ${highStr}`

          const str = `${stepValue}${stepText} с ${target.titleDesc} ${valueLowHigh} ${target.units}${cadenceStr ? ` ${cadenceStr}` : ''}`

          // @ts-ignore
          repeatedStepsResult[target.slug][actualIntensity].push(str)
          return
        }
      })
    })

    Object.keys(repeatedStepsResult).forEach((slug) => {
      Object.keys(repeatedStepsResult[slug]).forEach((intensity) => {
        // @ts-ignore
        if (!acc[slug]) {
          // @ts-ignore
          acc[slug] = {}
        }
        // @ts-ignore
        if (!acc[slug][intensity]) {
          // @ts-ignore
          acc[slug][intensity] = []
        }
        // @ts-ignore
        acc[slug][intensity].push([repeatCount, repeatedStepsResult[slug][intensity]])
      })
    })
    return acc
  }

  const unwrappedSteps = unwrapSteps(workoutSteps)

  return unwrappedSteps.reduce((acc:TabsData, step) => {
    if (Array.isArray(step)) {
      mapRepeated(step, acc)
      return acc
    }

    const { durationValue, durationType, title, intensity, targets, cadence } = step

    const actualIntensity = intensity === 'REST' ? 'ACTIVE' : intensity
    const stepValue = getStepValue(durationValue, durationType)
    const stepText = getStepText(title)

    targets.forEach(({ low, high, hideValue, target }) => {
      // @ts-ignore
      if (target?.slug) {
        if (!acc[target.slug]) {
          acc[target.slug] = {}
        }
        // @ts-ignore
        if (!acc[target.slug][actualIntensity]) {
          // @ts-ignore
          acc[target.slug][actualIntensity] = []
        }

        if (low === 0 && high === 0) {
          // @ts-ignore
          acc[target.slug][actualIntensity].push(`${stepValue} – пассивный отдых`)
          return
        }
        const cadenceStr = cadence ? `и каденсом ${cadence[0]} – ${cadence[1]} oб/мин`: undefined
        let lowStr: any = low
        let highStr: any = high

        if (low && high) {
          lowStr = target.slug === 'pace' || target.slug === 'swim' ? durationToString(low,3) : low
          highStr = target.slug === 'pace' || target.slug === 'swim' ? durationToString(high,3) : high
        }

        if (hideValue) {
          const strNoValue = `${stepValue} ${title}`
          // @ts-ignore
          acc[target.slug][actualIntensity].push(strNoValue)
          return
        }

        const valueLowHigh = lowStr === highStr ? lowStr : `${lowStr} – ${highStr}`

        const str = `${stepValue}${stepText} с ${target.titleDesc} ${valueLowHigh} ${target.units}${cadenceStr ? ` ${cadenceStr}` : ''}`

        // @ts-ignore
        acc[target.slug][actualIntensity].push(str)

        return
      }
    })
    return acc
  }, {})
}

function mapTarget(targetId?: number) {
  const map: {
    [key: number]: { name: string, units: string, slug: string, text: string }} = {
    1: {
      name: 'Мощность',
      units: 'Вт',
      slug: 'power',
      text: 'с мощностью'
    },
    2: {
      name: 'Пульс',
      units: 'уд/мин',
      slug: 'hr',
      text: 'с пульсом'
    },
    3: {
      name: 'Темп',
      units: 'мин/км',
      slug: 'pace',
      text: 'с темпом'
    },
    4: {
      name: 'RPE',
      units: 'из 10',
      slug: 'rpe',
      text: 'с RPE'
    },
    5: {
      name: 'Темп',
      units: 'мин/100м',
      slug: 'swim',
      text: 'с темпом'
    }
  }

  return targetId ? map[targetId] : map
}

function getDefaultTargetsMap(): Array<Target> {
  return [
    {
      "id": "1",
      "title": "",
      "titleDesc": null,
      "descriptionTarget": null,
      "units": "Вт",
      "sensorTitle": null,
      "slug": "power",
      "order": 0,
      "showSurvey": true,
      "isPace": false,
      "descriptionSurvey": '',
      "descriptionHelp": '',
      "defaultThreshold": 200
    },
    {
      "id": "2",
      "title": "",
      "titleDesc": null,
      "descriptionTarget": null,
      "units": "уд/мин",
      "sensorTitle": null,
      "slug": "hr",
      "order": 5,
      "showSurvey": false,
      "isPace": false,
      "descriptionSurvey": '',
      "descriptionHelp": '',
      "defaultThreshold": 165
    },
    {
      "id": "3",
      "title": "",
      "titleDesc": null,
      "descriptionTarget": null,
      "units": "мин/км",
      "sensorTitle": null,
      "slug": "pace",
      "order": 1,
      "showSurvey": true,
      "isPace": true,
      "descriptionSurvey": '',
      "descriptionHelp": '',
      "defaultThreshold": 270
    },
    {
      "id": "4",
      "title": "",
      "titleDesc": null,
      "descriptionTarget": null,
      "units": "из 10",
      "sensorTitle": null,
      "slug": "rpe",
      "order": 10,
      "showSurvey": false,
      "isPace": false,
      "descriptionSurvey": '',
      "descriptionHelp": '',
      "defaultThreshold": 0
    },
    {
      "id": "5",
      "title": "",
      "titleDesc": null,
      "descriptionTarget": null,
      "units": "мин/100м",
      "sensorTitle": null,
      "slug": "swim",
      "order": 3,
      "showSurvey": true,
      "isPace": true,
      "descriptionSurvey": '',
      "descriptionHelp": '',
      "defaultThreshold": 180
    }
  ]
}

export {
  makeWorkoutTabsData,
  getDefaultTargetsMap,
  mapTarget,
}

