import React, {memo} from 'react';
import {Wrapper, EditButton} from './styled';
import ReactTooltip from 'react-tooltip';
import {EditEventProps} from './types';

export const EditEventButton: React.FC<EditEventProps> = memo(function EditEvent({handleClick}) {
  return (
    <Wrapper>
      <EditButton
        type='button'
        onClick={() => handleClick()}
        data-tip
        data-for='edit-button'
      />
      <ReactTooltip id='edit-button' place='top' effect='solid'>
        <p style={{textAlign: 'center'}}>Редактировать событие</p>
      </ReactTooltip>
    </Wrapper>
  )
})
