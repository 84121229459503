import React, { useState } from 'react'
import {CalendarEvent} from '../components/CalendarEvent';
import {EventContainer} from './EventContainer';

interface CalendarEventContainerProps {
  event: UserEvent
}

export const CalendarEventContainer = ({ event }: CalendarEventContainerProps) => {
  const [visible, setVisible] = useState(false)

  if (!event) {
    return null
  }

  function modalHandler() {
    setVisible((prevVisible) => !prevVisible)
  }

  return (
    <div onClick={() => !visible && setVisible(true)}>
      <CalendarEvent event={event} cursorStyle='pointer'/>
      {visible && <EventContainer event={event} closeHandler={modalHandler}/>}
    </div>
  )
}
