import React from 'react'
import ReactDatePicker, { ReactDatePickerProps, registerLocale, setDefaultLocale } from 'react-datepicker'
import ru from 'date-fns/locale/ru'
import "./styles.css"
import format from 'date-fns/format'
import styled from 'styled-components'
import { ErrorMessage } from 'formik'
registerLocale('ru', ru)
setDefaultLocale('ru')

const CalendarContainer = styled.div`
  display: flex;
  justify-content: center;
  border: none;
  background-color: transparent;
  max-width: 310px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  color: #828282;
  font-weight: 500;
  text-transform: capitalize;
  & + div {
    display: none;
  }
`

const MonthChooser = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 55px;
  & button {
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
  }
  & button:disabled {
    cursor: default;
  }
  & button:before {
    content: '';
    border-width: 0.15em 0.15em 0 0;
    display: inline-block;
    border-style: solid;
    height: 0.4rem;
    transform: rotate(-135deg);
    width: 0.4rem;
    margin: 1.2px 5px;
    transition: all .15s ease;
  }
  & button:first-of-type {
    &:hover:before {
      transform: scale(1.1) rotate(-135deg);
    }
    &:active:before {
      transform: scale(.9) rotate(-135deg);
    }
    &:disabled:hover:before {
      transform: rotate(-135deg);
    }
  }
  & button:last-of-type {
    transform: rotate(180deg);
    &:hover:before {
      transform: scale(1.1) rotate(225deg);
    }
    &:active:before {
      transform: scale(.9) rotate(225deg);
    }
  }
`

const ErrorStyled = styled(ErrorMessage)`
  font-size: .75rem;
  color: #CC0000;
  padding: 3px 2px;
  line-height: 1.3;
`

export const DatePicker = (props: ReactDatePickerProps & {withError?: boolean}) => {

  function customHeaderRender(headerProps: any) {
    const { date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled,  } = headerProps
    return (
      <Header>
        { format(date, 'LLLL yyyy', {locale: ru}) }
        <MonthChooser>
          <button type='button' onClick={decreaseMonth} disabled={prevMonthButtonDisabled} />
          <button type='button' onClick={increaseMonth} disabled={nextMonthButtonDisabled} />
        </MonthChooser>
      </Header>
    )
  }

  return (
    <>
      <ReactDatePicker
        {...props}
        locale='ru'
        calendarContainer={CalendarContainer}
        renderCustomHeader={customHeaderRender}
      />
      {props.withError && props.name && <ErrorStyled name={props.name} component='div'/> }
    </>
  )
}
