import React, { SyntheticEvent, useState } from 'react'
import {CalendarWorkout} from '../components/CalendarWorkout'
import WorkoutContainer from './WorkoutContainer'
import { loader } from 'graphql.macro'
import { useApolloClient, useMutation } from '@apollo/client'
import styled from 'styled-components'

const WorkoutReadFragment = loader('../apollo/fragments/read/WorkoutRead.graphql')
const WorkoutUpdate = loader('../apollo/mutations/UpdateWorkout.graphql')

interface CalendarWorkoutContainerProps {
  id: number
}

const Blur = styled.div`
  filter: blur(3px);
  cursor: default;
`

const CalendarWorkoutContainer = ({ id }: CalendarWorkoutContainerProps) => {
  const client = useApolloClient()
  const [isOpenWorkout, setWorkoutModalState] = useState(false)
  const workout = client.readFragment({
    id: `WorkoutDay:${id}`,
    fragment: WorkoutReadFragment
  })

  const [updateWorkout] = useMutation(WorkoutUpdate)

  if (!workout) {
    return null
  }

  function modalHandler() {
    setWorkoutModalState(!isOpenWorkout)
  }

  function completeHandler(event:SyntheticEvent) {
    event.stopPropagation()
    updateWorkout({
      variables: {
        input: {
          workoutId: id,
          completed: !workout?.completed
        }
      }
    })
  }

  if (workout.isPaywall) {
    return (
      <Blur>
        <CalendarWorkout workout={workout}/>
      </Blur>
    )
  }

  return (
    <div onClick={() => !isOpenWorkout && setWorkoutModalState(true)}>
      <CalendarWorkout
        workout={workout}
        completeHandler={completeHandler}
        cursorStyle='pointer'
      />
      {isOpenWorkout && <WorkoutContainer id={id} closeHandler={modalHandler}/>}
    </div>
  )
}

export default CalendarWorkoutContainer
