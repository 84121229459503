import React from 'react'
import styles from './NewPassword.module.css'
import { Form, Formik, FormikValues } from 'formik'
import Button from '../../UI/Button/Button'
import { useHistory } from 'react-router-dom'
import { Wrapper, Input } from '../../UI/Form'
import SmallLoader from '../../UI/SmallLoader/SmallLoader'
import NewPasswordSchema from './validation'
import { loader } from 'graphql.macro'
import { useMutation } from '@apollo/client'
import { routes } from '../../../routeConfig'

const CodeForm = () => {
  const history = useHistory()
  const UpdateUser = loader('../../../apollo/mutations/UpdateUser.graphql')
  const [updateUser] = useMutation(UpdateUser)

  function formSubmit(values: FormikValues, { setSubmitting }: any):void {
    setSubmitting(true)
    updateUser({
      variables: {
        user: {
          newPassword: values.newPassword
        }
      }
    })
      .then(() => {
        setSubmitting(false)
        history.push(routes.calendar)
      })
      .catch((error) => {
        setSubmitting(false)
        console.error(error.message)
      })
  }

  return (
    <Formik
      initialValues={{
        newPassword: '',
        repeatNewPassword: '',
      }}
      onSubmit={formSubmit}
      validationSchema={NewPasswordSchema}
    >
      {(props) => (
        <Wrapper>
          <Form>
            <h2 className={styles.title}>Установите новый пароль</h2>
            <div className={styles.row}>
              <div className={styles.col}>
                <Input name='newPassword' type='password' placeholder='Новый пароль' formik={props} withError/>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>
                <Input name='repeatNewPassword' type='password' placeholder='Еще раз новый пароль' formik={props} withError/>
              </div>
            </div>
            <Button
              className={styles.submit}
              type='submit'
              solid
              disabled={props.isSubmitting}
            >
              {props.isSubmitting ? <SmallLoader /> : 'Обновить пароль'}
            </Button>
          </Form>
        </Wrapper>
      )}
    </Formik>
  )
}

export default CodeForm
