import React from 'react'
import { Switch, Route, useLocation, Redirect } from 'react-router-dom'
import { routes } from './routeConfig'
import SignUpPage from './pages/SignUpPage'
import SignInPage from './pages/SignInPage'
import AppPage from './pages/AppPage'
import Private from './pages/Private/Private'
import ResetPage from './pages/ResetPage'
import CodePage from './pages/CodePage'
import SurveyPage from './pages/SurveyPage'
import PageNotFound from './pages/404/PageNotFound'
import ToSPage from './pages/ToS'
import PrivacyPage from './pages/Privacy'
import ReloginPage from './pages/Relogin'
import ModalPage from './pages/ModalPage'
import MaintenancePage from './pages/Maintenance'

function App() {
  const location = useLocation<any>()
  const background = getBackground()

  function getBackground() {
    if (location.state) {
      return location.state.background
    }
    switch (location.pathname) {
      case routes.settings:
        return {pathname: routes.calendar}
      case routes.planbuilder:
        return {pathname: routes.calendar}
      case routes.subscription:
        return {pathname: routes.calendar}
      default:
        return undefined
    }
  }

  return (
    <>
      <Switch location={background || location}>
        <Private path={routes.calendar} exact>
          <AppPage />
        </Private>
        <Route path={routes.signUp} exact>
          <SignUpPage />
        </Route>
        <Route path={routes.resetPassword} exact>
          <ResetPage />
        </Route>
        <Route path={routes.code} exact>
          <CodePage />
        </Route>
        <Private path={routes.survey} exact>
          <SurveyPage />
        </Private>
        <Route path={routes.signIn} exact>
          <SignInPage />
        </Route>
        <Private path={routes.relogin} exact>
          <ReloginPage />
        </Private>
        <Route path={routes.tos} exact>
          <ToSPage />
        </Route>
        <Route path={routes.privacy} exact>
          <PrivacyPage />
        </Route>
        <Route path={routes.subscriptionConfirm}>
          <Redirect to={{
            pathname: routes.subscription,
            search: `${location.search}&tab=activate`
          }} />
        </Route>
        <Route path={routes.maintenance} exact>
          <MaintenancePage />
        </Route>
        <Route component={PageNotFound}/>
      </Switch>
      {background && (
        <Private path={routes.calendar} exact={false}>
          <ModalPage />
        </Private>
      )}
    </>
  )
}

export default App
