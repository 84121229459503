import React, { useEffect } from 'react'
import ym from 'react-yandex-metrika'
import ReactGA from 'react-ga'
import { routes } from '../../routeConfig'
import ReloginContainer from '../../containers/ReloginContainer'

const ReloginPage = () => {
  useEffect(() => {
    ym('hit', routes.relogin)
    ReactGA.pageview(routes.relogin)
  }, [])
  return (
    <>
      <ReloginContainer />
    </>
  )
}

export default ReloginPage
