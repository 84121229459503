import {gql} from '@apollo/client';

export const GET_PROGRAMS_FOR_TODAY = gql`
    query GetProgramsForToday($dateMin: Date, $dateMax: Date) {
        calendar(dateMin: $dateMin, dateMax: $dateMax) {
            programms {
                id
                title
                startDate
                endDate
            }
        }
    }
`
