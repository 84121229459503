import React, { useState } from 'react'
import NewPasswordForm from '../components/Forms/NewPassword/NewPasswordForm'
import CodeForm from '../components/Forms/Code/CodeForm'

const CodeFormContainer = () => {
  const [isActiveNewPasswordForm, setNewPasswordFormState] = useState(false)

  function formHandler() {
    setNewPasswordFormState(!isActiveNewPasswordForm)
  }

  return isActiveNewPasswordForm
    ? <NewPasswordForm />
    : <CodeForm formHandler={formHandler}/>
}

export default CodeFormContainer