import React, { useState } from 'react'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import { Col, Paragraph, Row, Title } from '../../UI/Form'
import { useMutation } from '@apollo/client'
import PlanBuilderWeek from '../PlanBuilderWeek/PlanBuilderWeek'
import Button from '../../UI/Button/Button'
import styled from 'styled-components'
import { DropResult } from 'react-beautiful-dnd'
import parseISO from "date-fns/parseISO"
import isBefore from "date-fns/isBefore"
import { loader } from 'graphql.macro'
import ym from 'react-yandex-metrika'
import ymGoals from '../../../analytics/ymGoals'
import { usePlanBuilder } from '../../../services/PlanBuilder'
import { PlanBuilderStepProps } from './types'

const PLAN_PREVIEW = loader('../../../apollo/mutations/PlanPreview.graphql')

const Buttons = styled.div`
  margin-top: 25px;
  display: flex;
  & button {
    margin-left: 25px;
    &:first-child {
      margin: 0;
    }
  }
`

const BackButton = styled(Button)`
  border: none;
  color: #2F80ED;
  &:hover {
    background: none;
    border: none;
    color: #2A75DB;
    text-decoration: underline;
  }
`

const Step3 = ({ title, tabSwitcher }: PlanBuilderStepProps) => {
  const { store, isDropDisabled, getScheduleText } = usePlanBuilder()
  const schedule = store.value.schedule

  const [planPreview, { loading }] = useMutation(PLAN_PREVIEW)
  const [dropState, setDropState] = useState<DropState>({
    isDropping: false,
    sourceDate: null,
    droppingWorkoutSportId: null,
    droppingWorkoutIntensityId: null
  })

  if (!schedule) {
    return (
      <>
        <Paragraph>Oops! Training days doesn't exist.</Paragraph>
        <Buttons>
          <BackButton type='button' onClick={() => tabSwitcher('2')} disabled={loading}>Вернуться назад</BackButton>
        </Buttons>
      </>
    )
  }

  function sendPlanBuilderInput() {
    const cleanedSchedule = schedule && schedule.map(({ date, sport, order, intensity}) => ({
      date,
      sportId: sport.id,
      order,
      workoutIntensityId: intensity.id
    }))
    const input = {
      trainingStartDate: store.value.trainingStartDate,
      events: store.value.events,
      programm: store.value.programm,
      includeTesting: store.value.includeTesting || false,
      schedule: cleanedSchedule,
    }

    planPreview({
      variables: {
        activatePlan: false,
        input
      }
    })
      .then((result) => {
        store.update({
          preview: {
            programs: result.data.planPreview.calendar.programms,
            workouts: result.data.planPreview.calendar.workouts,
            events: result.data.planPreview.calendar.events
          }
        })
        ym('reachGoal', ymGoals.planbuilder.thirdStepSuccess)
        tabSwitcher('4')
      })
      .catch((error) => console.error(error.message))
  }

  function onDragStart(result: DropResult) {
    const { draggableId, source: { droppableId } } = result
    const [, sportId, intensityId] = draggableId.split(':')

    setDropState({
      isDropping: true,
      sourceDate: parseISO(droppableId),
      droppingWorkoutSportId: +sportId,
      droppingWorkoutIntensityId: +intensityId
    })
  }

  function onDragEnd(result: DropResult) {
    const { destination, source } = result

    if (!schedule) {
      return
    }

    setDropState({
      isDropping: false,
      sourceDate: null,
      droppingWorkoutSportId: null,
      droppingWorkoutIntensityId: null
    })

    if (!destination) {
      return
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return
    }
    if (source.droppableId === destination.droppableId) {
      const newSchedule = schedule.map((workout) => {
        if (workout.date === source.droppableId && source.index === workout.order) {
          return {
            ...workout,
            order: destination.index
          }
        }
        if (workout.date === source.droppableId && source.index !== workout.order) {
          return {
            ...workout,
            order: destination.index === 1 ? 0 : 1
          }
        }
        return workout
      })
        .sort((workout1, workout2) => (
          isBefore(parseISO(workout1.date), parseISO(workout2.date)) ? 1 : -1
        ))
      store.update({ schedule: newSchedule })
      return
    }

    const newSchedule: any = schedule
      .map((workout) => {
        if (workout.date === source.droppableId && source.index === workout.order) {
          return {
            ...workout,
            order: destination.index,
            date: destination.droppableId,
          }
        }
        if (workout.date === source.droppableId && source.index !== workout.order) {
          return {
            ...workout,
            order: 0
          }
        }
        if (workout.date === destination.droppableId) {
          return {
            ...workout,
            order: destination.index === 1 ? 0 : 1
          }
        }
        return workout
      })
    store.update({ schedule: newSchedule })
  }

  return (
    <div>
      <Title style={{paddingBottom: 10}}>{title}</Title>
      <Row>
        <Col>
          <Paragraph>{getScheduleText()}</Paragraph>
        </Col>
      </Row>
      <Row>
        <Col>
          <PlanBuilderWeek
            isDnD
            workouts={schedule}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            isDropDisabled={isDropDisabled(dropState)}
          />
        </Col>
      </Row>
      <Buttons>
        <Button type='button' onClick={sendPlanBuilderInput} solid disabled={loading}>
          {loading ? <Loader type='ThreeDots' color='#FFFFFF' height={10}/> : 'Продолжить'}
        </Button>
        <BackButton type='button' onClick={() => tabSwitcher('2')} disabled={loading}>Вернуться назад</BackButton>
      </Buttons>
    </div>
  )
}

export default Step3
