import styled from 'styled-components';
import Button from '../../UI/Button/Button';

export const Wrapper = styled.h2`
  width: 320px;
  margin: 0 auto;
`

export const Title = styled.span`
  color: #3E3E3E;
  font-weight: 500;
  font-size: .875rem;
`

export const SmallText = styled.span`
  color: #BDBDBD;
  font-size: .75rem;
  font-weight: normal;
  padding-top: 6px;
  line-height: 1.4;
`

export const Buttons = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  & button {
    margin-left: 25px;
    &:first-child {
      margin: 0;
    }
  }
`

export const BackButton = styled(Button)`
  border: none;
  color: #2F80ED;
  padding: 0;
  &:hover {
    background: none;
    border: none;
    color: #2A75DB;
    text-decoration: underline;
  }
`

export const StartDate = styled.span<{error: boolean}>`
  display: inline-block;
  font-weight: ${props => props.error ? 700 : 500};
  margin-left: 5px;
  color: ${props => props.error && '#CC0000'};
`
