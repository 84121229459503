import React from 'react';

import {CalendarEventProps} from './types';
import {Wrapper, Header, Title, Content} from './styled'

export const CalendarEvent: React.FC<CalendarEventProps> = ({ event, cursorStyle= 'default' }) => {
  return (
    <Wrapper cursorStyle={cursorStyle}>
      <Header>
        <Title>Событие</Title>
      </Header>
      <Content>{event.title}</Content>
    </Wrapper>
  )
}
