import React, {memo, useState} from 'react';
import {AddEventProps} from './types';
import {Form, Formik, FormikValues} from 'formik';
import {Col, Input, Row} from '../../UI/Form';
import Button from '../../UI/Button/Button';
import {
  Wrapper,
  Title,
  SmallText,
  Buttons,
  BackButton
} from './styled';
import * as Yup from 'yup';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import {gql, useApolloClient, useMutation} from '@apollo/client';
import Loader from 'react-loader-spinner';

const UPDATE_EVENT = gql`
    mutation UpdateEvent($event: EventInput!, $id: ID!) {
        eventUpdate(event: $event, id: $id) {
            event {
                id
                title
                date
                priority
                programm {
                    id
                    title
                }
                notes
            }
        }
    }
`

export const EditEvent: React.FC<AddEventProps> = memo(function AddEvent({event, setCurrentEvent, onClose}) {
  const [isLoading, setIsLoading] = useState(false)
  const [updateEvent] = useMutation(UPDATE_EVENT)
  const client = useApolloClient()

  function formSubmit(values: FormikValues) {
    setIsLoading(true)
    const updationEvent = {
      title: values.eventName,
      date: event.date,
      priority: event.priority,
      programm: (event.programm as unknown as TrainingProgram)?.id,
      notes: values.notes
    }
    updateEvent({
      variables: {
        event: updationEvent,
        id: event.id
      }
    })
      .then((result) => {
        const updatedEvent = result.data.eventUpdate.event
        console.log('updatedEvent', updatedEvent);
        client.resetStore()
          .then(() => {
            setCurrentEvent(updatedEvent)
            onClose()
          })
      })
      .catch((error) => {
        console.error(error.message)
        setIsLoading(false)
      })
  }

  return (
    <Wrapper>
      <Formik
        initialValues={{
          eventName: event.title,
          eventType: (event.programm as unknown as TrainingProgram)?.title,
          eventDate: format(parseISO(event.date), 'dd.MM.yyyy'),
          notes: event.notes || ''
        }}
        onSubmit={formSubmit}
        validationSchema={AddEventSchema}
        validateOnBlur={false}
      >
        {(props) => {
          return (
            <Form>
              <Title style={{paddingBottom: 10}}>Редактировать событие</Title>
              <Row>
                <Col>
                  <Input
                    name='eventName'
                    label='Название события'
                    placeholder='Укажите название события'
                    formik={props}
                    withError
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input
                    name='eventType'
                    label='Тип события'
                    formik={props}
                    withError
                    disabled
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input
                    name='eventDate'
                    label='Дата события'
                    formik={props}
                    withError
                    disabled
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input
                    name='priority'
                    label='Приоритет события'
                    placeholder='B — Второстепенные старты'
                    formik={props}
                    disabled
                    withError
                  />
                  <SmallText>
                    В данный момент поддерживаются только второстепенные события. Создание основного старта доступно через конструктор плана.
                  </SmallText>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input
                    as='textarea'
                    name='notes'
                    label='Заметки'
                    formik={props}
                    withError
                  />
                </Col>
              </Row>
              <Buttons>
                <Button type='submit' solid>
                  {isLoading
                    ? <Loader type='ThreeDots' color='#FFFFFF' height={10}/>
                    : 'Сохранить'
                  }
                </Button>
                <BackButton type='button' onClick={() => onClose()} >Отменить</BackButton>
              </Buttons>
            </Form>
          )
        }}
      </Formik>
    </Wrapper>
  )
})

const AddEventSchema = Yup.object().shape({
  eventName: Yup.string()
    .required('Укажите название события'),
})
