import React from 'react'
import styled from 'styled-components'
import { getPreviewWeekDays } from '../../../utils/week'
import PlanBuilderDay from '../PlanBuilderDay/PlanBuilderDay'
import PlanBuilderWeekWithDnD from '../../../dnd/PlanBuilderWeekWithDnD'
import { DropResult } from 'react-beautiful-dnd'
import { getWeekHead } from '../../../utils/time'

type PlanBuilderWeekProps = {
  workouts: Array<WorkoutSchedule>
  events?: Array<UserEvent>
  isDnD?: boolean
  onDragStart?: (result: DropResult) => void
  onDragEnd?: (result: DropResult) => void
  isDropDisabled?: (workouts: Array<WorkoutSchedule>, date: string) => boolean
}

const Week = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
`

const PlanBuilderWeek = ({ workouts, isDnD, events, ...restProps }: PlanBuilderWeekProps) => {
  if (workouts.length === 0) {
    return null
  }
  const firstDateOfWeek = getWeekHead(workouts[0].date)
  const weekDays = getPreviewWeekDays(firstDateOfWeek)

  if (isDnD) {
    return <PlanBuilderWeekWithDnD workouts={workouts} {...restProps}/>
  }

  function getDayWorkouts(dayDate: string): Array<WorkoutSchedule> {
    return workouts.filter(({ date }) => date === dayDate)
  }

  return (
    <Week>
      {weekDays.map(({ date }) => {
        const workouts = getDayWorkouts(date)
        return (
          <PlanBuilderDay
            key={date}
            date={date}
            workouts={workouts}
            events={events}
          />
        )
      })}
    </Week>
  )
}

export default PlanBuilderWeek
