import {useCallback} from 'react';
import { calendarCurrentMonth, calendarEndDate, calendarStartDate } from '../apollo/cache'
import { Duration } from 'date-fns'
import sub from "date-fns/sub"
import startOfWeek from "date-fns/startOfWeek"
import add from "date-fns/add"
import endOfWeek from "date-fns/endOfWeek"

const DEFAULT_OFFSET: Duration = { months: 1 }

type UseCalendarResult = {
  addToStart: (localOffset?: Duration) => void
  addToEnd: (localOffset?: Duration) => void
  scrollToToday: (smooth?: boolean) => void
  scrollToMonth: (date: string) => void
}

export function useCalendarTools(): UseCalendarResult {
  const addToStart = useCallback((offset = DEFAULT_OFFSET) => {
    const startScrollPosition = document.documentElement.scrollTop
    const monthAgoExact = sub(calendarStartDate (), offset)
    const monthAgoWeekStart = startOfWeek (monthAgoExact, {weekStartsOn: 1})
    calendarStartDate (monthAgoWeekStart)
    document.documentElement.scrollTop = startScrollPosition + 1700
  }, []);

  const addToEnd = useCallback((offset = DEFAULT_OFFSET) => {
    const monthAheadExact = add(calendarEndDate (), offset)
    const monthAheadWeekEnd = endOfWeek(monthAheadExact, {weekStartsOn: 1})
    calendarEndDate(monthAheadWeekEnd)
  }, []);


  const scrollToToday = (smooth = false) => {
    const todayCell = document.querySelector ('div[data-today="today"]')
    if (todayCell) {
      todayCell.scrollIntoView ({
        behavior: smooth ? 'smooth' : 'auto',
        block: 'center',
      })
    }
  }

  const scrollToMonth = (dataTag: string) => {
    const target = document.querySelector (`div[data-month="${ dataTag }"]`)
    if (target) {
      target.scrollIntoView ({
        behavior: 'smooth',
        block: 'center',
      })
      calendarCurrentMonth (dataTag)
    }
  }

  return {
    addToStart,
    addToEnd,
    scrollToToday,
    scrollToMonth
  }
}
