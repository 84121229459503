import styled from 'styled-components';

export const EventWrapper = styled.div`
  width: 910px;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 40px;
`

export const HeaderLeft = styled.div`
  
`

export const ActionButtons = styled.div`
  display: flex;
  & > *:not(:last-child) {
    margin-right: 12px;
  }
`

export const Title = styled.h2`
  margin: 8px 0 15px;
`

export const TopStyled = styled.div`
  display: flex;
  & span {
    text-transform: uppercase;
    font-size: .875rem;
    &:nth-child(2) {
      padding-left: 4px;
    }
  }
`

export const ContentStyled = styled.div`
  padding: 8px 0;
`
