import getDay from 'date-fns/getDay'
import addDays from 'date-fns/addDays'
import subDays from 'date-fns/subDays'
import isSameDay from 'date-fns/isSameDay'

function getPrevSyntheticDay(date: Date): Date {
  if (getDay(date) === 1) {
    return addDays(date, 6)
  }
  return subDays(date, 1)
}

function getNextSyntheticDay(date: Date): Date {
  if (getDay(date) === 0) {
    return subDays(date, 6)
  }
  return addDays(date, 1)
}

export function getNearDays(date: Date, depth: number = 1): Array<Date> {
  const result: Set<Date> = new Set()
  const prevDay = getPrevSyntheticDay(date)
  const nextDay = getNextSyntheticDay(date)
  if (depth > 1) {
    getNearDays(prevDay, depth - 1).forEach((nearDay) => (
      !isSameDay(nearDay, date) && result.add(nearDay)
    ))
    getNearDays(nextDay, depth - 1).forEach((nearDay) => (
      !isSameDay(nearDay, date) && result.add(nearDay)
    ))
  }
  result.add(prevDay)
  result.add(nextDay)
  return Array.from(result)
}

interface Option {
  label: string;
  value: string;
}

interface Group {
  label: string;
  options: Option[];
}

export function getSelectorOptions(programs?: Array<TrainingProgram>): Group[] | undefined {
  const groups: Array<ProgramGroup> = []
  if (!programs) {
    return;
  }

  function inGroups(groups: Array<ProgramGroup>, search: ProgramGroup) {
    return groups.some(({ id }) => id === search.id)
  }

  for (const { group } of programs) {
    if (!inGroups(groups, group)) {
      groups.push(group)
    }
  }
  return groups
    .sort((group1, group2) => group1.id > group2.id ? 1 : -1)
    .map((group) => {
    const groupTrainingPrograms = programs
      .filter(({ group: { title } }) => group.title === title)
      .map(({ id, title }) => ({
        label: title,
        value: `${id}`
      }))
    return {
      label: group.title,
      options: groupTrainingPrograms
    }
  })
}

export function getSelectorOptionsWithoutEvents(programs?: Array<TrainingProgram>): Group[] | undefined {
  if (!programs) {
    return;
  }
  return (getSelectorOptions(programs.filter(({ fillEventDate }) => !fillEventDate)))
}
