import React from 'react'
import styles from './WeekHead.module.css'
import { Line } from "rc-progress"
import Skeleton from 'react-loading-skeleton'
import { useSport } from '../../../hooks/useSport'

type WeekheadProps = {
  params: IWeekHeadParams | undefined
  isLoading: boolean
  title: string
}

function getColorByPercent(percent: number) {
  if (percent < 30) {
    return '#F3665B'
  }

  if (percent >= 30 && percent < 75) {
    return '#FBAD3E'
  }

  return '#87d068'
}

const WeekHead = ({ isLoading, params, title }:WeekheadProps) => {
  const sport = useSport()

  if (isLoading) {
    return (
      <div className={styles.weekhead}>
        <Skeleton count={7} height={15} />
      </div>
    )
  }

  if (params === undefined) {
    return (
      <div className={styles.weekhead}>
        <p className={styles.emptyWeek}>Тренировок нет</p>
      </div>
    )
  }
  const { total, progress, ...sports } = params

  return (
    <div className={styles.weekhead}>
      { title !== '' && <span className={styles.title}>{title}</span>}
        <div className={styles.progress}>
          <div className={styles.mark}>
            <span className={styles.markName}>Всего:</span>
            <span className={styles.markValue}>{total?.completed} / {total?.sum}</span>
          </div>
          <div className={styles.progressBar}>
            <Line
              percent={progress}
              trailWidth={4}
              strokeWidth={4}
              strokeColor={getColorByPercent(progress)}
            />
          </div>
          {
            // @ts-ignore
            Object.keys(sports).map((slug: 'swim' | 'bike' | 'run' | 'gym') => {
              return (
                <div className={ styles.mark } key={slug}>
                  <span className={ styles.markName }>{sport.getBySlug(slug)?.short}</span>
                  <span className={ styles.markValue }>{ sports[slug]?.completed } / { sports[slug]?.sum }</span>
                </div>
              )
            })
          }
        </div>
    </div>
  )
}

export default React.memo(WeekHead)
