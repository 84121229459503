import React, {useMemo} from 'react';
import {Droppable} from 'react-beautiful-dnd';
import {useReactiveVar} from '@apollo/client';

import isWithinInterval from 'date-fns/isWithinInterval'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore';
import startOfDay from 'date-fns/startOfDay'
import sub from 'date-fns/sub';
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format';

import CalendarWorkoutDraggable from '../../dnd/Draggable/CalendarWorkoutDraggable'
import CalendarWorkoutContainer from '../../containers/CalendarWorkoutContainer'

import {
  WorkoutsWrapper,
  NoWorkouts,
  SkeletonStyled
} from './styled';
import {CellWorkoutsProps} from './types';
import {AddCalendarEvent} from './event-plus';
import {useCalendarEvents} from '../../hooks/useCalendarEvents';
import {useRestrictDates} from '../../hooks/useRestrictDates';

import {userProgramms} from '../../apollo/cache';

export const CellWorkouts = ({ isLoading, workouts, date, isDropDisabled, isDayOff }: CellWorkoutsProps) => {
  const today = useMemo(() => new Date(), []);
  const [nearestEvent] = useCalendarEvents({
    priority: 'A',
    time: 'future',
    nearest: true
  })
  const restrictDates = (useRestrictDates({format: 'string'}) as string[])
  const userProgrammsList = useReactiveVar(userProgramms)

  const lastProgramDate = useMemo(() => {
      if (userProgrammsList && userProgrammsList.length !== 0) {
        return [...userProgrammsList]
          .sort((a, b) => isAfter(parseISO(b.endDate), parseISO(a.endDate)) ? 1 : -1)
          [0].endDate
      }
    }, [userProgrammsList]);

  const isInInterval = useMemo(() => {
    if (!nearestEvent) {
      if (lastProgramDate) {
        return isAfter(date, today) && isBefore(date, parseISO(lastProgramDate))
      }
      return false
    }
    return isWithinInterval(date, {
      start: startOfDay(today),
      end: sub(parseISO(nearestEvent?.date), {days: 1})}
    )
  }, [nearestEvent, date, today, lastProgramDate]);

  const isInRestricts = restrictDates.includes(format(date, 'yyyy-MM-dd'))

  if (isLoading) {
    return <SkeletonStyled />
  }

  return (
    <Droppable
      droppableId={format(date, 'yyyy-MM-dd')}
      isDropDisabled={isDropDisabled}
    >
      {(provided, snapshot) => {
        const addEventVisible = !snapshot.isDraggingOver && isInInterval && !isInRestricts
        return (
          <WorkoutsWrapper
            isDraggingOver={snapshot.isDraggingOver}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {isDayOff
              ? <NoWorkouts>День отдыха</NoWorkouts>
              : workouts
                .sort((a, b) => a.order > b.order ? 1 : -1)
                .map(({ id, isPaywall}, index) => (
                  isPaywall
                    ? <CalendarWorkoutContainer key={id} id={id}/>
                    : (
                      <CalendarWorkoutDraggable
                        key={id}
                        index={index}
                        id={id}
                      />
                    )
                ))
            }
            {provided.placeholder}
            <AddCalendarEvent visible={addEventVisible} date={date} />
          </WorkoutsWrapper>
        )
      }}
    </Droppable>
  )
}
