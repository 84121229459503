import { durationToString, getDurationInSeconds } from './time'
import moment from 'moment'

function convertZoneThresholdToString(value: number, targetId: string): string {
  switch (targetId) {
    case ('1'):
      return `${value}`
    case ('2'):
      return `${value}`
    case ('3'):
      return durationToString(+value, 3)
    case ('5'):
      return durationToString(+value, 3)
    default:
      return ''
  }
}

function convertZoneThresholdToRaw(value: string, targetId: string): number {
  switch (targetId) {
    case ('1'):
      return +value
    case ('2'):
      return +value
    case ('3'):
      return getDurationInSeconds(value)
    case ('5'):
      return getDurationInSeconds(value)
    default:
      return 0
  }
}

function normalizeZones(values: any): Array<UserZone> {
  return Object.keys(values).map((key: string) => {
    const [sportId, targetId] = key.split(':')
    const threshold = convertZoneThresholdToRaw(values[key], targetId)
    return {
      threshold,
      sportId,
      targetId
    }
  })
}

function normalizeBirthDate(humanDate: string): string {
  const normalized = moment(humanDate, 'DD.MM.YYYY').format('YYYY-MM-DD')
  return normalized || ''
}

function separateName(name: string): { firstName: string, lastName?: string } {
  if (!name) {
    return { firstName: '' }
  }
  const [firstName, ...other] = name.split(' ')

  if (other.length === 0 || other[0] === '') {
    return { firstName }
  }

  return { firstName, lastName: other.join(' ') }
}

export {
  convertZoneThresholdToString,
  normalizeZones,
  normalizeBirthDate,
  separateName
}