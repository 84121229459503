import { useState } from 'react'
import { useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import { UseTransaction, useTransaction } from './useTransaction'
import { UsePaymentData, usePaymentData } from './usePaymentData'

const PAYMENT_PLANS = loader('../../apollo/queries/PaymentPlans.graphql')

export interface UseSubscription {
  plans: Array<PaymentPlan>
  loading: boolean
  transaction: UseTransaction
  paymentData: UsePaymentData
}

export function useSubscription(): UseSubscription {
  const transaction = useTransaction()
  const paymentData = usePaymentData()
  const [paymentPlans, setPaymentPlans] = useState<Array<PaymentPlan>>([])
  const [skip, setSkip] = useState(false)
  const { loading, error } = useQuery(PAYMENT_PLANS, {
    skip,
    onCompleted: (result) => setPaymentPlans([...result.paymentPlans].sort((a, b) => (
      a.price.value - b.price.value
    ))),
    onError: (error) => console.error(error.message)
  })

  if (error) {
    setSkip(true)
  }

  return {
    plans: paymentPlans,
    loading,
    transaction,
    paymentData
  }
}
