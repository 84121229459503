import sub from "date-fns/sub"
import startOfWeek from "date-fns/startOfWeek"
import add from "date-fns/add"
import endOfWeek from "date-fns/endOfWeek"

function getCalendarStartDate() {
  const monthAgoExact = sub(new Date(), { months: 1 })
  return startOfWeek(monthAgoExact, { weekStartsOn: 1 })
}

function getCalendarEndDate() {
  const monthAheadExact = add(new Date(), { months: 1 })
  return endOfWeek(monthAheadExact, { weekStartsOn: 1 })
}

export {
  getCalendarStartDate,
  getCalendarEndDate
}