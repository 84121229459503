import { Livechat } from '../models/Livechat'

export interface useLivechatOptions {
  initState?: 'minimize' | 'maximize'
  initData?: any,
  customVariables?: any,
}

export function useLivechat() {
  const license = `${process.env.REACT_APP_LIVECHAT_APP_ID!}`
  return (options?: useLivechatOptions) => {
    const livechat = new Livechat({
      appId: license,
      afterLoad
    })

    function afterLoad() {
      if (options?.initState) {
        livechat.call(options?.initState)
      }
      if (options?.initData) {
        for (const property in options.initData) {
          if (options.initData.hasOwnProperty(property)) {
            livechat.call(`set_customer_${property}`, `${options.initData[property]}`)
          }
        }
      }
      if (options?.customVariables) {
        livechat.call('set_session_variables', options?.customVariables)
      }
    }
    return livechat
  }
}
