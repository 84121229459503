import React from 'react'
import styled from 'styled-components'

type TopLineProps = {
  isOpen: boolean
  onClose: () => void
  children: any
  backgroundColor?: string
  borderColor?: string
}

type WrapperProps = {
  open: boolean
  backgroundColor?: string
  borderColor?: string
}

const Wrapper = styled.div<WrapperProps>`
  position: absolute;
  margin-top: ${props => props.open ? '0' : '-100px'};
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 18px;
  height: 34px;
  border-width: ${({borderColor}) => borderColor ? '1px' : null};
  border-style: ${({borderColor}) => borderColor ? 'solid' : null};
  border-color: ${({borderColor}) => borderColor ? borderColor : null};
  border-radius: 3px;
  background-color: ${({backgroundColor}) => backgroundColor ? backgroundColor : 'transparent'};
  transition: all ease-in-out 400ms;
  z-index: 2000;
`

const Content = styled.div`
  flex: 1 1 auto;
`

const Closed = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #FFFFFF;
  font-size: 1.25rem;
  padding: 0 0 0 12px;
`

const TopLine = ({ isOpen, onClose, children, backgroundColor, borderColor }: TopLineProps) => {
  return (
    <Wrapper open={isOpen} data-modal='shift' backgroundColor={backgroundColor} borderColor={borderColor}>
      <Content>
        {children}
      </Content>
      <Closed onClick={onClose}>&times;</Closed>
    </Wrapper>
  )
}

export default TopLine
