import React, { useState } from 'react'
import { Title, Paragraph, DatePicker } from '../../UI/Form'
import styled from 'styled-components'
import Button from '../../UI/Button/Button'
import { Form, Formik } from 'formik'
import ru from 'date-fns/locale/ru'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import ym from 'react-yandex-metrika'
import ymGoals from '../../../analytics/ymGoals'
import { PlanBuilderStepProps } from './types'
import { usePlanBuilder } from '../../../services/PlanBuilder'

type Step1Values = {
  startDate: string
}

const LocalParagraph = styled(Paragraph)`
  padding: 15px 0 5px;
  & span {
    display: inline-block;
    font-weight: 500;
    margin-left: 5px;
  }
`

const Buttons = styled.div`
  margin-top: 25px;
  display: flex;
`

const Step1 = ({ title, tabSwitcher }: PlanBuilderStepProps) => {
  const today = new Date()
  const { store } = usePlanBuilder()
  const initStartDate = parseISO(getInitStartDate())
  const [startDate, setStartDate] = useState<Date>(initStartDate)

  function getInitStartDate() {
    const trainingStartDate = store.value.trainingStartDate
    if (typeof trainingStartDate === 'string') {
      return trainingStartDate
    }
    return format(today, 'yyyy-MM-dd')
  }

  function formSubmit(values: Step1Values, { setSubmitting }: any): void {
    setSubmitting(true)
    store.update({ trainingStartDate: values.startDate })
    setSubmitting(false)
    ym('reachGoal', ymGoals.planbuilder.firstStepSuccess)
    tabSwitcher('2')
  }

  return (
    <Formik
      initialValues={{
        startDate: format(startDate, 'yyyy-MM-dd')
      }}
      onSubmit={formSubmit}
    >
      {(props) => {
        function dateChange(date: Date) {
          setStartDate(date)
          const humanizeDate = format(date, 'yyyy-MM-dd')
          props.setFieldValue('startDate', humanizeDate)
        }
        return (
          <Form>
            <Title>{title}</Title>
            <Paragraph>Выберите день когда готовы приступить к тренировкам.</Paragraph>
            <LocalParagraph>Начинаем тренироваться: <span>{format(startDate, 'EEEE, d MMMM yyyy', {locale: ru})}</span></LocalParagraph>
            <DatePicker
              selected={startDate}
              onChange={dateChange}
              minDate={today}
              inline
              fixedHeight
              showDisabledMonthNavigation
              disabledKeyboardNavigation
            />
            <Buttons>
              <Button type='submit' solid>Продолжить</Button>
            </Buttons>
          </Form>
        )
      }}
    </Formik>

  )
}

export default Step1
