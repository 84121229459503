import { TrainingProgram } from './TrainingProgram'
import isSameDayFns from 'date-fns/isSameDay'
import { getNearDays } from '../../../utils/planbuilder'
import parseISO from 'date-fns/parseISO'

export class TriathlonProgram extends TrainingProgram {
  private id: string = '1'

  public getId() {
    return this.id
  }

  public getWorkoutTitle(workout: WorkoutSchedule): string {
    return workout.sport.title
  }

  public isDropDisabled(
    dropState: DropState,
    schedule: Array<TempScheduleInput> | undefined,
    dayWorkouts: Array<TempScheduleInput>,
    dayISODate: string
  ): boolean {
    if (dropState.sourceDate) {
      const toDayDate = parseISO(dayISODate)
      const nearDays = getNearDays(toDayDate)

      const isSameDay = isSameDayFns(dropState.sourceDate, toDayDate)

      if (isSameDay) {
        return false
      }

      if (dayWorkouts.length > 1) {
        return true
      }

      const hasSameTypeWorkouts = dayWorkouts.some((workout) => (
        +workout?.sport.id === dropState.droppingWorkoutSportId
      ))

      if (hasSameTypeWorkouts) {
        return true
      }

      const hasSameTypeWorkoutsInSibling = schedule?.filter(({ date }) => (
        dropState.sourceDate !== null && !isSameDayFns(parseISO(date), dropState.sourceDate)
      ))
        .filter(({ date }) => nearDays.some((nearDay) => isSameDayFns(parseISO(date), nearDay)))
        .some(({ sport }) => +sport.id === dropState.droppingWorkoutSportId)

      if (hasSameTypeWorkoutsInSibling) {
        return true
      }

      const isNearFromSource = nearDays.some((siblingDate: Date) => (
        dropState.sourceDate !== null && isSameDayFns(siblingDate, dropState.sourceDate)
      ))

      if (isNearFromSource) {
        return false
      }
    }
    return false
  }

  getScheduleText(): string {
    return 'Перетаскивайте тренировки и настройте план согласно вашим предпочтениям. Рекомендуем оставить 1-2 дня отдыха для восстановления.'
  }
}
