import React, {useEffect, useState, memo} from 'react';
import {useHistory} from 'react-router-dom';
import {gql, useApolloClient, useQuery} from '@apollo/client';
import format from 'date-fns/format';

import {CalendarOverlayProps} from './types';
import {GET_CALENDAR} from './gql';
import {Overlay, OpenButton} from './styled';
import {routes} from '../../../routeConfig';

export const CalendarOverlay = memo(function CalendarOverlay({ initStart, initEnd }: CalendarOverlayProps) {
  const history = useHistory()
  const [isVisible, setVisibility] = useState(false)
  const [skip, setSkip] = useState(false)
  const client = useApolloClient()

  const { error, data } = useQuery(GET_CALENDAR, {
    variables: {
      dateMin: format(initStart, 'yyyy-MM-dd'),
      dateMax: format(initEnd, 'yyyy-MM-dd')
    },
    skip,
  })

  const user = client.readFragment({
    id: 'User:{}',
    fragment: gql`
        fragment PaymentSubscription on User {
            paymentSubscription {
                status
            }
        }
    `
  })

  useEffect(() => {
    if (data) {
      const isShow = data.calendar.programms.length === 0 && data.calendar.workouts.length === 0
      setVisibility(isShow)
    }
  }, [data])

  if (error) {
    setSkip(true)
  }

  function openPlanBuilder() {
    history.push(routes.planbuilder)
  }

  function openSubscription() {
    history.push({
      pathname: routes.subscription,
      search: '?tab=activate'}
    )
  }

  return isVisible ? (
    <Overlay>
      {user?.paymentSubscription?.status === 'ENDED'
        ? <OpenButton type='button' onClick={openSubscription} solid>Активировать подписку</OpenButton>
        : <OpenButton type='button' onClick={openPlanBuilder} solid>Создать план</OpenButton>
      }
    </Overlay>
  ) : null
})
