import React, {memo, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useQuery} from '@apollo/client';

import {routes} from '../../../../routeConfig';
import Loader from 'react-loader-spinner';
import Button from '../../../UI/Button/Button';

import {GET_PAYMENT_TRANSACTION} from './gql';
import {StatusTransactionProps} from './types';
import {Wrapper, Wrap, Pending} from './styled';

const StatusTransaction: React.FC<StatusTransactionProps> = memo(function({ id, resetTransaction }) {
  const history = useHistory()
  const [transaction, setTransaction] = useState<PaymentTransaction>()
  const [statusTimeout, setStatusTimeout] = useState(false)
  const [pollingTimeout, setPollingTimeout] = useState<NodeJS.Timeout>()

  const {client, error, stopPolling } = useQuery(GET_PAYMENT_TRANSACTION, {
    variables: {
      id
    },
    pollInterval: 2000,
    onCompleted: (result) => setTransaction(result.paymentTransaction),
    onError: (error) => console.error(error.message),
    notifyOnNetworkStatusChange: true
  })

  useEffect(() => {
    const pollingTimeout = setTimeout(() => {
      stopPolling()
      setStatusTimeout(true)
    }, 300000)
    setPollingTimeout(pollingTimeout)
    return () => clearTimeout(pollingTimeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (transaction && transaction?.status.value !== 'pending') {
      stopPolling()
      client.resetStore()
      if (pollingTimeout) {
          clearTimeout(pollingTimeout)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction?.status.value])

  if (error) {
    stopPolling()
    return (
      <Wrapper>
        <Wrap>
          <p><span style={{display: 'inline-block', color: 'red'}}>{error.message}</span> &#128577;</p>
          <Button onClick={resetTransaction}>Попробовать еще раз</Button>
        </Wrap>
      </Wrapper>
    )
  }

  if (!transaction) {
    return null
  }

  if (statusTimeout) {
    return (
      <Wrapper>
        <Wrap>
          <p><span style={{display: 'inline-block', color: 'red'}}>Время ожидания истекло</span> &#128577;</p>
          <Button onClick={resetTransaction}>Попробовать еще раз</Button>
        </Wrap>
      </Wrapper>
    )
  }

  function renderStatus() {
    if (transaction?.status.value === 'pending') {
      return (
        <Pending>
          <Loader type='RevolvingDot' width={100} height={100} />
          <span>Ожидание оплаты</span>
        </Pending>
      )
    }

    if (transaction?.status.value === 'succeeded') {
      return (
        <Wrap>
          <p><span style={{display: 'inline-block', color: 'green'}}>Оплата прошла успешно</span> &#128578;</p>
          <Button onClick={() => history.replace(routes.subscription)}>Закрыть</Button>
        </Wrap>
      )
    }

    if (transaction?.status.value === 'unknown' || transaction?.status.value === 'canceled') {
      return (
        <Wrap>
          <p><span style={{display: 'inline-block', color: 'red'}}>Оплата не прошла</span> &#128577;</p>
          <Button onClick={() => history.replace({
            pathname: routes.subscription,
            search: '&tab=activate'
          })}>Закрыть</Button>
        </Wrap>
      )
    }
  }

  return (
    <Wrapper>
      {renderStatus()}
    </Wrapper>
  )
})

export default StatusTransaction
