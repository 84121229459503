import React, { useEffect, useState } from 'react'
import styles from './Workout.module.css'
import styled from 'styled-components'
import WorkoutChart from '../Rechart/WorkoutChart/WorkoutChart'
import ru from 'date-fns/locale/ru'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
import Tabs from '../Tabs/Tabs'
import {CalendarWorkout} from '../CalendarWorkout'
import WorkoutDescription from './WorkoutDescription/WorkoutDescription'
import Export from './Export/Export'
import { makeWorkoutTabsData } from '../../utils/workout'
import TestChooser from './TestChooser/TestChooser'

const WorkoutWrapper = styled.div`
  width: 910px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 40px;
`

const Title = styled.h2`
  margin: 8px 0 15px;
`

const Date = styled.span`
  text-transform: uppercase;
  font-size: .875rem;
`

const Alternatives = styled.div`
  width: 220px;
  float: right;
  padding: 10px 0 15px 20px;
  & > h2 {
    color: #828282;
    font-size: .875rem;
    font-weight: 500;
    margin: 0;
    padding: 4px 0 15px;
    text-transform: uppercase;
  }
  & h3 {
    color: #444444;
    font-weight: normal;
    font-size: .75rem;
    margin: 0;
    padding: 0 0 10px;
  }
`

const AlternativeWorkoutWrapper = styled.div`
  padding-bottom: 8px;
`

const Content = styled.div`
  padding: 8px 0;
`

const Video = styled.div`
  width: calc(100% + 40px);
  height: 400px;
  margin-left: -20px;
  padding: 15px 0;
`

const Workout = (props: WorkoutDay) => {
  const [workout, setWorkout] = useState(props)
  const [currentVisibleTarget, setVisibleTarget] = useState('')
  const [showChooser, setShowChooser] = useState(true)
  const [workoutIdShow, setWorkoutIdShow] = useState(props.id)

  const { title, descriptionHtml, descriptionData, totalTimePlanned, date, steps, sport, alternatives, download } = workout

  const tabsData: any = makeWorkoutTabsData(steps)
  const [firstTarget] = tabsData ? Object.keys(tabsData) : []

  useEffect(() => {
    if (workoutIdShow === props.id) {
      setWorkout(props)
    } else {
      if (workoutIdShow !== workout.id) {
        const alternativeWorkout = alternatives
          .filter(({ workout }) => workout)
          .find(({ workout}) => workout.id === workoutIdShow)
        if (alternativeWorkout) {
          setWorkout((prevWorkout => ({
            ...prevWorkout,
            title: alternativeWorkout.workout.title,
            descriptionData: alternativeWorkout.workout.descriptionData,
            totalTimePlanned: alternativeWorkout.workout.totalTimePlanned,
            steps: alternativeWorkout.workout.steps,
            sport: alternativeWorkout.workout.sport,
            download: alternativeWorkout.workout.download
          })))
        }
      }
    }
    setVisibleTarget(firstTarget)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workoutIdShow, firstTarget])

  function changeVisibleTarget(targetName: string) {
    setVisibleTarget(targetName)
  }

  function handleChooserClick(id: number) {

    setWorkoutIdShow(id)
    setShowChooser(false)
  }

  return (
    <>
      {
        alternatives.length !== 0 &&
        showChooser &&
        <TestChooser handleClick={handleChooserClick} alternatives={alternatives} onClose={() => setShowChooser(false)}/>
      }
      <WorkoutWrapper>
        <Header>
          <div className={styles.left}>
            <Date>{sport?.short} — {format(parseISO(date), 'dd MMMM yyyy', {locale: ru})}</Date>
            <Title>{title} {totalTimePlanned ? `– ${totalTimePlanned}` : null}</Title>
          </div>
          <div className={styles.right}>
            {download && <Export download={download} />}
          </div>
        </Header>
        {descriptionHtml
          ? <Video dangerouslySetInnerHTML={{__html: `${descriptionHtml}`}} />
          : <WorkoutChart id={workoutIdShow} steps={steps} sport={sport} chartTarget={firstTarget}/>}
        <Content>
          {alternatives?.length !== 0 && (
            <Alternatives>
              <h2>Варианты тренировки</h2>
              {alternatives.map((alternative) => {
                if (!alternative.workout) {
                  return (
                    <AlternativeWorkoutWrapper key={alternative.title}>
                      <h3>{alternative.title}</h3>
                      <div onClick={() => setWorkoutIdShow(props.id)}>
                        <CalendarWorkout
                          workout={props}
                          cursorStyle='pointer'
                          borderStyle={props.id === workoutIdShow ? '1px solid #2F80ED' : '1px solid #DADADA'}
                        />
                      </div>
                    </AlternativeWorkoutWrapper>
                  )
                }
                return (
                  <AlternativeWorkoutWrapper key={alternative.title}>
                    <h3>{alternative.title}</h3>
                    <div onClick={() => setWorkoutIdShow(alternative.workout.id)}>
                      <CalendarWorkout
                        workout={alternative.workout}
                        cursorStyle='pointer'
                        borderStyle={alternative.workout.id === workoutIdShow ? '1px solid #2F80ED' : '1px solid #DADADA'}
                      />
                    </div>
                  </AlternativeWorkoutWrapper>
                )
              })}
            </Alternatives>
          )}
          {tabsData && <Tabs tabsData={tabsData} sportSlug={currentVisibleTarget} changeVisibleTarget={changeVisibleTarget}/>}
          {descriptionData && <WorkoutDescription data={descriptionData} sport={sport}/>}
        </Content>
      </WorkoutWrapper>
    </>
  )
}

export default React.memo(Workout)
