import styled from 'styled-components';
import { isMobile } from "react-device-detect";
import {WorkoutsWrapper} from '../styled';

export const AddEventPlus = styled.button`
  cursor: pointer;
  font-size: 20px;
  color: #D8D8D8;
  background-color: transparent;
  border: 1px solid #D8D8D8;

  ${isMobile ? `
  margin-top: auto;
  ` : `
  ${WorkoutsWrapper}:not(:hover) & {
    display: none;
  }
  `}

  &:hover {
    color: #A2A2A2;
    border-color: #A2A2A2;
  }
  &:active {
    transform: scale(.98);
  }
`
