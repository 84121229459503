import { useState } from 'react'
import { gql, useQuery } from '@apollo/client'

type useTargetsProps = {
  mapTo?: 'id' | 'slug' | 'title' | 'units'
  locale?: 'ru' | 'en'
}

type UseTargetsResult = [Array<Target> | undefined, boolean]

const GET_ALL_TARGETS = gql`
    query GetAllTargets {
        targets {
            id
            title
            titleDesc
            descriptionTarget
            units
            sensorTitle
            slug
            order
            showSurvey
            isPace
            descriptionSurvey
            descriptionHelp
            defaultThreshold
        }
    }
`

export function useTargets(options?: useTargetsProps): UseTargetsResult {
  const [targets, setTargets] = useState()
  const [skip, setSkip] = useState(false)

  const {loading, error} = useQuery(GET_ALL_TARGETS, {
    variables: {
      locale: options?.locale || 'ru'
    },
    skip,
    onCompleted: (data) => setTargets(data.targets),
    onError: (error) => console.error(error.message)
  })

  if (error) {
    setSkip(true)
  }

  if (targets && options?.mapTo) {
    const mappingKey = options.mapTo
    // @ts-ignore
    const mappedTargets = targets.reduce((acc: any, target: Target) => {
      acc[target[mappingKey]] = {...target}
      delete acc[target[mappingKey]][mappingKey]
      return acc
    }, {})
    return [mappedTargets, loading]
  }

  return [targets, loading]
}