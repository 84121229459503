import add from 'date-fns/add';
import {GenerateCalendarDaysResult} from '../../infinity-loader/types';

const DAY_COUNT = 7

export function generateCalendarDays(start: Date): GenerateCalendarDaysResult {
  return new Array(DAY_COUNT)
    .fill('')
    .map((_, index) => {
      const date = add(start, {days: index})
      return {
        date
      }
    })
}
