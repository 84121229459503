import styled from 'styled-components';
import Button from '../../UI/Button/Button';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, .7);
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const OpenButton = styled(Button)`
  background: #219653;
  border: none;
  &:hover {
    background: #1d8045;
  }
  &:disabled {
    background-color: #219653 !important;
  }
`
