import {useMemo} from 'react';
import {useReactiveVar} from '@apollo/client';
import {userEvents} from '../apollo/cache';
import parseISO from 'date-fns/parseISO';
import sub from 'date-fns/sub';
import add from 'date-fns/add';
import formatFns from 'date-fns/format';

const DAYS_COUNT = 3

type UseRestrictDatesOptions = {
  format: 'date' | 'string'
}

export const useRestrictDates = ({format}: UseRestrictDatesOptions) => {
  const events = useReactiveVar(userEvents)
  return useMemo(() => {
    if (!events || events?.length === 0) {
      return []
    }

    let result: Date[] = []

    const eventsDates: Date[] = events.map((event) => parseISO(event.date))

    result.push(...eventsDates)

    eventsDates.forEach((date) => {
      const pastDates = new Array(DAYS_COUNT)
        .fill('')
        .map((value, index) => sub(date, {days: index + 1}))
      const futureDates = new Array(DAYS_COUNT)
        .fill('')
        .map((value, index) => add(date, {days: index + 1}))
      result.push(...pastDates, ...futureDates)
    })

    const uniqueDates = Array.from(new Set(result))

    if (format === 'string') {
      return uniqueDates.map((date) => formatFns(date, 'yyyy-MM-dd'))
    }

    return uniqueDates
  }, [events, format]);
}
