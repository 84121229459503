import styled from 'styled-components'

export const Col = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
`