import React, {memo, useEffect, useMemo} from 'react';
import {useLazyQuery} from '@apollo/client';
import {userCurrentProgram} from '../../../../apollo/cache';

import isTodayFns from 'date-fns/isToday';
import isFirstDayOfMonthFns from 'date-fns/isFirstDayOfMonth';
import format from 'date-fns/format';
import ru from 'date-fns/locale/ru';

import {GET_PROGRAMS_FOR_TODAY} from './graphql';
import {CalendarCellProps} from './types';
import {CalendarCellWrapper, Title} from './styled';
import {CellEvents} from '../../../CellEvents';
import {CellWorkouts} from '../../../CellWorkouts';

export const CalendarCell: React.FC<CalendarCellProps> = memo(function CalendarCell(props) {
  const {isDropDisabled, isLoading, date, workouts, events} = props
  const isFirstDayOfMonth = useMemo(() => isFirstDayOfMonthFns(date), [date]);
  const isToday = useMemo(() => isTodayFns(date), [date]);

  const [getProgramsForToday] = useLazyQuery(GET_PROGRAMS_FOR_TODAY, {
    onCompleted: (result) => {
      const { programms } = result.calendar
      if (programms.length !== 0) {
        const currentProgramm: TrainingProgram = programms[programms.length - 1]
        userCurrentProgram(currentProgramm.title)
      }
    }
  })

  useEffect(() => {
    if (isToday) {
      getProgramsForToday({
        variables: {
          dateMin: format(date, 'yyyy-MM-dd'),
          dateMax: format(date, 'yyyy-MM-dd')
        }
      })
    }
    // eslint-disable-next-line
  }, [])

  const isDayOff = useMemo(() => {
    if (events.length !== 0) {
      return false
    }
    return workouts.length === 0
  }, [workouts, events]);

  return (
    <CalendarCellWrapper
      isDropDisabled={isDropDisabled}
      isFirstDayOfMonth={isFirstDayOfMonth}
      data-today={isToday ? 'today' : null}
    >
      <Title isToday={isToday}>{format(date, 'd MMMM', {locale: ru})}</Title>
      <CellEvents events={events}/>
      <CellWorkouts
        isLoading={isLoading}
        isDropDisabled={isDropDisabled}
        isDayOff={isDayOff}
        date={date}
        workouts={workouts}
      />
    </CalendarCellWrapper>
  )
})
