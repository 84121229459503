import React, { useEffect } from 'react'
import styles from './CodeForm.module.css'
import { Form, Formik, FormikValues, useFormikContext } from 'formik'
import Button from '../../UI/Button/Button'
import { Link, useHistory } from 'react-router-dom'
import { Wrapper, Input } from '../../UI/Form'
import SmallLoader from '../../UI/SmallLoader/SmallLoader'
import CodeSchema from './validation'
import { loader } from 'graphql.macro'
import { useMutation } from '@apollo/client'
import { useSession } from '../../../hooks/auth/useSession'
import { routes } from '../../../routeConfig'

const CODE_AUTH = loader('../../../apollo/mutations/CodeAuth.graphql')

type CodeFormProps = {
  formHandler: () => void
}

const AutoSubmitCode = () => {
  const { submitForm } = useFormikContext()
  useEffect(() => {
    submitForm()
  }, [submitForm])
  return null
}

const CodeForm = ({ formHandler }: CodeFormProps) => {
  const [codeAuth] = useMutation(CODE_AUTH)
  const history = useHistory()
  const session = useSession()
  const urlParams = new URLSearchParams(history.location.search)
  const code = urlParams.get('code')

  function formSubmit(values: FormikValues, { setSubmitting, setFieldError }: any):void {
    const { code } = values
    const upperCode = code.toUpperCase()
    setSubmitting(true)

    codeAuth({
      variables: {
        code: upperCode
      }
    })
      .then(({ data: { codeAuth: { action, token, refreshToken } } }) => {
        setSubmitting(false)
        if (action === 'reset_password') {
          formHandler()
          session.login(token, refreshToken)
        } else {
          history.push(routes.calendar)
        }
      })
      .catch((error) => {
        setSubmitting(false)
        if (error.message === 'invalid_code') {
          setFieldError('code', 'Неправильный код. Проверьте еще раз или сбросьте заново.')
        }
      })
  }

  return (
    <Formik
      initialValues={{
        code: code ? code : '',
      }}
      onSubmit={formSubmit}
      validationSchema={CodeSchema}
    >
      {(props) => {
        return (
          <Wrapper>
            <Form>
              <h2 className={styles.title}>Введите код полученный из письма</h2>
              <div className={styles.row}>
                <div className={styles.col}>
                  <Input mask='******' name='code' placeholder='Код' formik={props} className={styles.codeField} withError/>
                  { code && <AutoSubmitCode />}
                </div>
              </div>
              <Button
                className={styles.submit}
                type='submit'
                solid
                disabled={props.isSubmitting}
              >
                {props.isSubmitting ? <SmallLoader /> : 'Отправить код'}
              </Button>
              <div className={styles.underline}>
                <Link
                  to={routes.signIn}
                  className={styles['underline__link']}
                >
                  Войти
                </Link>
                <Link
                  to={routes.resetPassword}
                  className={styles['underline__link']}
                >
                  Забыли пароль?
                </Link>
              </div>
            </Form>
          </Wrapper>
        )
      }}
    </Formik>
  )
}

export default CodeForm
