import React from 'react'
import styled from 'styled-components'
import Loader from 'react-loader-spinner'

type ButtonProps = {
  children: JSX.Element | string
  href?: string
  onClick: () => void
  loading?: boolean
  target?: string
}

const Wrapper = styled.a<{loading?: boolean}>`
  min-width: 185px;
  display: flex;
  cursor: ${({ loading }) => loading ? 'not-allowed' : 'pointer'};
  justify-content: center;
  align-items: flex-end;
  color: #FFF;
  background: #0070f0;
  padding: 12px 20px;
  font-weight: 500;
  font-size: 1rem;
  border: none;
  &:hover {
    text-decoration: none;
    filter: brightness(1.1)
  }
  &:active {
    filter: ${({ loading }) => loading ? null : 'brightness(.95)'};
  }
`

const Button = ({ children, href, onClick, loading, target = '_self'}: ButtonProps) => {
  function handleClick() {
    if (!loading) {
      onClick()
    }
  }

  return (
    <Wrapper href={href} onClick={handleClick} loading={loading} target={target}>
      {loading
        ? <Loader type='ThreeDots' width={30} height={12} color='#000000'/>
        : children
      }
    </Wrapper>
  )
}

export default Button
