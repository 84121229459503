import React, { SyntheticEvent, useState } from 'react'
import { Form, Formik, FormikValues } from 'formik'
//import styles from '../Settings.module.css'
import { loader } from 'graphql.macro'
import { useMutation } from '@apollo/client'
import { Col, Row, Input, } from '../../UI/Form'
import Skeleton from 'react-loading-skeleton'

import {  Paragraph, } from '../../UI/Modal'

import {formatDuration, format, parseISO} from 'date-fns'


import ru from 'date-fns/locale/ru'

import styled from 'styled-components';
import Button from '../../UI/Button/Button';


const APPLY_CERTIFICATE_MUTATION = loader('../../../apollo/mutations/payment/ApplyCertificate.graphql')
const Title = styled.h2`
  font-size: .875rem;
  font-weight: 500;
  color: #4F4F4F;
`
const ActivateCertificateButton = styled(Button)<{activated?: boolean}>`
  padding: 0 20px;
  background: ${({activated}) => activated ? '#219653' : '#0070f0'};
  border: none;
  color: ${({activated}) => activated ? '#FFFFFF' : '#FFFFFF'};
  min-width: 115px;
  &:hover {
    cursor: ${({activated}) => activated ? 'default' : 'pointer'};
    background: ${({activated}) => activated ? '#219653' : '#0070f0'};
    color: ${({activated}) => activated ? '#FFFFFF' : '#FFFFFF'};
    filter: brightness(1.1);
  }
  &:active {
    transform: ${({activated}) => activated ? 'none' : 'translateY(1px)'};
  }
  &:disabled {
    color: #FFFFFF;
    background: #219653 !important;
    &:hover {
      cursor: default;
    }
  }
`
const Subtitle = styled.h3`
  color: #828282;
  font-weight: 500;
  font-size: .75rem;
  text-transform: uppercase;
  padding: 0 0 5px;
  margin: 0;
`

type CertificateFormProps = {
  user?: User
  switchTab: (event: SyntheticEvent<Element, Event> | string) => void
}


const CertificateForm = ({ switchTab, user  }: CertificateFormProps) => {
  const [applyCert] = useMutation(APPLY_CERTIFICATE_MUTATION)

  
  const [certApplied, setCertApplied] = useState(false)
  
  const [monthsApplied, setMonthsApplied] = useState(1)  

  console.log(user)
  if (!user) {
    return <CertSkeleton />
  }  
  const { paymentSubscription } = user

  function handleSubmit(values: FormikValues, { setFieldError, setSubmitting  }: any) {
    const certificateCode = values.certCode

    applyCert({
      variables: {
        certcode: certificateCode
      }
    })
    .then((res) => {

      const benefit = res.data.paymentApplyCertificate.appliedBenefit
      
      setCertApplied(true)
      setMonthsApplied(benefit)
      

    })
    .catch(error => {
      if (error.message === 'invalid_certcode') {
        setFieldError('certCode', 'Некорректный код или сертификат уже был применен')
        setSubmitting(false)
      }
    })
  }
  // dateFNS to convert days to human readable https://date-fns.org/
  return (
    certApplied ? (
      <Row>
        <Col>
          <Title>Сертификат на {formatDuration({months: monthsApplied}, {format: ['months'], locale: ru})} активирован</Title>
          <Row>
            <Col>
              <Paragraph>Поздравляем, вы успешно активировали подписку.</Paragraph>
            </Col>
          </Row>
          <Row>
            <Col>    
              <Subtitle>Текущая подписка</Subtitle>     
              <Paragraph>{paymentSubscription ? (paymentSubscription?.plan.title) : ('')}</Paragraph>
            </Col>
          </Row>
          <Row>
            <Col>    
              <Subtitle>Активна до</Subtitle>                   
              <Paragraph>{ paymentSubscription ? (format(parseISO(paymentSubscription.nextPaymentDate), 'EEEE, dd MMM yyyy', {locale: ru})) : ('')}</Paragraph>
            </Col>
          </Row>
        </Col>        
      </Row>


    ) : (
      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          certificateCode: ''
        }}
        
      >
        {props => {
          return (          
            <Form>
              <Row>
                <Col>
                  <Title>Активировать подарочный сертификат</Title>

                </Col>
              </Row>            
              <Row>
                <Col>
                  <Input name='certCode' placeholder='Код сертификата' formik={props} withError/>
                </Col>
                <Col>                
                  <ActivateCertificateButton  disabled={(props.isSubmitting )}  type='submit' >{(props.isSubmitting ? ('Применяем..') : ('Применить'))}</ActivateCertificateButton>
                </Col>
              </Row>
              <Row>
                <Col>                  
                  <Paragraph>После применения сертификата у вас появится полный доступ к платформе. Если у вас уже есть активная подписка она автоматически продлится на срок действия сертификата.</Paragraph>
                  <Paragraph>Приобрести сертификат на подписку для себя или в подарок можно на <a rel='noreferrer' target='_blank' href="https://trisystems.ru/certificates">https://trisystems.ru/certificates</a></Paragraph>                  
                  <Paragraph>Если у вас появятся вопросы по активации сертификата напишите нам в <a rel='noreferrer' target='_blank' href="https://trisystems.atlassian.net/servicedesk/customer/portals">центр поддержки</a></Paragraph>
                </Col>
              </Row>             
            </Form>
          )
        }}
      </Formik>
    )
  )
}

export default CertificateForm

const CertSkeleton = () => {
  return (
    <>
      <Row>
        <Col>
          <Row>
            <Skeleton height={50} />
          </Row>
          <Row>
            <Skeleton height={50} />
          </Row>
          <Row>
            <Skeleton height={50} />
          </Row>
        </Col>
        <Col>
          <Row>
            <Skeleton height={200} />
          </Row>
        </Col>
      </Row>
    </>
  )
}
