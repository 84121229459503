import {gql} from '@apollo/client';

export const GET_CALENDAR = gql`
    query Calendar($dateMin: Date, $dateMax: Date) {
        calendar(dateMin: $dateMin, dateMax: $dateMax) {
            programms {
                id
            }
            workouts {
                id
            }
        }
    }
`
