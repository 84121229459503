import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  height: 100%;
  padding-bottom: 40px;
`

export const StyledSubWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: inherit;
`

export const StyledLeftWrapper = styled(StyledSubWrapper)`
  padding-right: 20px;
`

export const StyledRightWrapper = styled(StyledSubWrapper)`
  padding: 10px 20px 0;
`

export const Title = styled.h2`
  font-size: .875rem;
  font-weight: 500;
  color: #4F4F4F;
`


