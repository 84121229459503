export const routes = {
  calendar: '/',
  signIn: '/login',
  signUp: '/register',
  resetPassword: '/reset',
  survey: '/survey',
  code: '/code',
  settings: '/settings',
  planbuilder: '/planbuilder',
  subscription: '/subscription',
  subscriptionConfirm: '/subscription/confirm',
  tos: '/tos',
  privacy: '/privacy',
  relogin: '/login/as',
  maintenance: '/maintenance'
}
