import React from 'react'
import styled from 'styled-components'
import PlanBuilderWorkout from '../PlanBuilderWorkout/PlanBuilderWorkout'
import format from "date-fns/format"
import parseISO from "date-fns/parseISO"
import ru from "date-fns/locale/ru"
import { usePlanBuilder } from '../../../services/PlanBuilder'

type PlanBuilderDayProps = {
  date: string
  workouts: Array<WorkoutSchedule>
  events?: Array<UserEvent>
}

const Day = styled.div`
  width: 13.8%;
  border: 1px solid #ECE9E9;
  padding: 10px 8px 8px;
  border-radius: 3px;
`

const Title = styled.div`
  color: #909090;
  font-size: .6250rem;
  text-transform: uppercase;
  padding-bottom: 8px;
`

const Workouts = styled.div`
  min-height: 84%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const NoWorkouts = styled.p`
  color: #575757;
  font-size: .6850rem;
  font-weight: 500;
  padding: 0 1px;
`

const PlanBuilderDay = ({ workouts, events, date: dayDate }: PlanBuilderDayProps) => {
  const planbuilder = usePlanBuilder()
  const humanizeDate = format(parseISO(dayDate), 'd MMM', {locale: ru})
  const sortWorkouts = workouts.sort((workout1, workout2) => (
    workout1.order < workout2.order ? -1 : 1
  ))
  const event = events?.find(({date}) => date === dayDate)

  function renderDayContent() {
    if (event) {
      return (
        <PlanBuilderWorkout
          key={dayDate + event.title}
          title='Событие'
          content={event && event.title}
          slug='event'
        />
      )
    }

    if (workouts.length === 0) {
      return <NoWorkouts>День отдыха</NoWorkouts>
    }

    return sortWorkouts.map((workout) => {
      const { sport, plot, date, order, title } = workout
      const workoutTitle = !!title
        ? title
        : planbuilder.getWorkoutTitle(workout)
      return (
        <PlanBuilderWorkout
          key={date + order + sport.id}
          title={workoutTitle}
          slug={sport.slug}
          plot={plot}
        />
      )
    })
  }

  return (
    <Day>
      <Title>{humanizeDate}</Title>
      <Workouts>
        {renderDayContent()}
      </Workouts>
    </Day>
  )
}

export default PlanBuilderDay
