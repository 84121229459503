import React from 'react'
import styled from 'styled-components'

type FormWrapperProps = {
  children: JSX.Element
}

const FormWrapperStyled = styled.div`
  width: 450px;
  display: flex;
  flex-direction: column;
  padding: 40px;
  background: #FFFFFF;
  box-shadow: 0 0 15px rgba(0, 0, 0 .3);
`

export const Wrapper = ({children}:FormWrapperProps) => {
  return (
    <FormWrapperStyled>
      {children}
    </FormWrapperStyled>
  )
}
