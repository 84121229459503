import React, { useEffect, useState } from 'react'
import { gql, useApolloClient } from '@apollo/client'
import parseISO from 'date-fns/parseISO'
import isAfter from 'date-fns/isAfter'
import differenceInDays from 'date-fns/differenceInDays'
import formatDuration from 'date-fns/formatDuration'
import TopLine from '../../UI/TopLine/TopLine'
import styled from 'styled-components'
import ru from 'date-fns/locale/ru'
import { Link, useLocation } from 'react-router-dom'
import { routes } from '../../../routeConfig'
import format from 'date-fns/format'

const DAY_DIFFERENCE = 7

const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const Text = styled.p`
  height: max-content;
  margin: 0;
  color: #FFFFFF;
  font-size: .75rem;
  line-height: 1.3;
`

const TextLink = styled(Link)`
  color: #FFFFFF;
  font-weight: bold;
  text-decoration: underline;
  margin-left: 5px;
  &:hover, &:active {
    text-decoration: none;
  }
  &:active {
    filter: brightness(.9);
  }
`

const ExpiringSubscription = () => {
  const [open, setOpen] = useState(false)
  const location = useLocation()
  const client = useApolloClient()
  const user: User | null = client.readFragment({
    id: 'User:{}',
    fragment: gql`
        fragment ExpiringSubscription on User {
            paymentSubscription {
                status
                nextPaymentDate
                plan {
                    title
                }
            }
        }
    `
  })

  useEffect(() => {
    if (differenceInDays(nextPaymentDate, today) < DAY_DIFFERENCE) {
      setOpen(true)
    } else {
      setOpen(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.paymentSubscription?.nextPaymentDate])

  if (!user?.paymentSubscription) {
    return null
  }

  const nextPaymentDate = parseISO(user.paymentSubscription.nextPaymentDate)
  const today = new Date()

  function handleClose() {
    setOpen(!open)
  }

  function getDayDifference(): number {
    if (!isAfter(nextPaymentDate, today)) {
      return -1
    }
    return differenceInDays(nextPaymentDate, today)
  }

  function renderText() {
    if (user?.paymentSubscription?.status === 'ACTIVE') {
      if (getDayDifference() < 0) {
        return (
          <Text>
            Не удалось продлить подписку. Мы попробуем повторить платеж в ближайшие пару дней.
          </Text>
        )
      }
      return null;
      /* Смотри https://trisystems.atlassian.net/browse/TSDEV-671
      const subscriptionTitle = user.paymentSubscription.plan?.title

      if (getDayDifference() === 0) {
        return (
          <Text>Ваша подписка истечет в ближайшие 24 часа. {subscriptionTitle && ` Текущий план "${subscriptionTitle}".`}</Text>
        )
      }
      return (
        <Text>
          Ваша подписка истечет через {formatDuration({days: getDayDifference()}, {locale: ru})}.
          {subscriptionTitle && ` Текущий план "${subscriptionTitle}".`}
        </Text>
      )
      */
    }

    if (user?.paymentSubscription?.status === 'EXPIRING') {
      if (getDayDifference() === 0) {
        return (
          <Text>
            Ваша подписка истечет в ближайшие 24 часа.
            <TextLink to={{
              pathname: routes.subscription,
              search: '?tab=activate',
              state: { background: location }
            }}>
              Активировать подписку
            </TextLink>.
          </Text>
        )
      }
      return (
        <Text>
          Ваша подписка закончится через {formatDuration({days: differenceInDays(nextPaymentDate, today)}, {locale: ru})}.
          <TextLink to={{
            pathname: routes.subscription,
            search: '?tab=activate',
            state: { background: location }
          }}>Активировать подписку
          </TextLink>.
        </Text>
      )
    }

    if (user?.paymentSubscription?.status === 'ENDED') {
      return (
        <Text>
          Ваша подписка истекла {format(parseISO(user?.paymentSubscription.nextPaymentDate), 'dd.MM.yyyy', {locale: ru})}.
          <TextLink to={{
            pathname: routes.subscription,
            search: '?tab=activate',
            state: { background: location }
          }}>Активировать подписку</TextLink>.
        </Text>
      )
    }
  }

  return (
    <TopLine isOpen={open} onClose={handleClose} backgroundColor='#DD4747'>
      <TextWrapper>
        {renderText()}
      </TextWrapper>
    </TopLine>
  )
}

export default ExpiringSubscription
