import * as Yup from 'yup'
import moment from 'moment'

export const SettingsAccountSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[а-яА-ЯёЁa-zA-Z]+$/, {
      message: 'Используйте только буквы',
    })
    .min(2, 'Слишком короткое имя')
    .max(50, 'Слишком длинное имя')
    .required('Пожалуйста, укажите ваше имя'),
  lastName: Yup.string()
    .matches(/^[а-яА-ЯёЁa-zA-Z]+$/, {
      message: 'Используйте только буквы',
    })
    .min(2, 'Слишком короткая фамилия')
    .max(50, 'Слишком длинная фамилия')
    .required('Пожалуйста, укажите вашу фамилию'),
  birthDate: Yup.string()
    .matches(/^\d{2}[.]\d{2}[.]\d{4}$/, {
      message: 'Неправильная дата рождения',
    })
    .test('Existing date', 'Такой даты не существует', (value) => (
      moment(value, 'DD.MM.YYYY').format('YYYY-MM-DD') !== 'Invalid date'
    )),
  email: Yup.string()
    .email('Введите корректный email')
    .required('Пожалуйста, укажите ваш email. Без него вы не сможете войти в систему'),
  height: Yup.number()
    .min(100, 'Слишком маленький рост. Введите ваш рост в сантиметрах')
    .max(250, 'Слишком большой рост. Введите ваш рост в сантиметрах'),
  weight: Yup.number()
    .min(30, 'Вес слишком маленький. Введите ваш вес в килограммах')
    .max(150, 'Вес слишком большой. Введите ваш вес в килограммах'),
  currentPassword: Yup.string(),
  newPassword: Yup.string()
    .when('currentPassword', {
      is: (val) => !!val,
      then: Yup.string().required('Введите новый пароль'),
      otherwise: Yup.string()
    })
    .min(6, 'Слишком короткий новый пароль. Введите не менее 6 символов'),
  repeatNewPassword: Yup.string()
    .when('newPassword', {
      is: (val) => !!val,
      then: Yup.string().required('Пожалуйста, укажите новый пароль еще раз'),
      otherwise: Yup.string()
    })
    .oneOf([Yup.ref('newPassword')], 'Новые пароли не совпадают'),
})
