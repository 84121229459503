import React, {useEffect, useMemo} from 'react';
import _debounce from 'lodash.debounce';
import {useReactiveVar} from '@apollo/client';
import {calendarStartDate, calendarEndDate} from '../../apollo/cache';

import {getCalendarStartDate, getCalendarEndDate} from '../../utils/calendar';
import {generateCalendarWeeks} from './generate-calendar-week';
import {calendarOnScroll} from './calendar-tools';
import {CalendarOverlay} from './overlay';
import {CalendarGrid} from './grid';

export const Calendar = () => {
  const calendarStart = useReactiveVar(calendarStartDate)
  const calendarEnd = useReactiveVar(calendarEndDate)

  const weeks = useMemo(() => generateCalendarWeeks({
    start: calendarStart,
    end: calendarEnd
  }), [calendarStart, calendarEnd]);

  useEffect(() => {
    const debounceOnScroll = _debounce(calendarOnScroll, 50)
    window.addEventListener('scroll', debounceOnScroll)
    return () => window.removeEventListener('scroll', debounceOnScroll)
  }, [])

  const initStartDate = useMemo(() => getCalendarStartDate(), []);
  const initEndDate = useMemo(() => getCalendarEndDate(), []);

  return (
    <>
      <CalendarOverlay initStart={initStartDate} initEnd={initEndDate}/>
      <CalendarGrid weeks={weeks} />
    </>
  )
}

