import React from 'react'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import { useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
import Loader from 'react-loader-spinner'

const SEND_CODE = loader('../../../../apollo/mutations/SendCode.graphql')

type Step1Props = {
  email?: string
  switchStep: (direction?: 'prev' | 'next') => void
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const Text = styled.p`
  height: max-content;
  margin: 0;
  color: #FFFFFF;
  font-size: .75rem;
`

const SendButton = styled.button`
  cursor: pointer;
  border: none;
  border-bottom: 1px solid;
  background: none;
  font-weight: bold;
  height: 20px;
  color: #FFFFFF;
  font-size: .75rem;
  margin-left: 10px;
  padding: 0;
  text-transform: uppercase;
  &:hover {
    border-bottom: 1px solid transparent;
  }
`

const Prompt = styled.div`
  cursor: help;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #D8D8D8;
  font-size: .6250rem;
  width: 17px;
  height: 17px;
  border: 1px solid #D8D8D8;
  border-radius: 50%;
  margin-left: 10px;
  &:hover {
    color: #FFFFFF;
    border-color: #FFFFFF;
  }
`

const Step1 = ({ email, switchStep }: Step1Props) => {
  const [sendCode, { loading }] = useMutation(SEND_CODE)

  function handleClick() {
    sendCode({
      variables: {
        email,
        action: 'confirm_email'
      }
    })
      .then(() => switchStep())
      .catch((error) => console.error(error.message))
  }

  return (
    <Wrapper>
      {loading
        ? <Loader type='ThreeDots' width={30} height={24} color='#FFFFFF'/>
        : (
          <>
            <Text>Пожалуйста, подтвердите ваш email:</Text>
            <SendButton onClick={handleClick}>Отправить повторно</SendButton>
            <Prompt data-tip data-for='prompt'>?</Prompt>
            <ReactTooltip id='prompt' place='bottom' effect='solid'>
              <p style={{textAlign: 'center'}}>
                Мы отправим вам письмо с ссылкой на почту <span style={{fontWeight: 700, padding: '4px 0'}}>{email}</span>
              </p>
            </ReactTooltip>
          </>
        )}
    </Wrapper>
  )
}

export default Step1