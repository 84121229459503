import React, { useState } from 'react'
import { Form, Formik, FormikValues } from 'formik'
import styles from '../Settings.module.css'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
import { SettingsAccountSchema } from './validation'
import { loader } from 'graphql.macro'
import { useMutation } from '@apollo/client'
import { Col, Row, Input, RadioTabGroup, } from '../../UI/Form'
import { normalizeBirthDate } from '../../../utils/user'
import Skeleton from 'react-loading-skeleton'
import ChangePasswordForm from './ChangePasswordForm'
import { StyledChangePasswordToggle } from './styled'

const UPDATE_USER = loader('../../../apollo/mutations/UpdateUser.graphql')

type AccountFormProps = {
  user: {
    firstName: string
    lastName: string
    birthDate: string
    email: string
    gender: string
    weight: number
    height: number
  }
  closeHandler: () => void
}

const AccountForm = ({ user, closeHandler }: AccountFormProps) => {
  const [changePasswordVisible, setChangePasswordVisible] = useState(false)
  const [updateUser] = useMutation(UPDATE_USER)

  if (!user) {
    return <AccountFormSkeleton />
  }

  const { firstName, lastName, birthDate, email, gender, weight, height } = user

  function handleSubmit(values: FormikValues, { setFieldError }: any) {
    const formatBirthDate = values.birthDate ? normalizeBirthDate(values.birthDate) : ''
    const newValues: any = {...values, birthDate: formatBirthDate}
    const clearedValues = Object.keys(newValues).reduce((acc: any,key) => {
      if (newValues[key] !== '' && key !== 'repeatNewPassword') {
        acc[key] = newValues[key]
      }
      return acc
    }, {})

    updateUser({
      variables: {
        user: clearedValues
      }
    })
      .then(() => closeHandler())
      .catch(error => {
        if (error.message === 'password_incorrect') {
          setFieldError('currentPassword', 'Неправильный текущий пароль')
        }
        if (error.message === 'new_passwords_are_not_the_same') {
          setFieldError('newPassword', 'Введите новый пароль')
        }
      })
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        firstName,
        lastName,
        email,
        birthDate: birthDate ? format(parseISO(birthDate), 'dd.MM.yyyy') : '',
        gender,
        weight,
        height,
        currentPassword: '',
        newPassword: '',
        repeatNewPassword: ''
      }}
      validationSchema={SettingsAccountSchema}
    >
      {props => {
        return (
          <Form>
            <Row>
              <Col>
                <Input name='firstName' label='Имя' placeholder='Ваше имя' formik={props} withError/>
              </Col>
              <Col>
                <Input name='lastName' label='Фамилия' placeholder='Ваша фамилия' formik={props} withError/>
              </Col>
            </Row>
            <Row>
              <Col>
                <Input name='email' label='Email' placeholder='Ваш email' formik={props} withError/>
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  label='День рождения'
                  mask='99.99.9999'
                  maskChar='_'
                  name='birthDate'
                  formik={props}
                  withError
                />
              </Col>
            </Row>
            <Row>
              <RadioTabGroup label='Пол' name='gender' values={[
                { id: 'male', value: 'm', labelValue: 'Мужской'},
                { id: 'female', value: 'f', labelValue: 'Женский'}
              ]}/>
            </Row>
            <Row>
              <Col>
                <Input label='Рост' units='см' mask='999' name='height' placeholder='Ваш рост' formik={props} withError/>
              </Col>
              <Col>
                <Input label='Вес' units='кг' mask='999' name='weight' placeholder='Ваш вес' formik={props} withError/>
              </Col>
            </Row>
            <Row style={{flexDirection: 'column', padding: '20px 0 10px'}}>
              <StyledChangePasswordToggle
                active={changePasswordVisible}
                type='button'
                onClick={() => setChangePasswordVisible(!changePasswordVisible)}
              >
                Изменить пароль
              </StyledChangePasswordToggle>
              {changePasswordVisible && <ChangePasswordForm formikProps={props} />}
            </Row>
            <Row>
              <button type='submit' className={styles.submit}>Сохранить и закрыть</button>
            </Row>
          </Form>
        )
      }}
    </Formik>
  )
}

export default AccountForm

const AccountFormSkeleton = () => {
  return (
    <>
      <Row>
        <Col>
          <Skeleton height={50} />
        </Col>
        <Col>
          <Skeleton height={50} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Skeleton height={50} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Skeleton height={50} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Skeleton height={50} />
        </Col>
        <Col>
          <Skeleton height={50} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Skeleton height={50} />
        </Col>
        <Col>
          <Skeleton height={50} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Skeleton height={50} style={{marginBottom: 10}} />
          <Skeleton height={50} style={{marginBottom: 10}} />
          <Skeleton height={50} style={{marginBottom: 10}} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Skeleton height={50} />
        </Col>
      </Row>
    </>
  )
}
