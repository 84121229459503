import React from 'react'
import isWithinInterval from "date-fns/isWithinInterval"
import parseISO from "date-fns/parseISO"
import differenceInWeeks from "date-fns/differenceInWeeks"
import startOfWeek from "date-fns/startOfWeek"
import endOfWeek from 'date-fns/endOfWeek'
import format from "date-fns/format"
import styled from 'styled-components'
import ru from "date-fns/locale/ru"
import { Paragraph } from '../../../UI/Form'
import PlanBuilderWeek from '../../PlanBuilderWeek/PlanBuilderWeek'
import { useSport } from '../../../../hooks/useSport'

const MIN_PERIOD_DURATION = 1

type PlanPreviewPeriodProps = {
  workouts: Array<PreviewWorkout>
  events: Array<UserEvent>
  title: string
  startDate: string
  endDate: string
}

const Wrapper = styled.div`
  padding: 15px 0;
`

const Title = styled.span`
  font-weight: 500;
  display: inline-block;
`

const Range = styled.span`
  margin-left: 5px;
  display: inline-block;
  position: relative;
  padding-left: 8px;
  &:before {
    content: '·';
    position: absolute;
    left: 0;
  }
`

const Separator = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  margin: 15px auto;
  border-top: 5px solid #E7E7E7;
  border-bottom: 5px solid #E7E7E7;
  border-radius: 5px;
  &:before {
    content: '';
    position: absolute;
    top: 6px;
    background: #E7E7E7;
    height: 5px;
    width: 100%;
    z-index: 10;
  }
  & span {
    padding: 2px 10px;
    font-size: .6850rem;
    z-index: 100;
    color: #C6C6C6;
    border-radius: 2px;
    background: #FFFFFF;
  }
`

const PlanPreviewPeriod = ({ workouts, title, startDate, endDate , events }: PlanPreviewPeriodProps) => {
  const sport = useSport()
  const duration = differenceInWeeks(parseISO(endDate), startOfWeek(parseISO(startDate))) || MIN_PERIOD_DURATION

  function getWeekWorkouts(startDate: string, endDate: string): Array<WorkoutSchedule> {
    return workouts
      .filter(({ date }) => (
        isWithinInterval(parseISO(date), { start: parseISO(startDate), end: parseISO(endDate) })
      ))
      .map((workout) => ({
        ...workout,
        sport: sport.getById(workout.sport.id),
        plot: workout.plot || [],
      }))
  }

  function renderComplexPeriod(firstWeek: Array<WorkoutSchedule>, lastWeek: Array<WorkoutSchedule>) {
    return (
      <>
        {firstWeek.length !== 0 && <PlanBuilderWeek workouts={firstWeek} events={events}/>}
        {duration > 2 && (
          <Separator>
            <span>{duration - 2} нед</span>
          </Separator>)
        }
        {lastWeek.length !== 0 && <PlanBuilderWeek workouts={lastWeek} events={events}/>}
      </>
    )
  }

  function renderPeriodWeeks() {
    if (duration === 1) {
      const periodWorkouts = getWeekWorkouts(startDate, endDate)
      return <PlanBuilderWeek workouts={periodWorkouts} events={events}/>
    }
    const [firstWeekStart, firstWeekEnd] = [startDate, format(endOfWeek(parseISO(startDate), {weekStartsOn: 1}), 'yyyy-MM-dd')]
    const [lastWeekStart, lastWeekEnd] = [format(startOfWeek(parseISO(endDate), {weekStartsOn: 1}), 'yyyy-MM-dd'), endDate]
    const firstWeekWorkouts = getWeekWorkouts(firstWeekStart, firstWeekEnd)
    const lastWeekWorkouts = getWeekWorkouts(lastWeekStart, lastWeekEnd)

    return renderComplexPeriod(firstWeekWorkouts, lastWeekWorkouts)
  }

  return (
    <Wrapper>
      <Paragraph>
        <Title>{`${title} (${duration} нед)`}</Title>
        <Range>
          {`
            ${format(parseISO(startDate), 'd MMM yyyy', {locale: ru})}
            –
            ${format(parseISO(endDate), 'd MMM yyyy', {locale: ru})}
          `}
        </Range>
      </Paragraph>
      {renderPeriodWeeks()}
    </Wrapper>
  )
}

export default PlanPreviewPeriod
