import React from 'react'
import ReactSelect, { Styles, ValueType } from 'react-select'
import { FormikProps } from 'formik'
import { Option } from 'react-select/src/filters'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'

type SelectProps = {
  name: string
  label?: string | {
    text: string
    tooltip?: string
  }
  placeholder?: string
  defaultValue?: any
  options: Array<any> | undefined
  onChange?: ((value: Option) => void) | any
  formik: FormikProps<any>
  withError?: boolean
  isLoading?: boolean
  isDisabled?: boolean
}

const customStyles: Styles<any, any> = {
  container: (base) => ({
    ...base,
    width: '100%',
    border: '1px solid #C4C4C4',
    borderRadius: 2,
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: '.875rem'
  }),
  singleValue: (base) => ({
    ...base,
    fontSize: '.875rem'
  }),
  control: (base, props) => ({
    ...base,
    border: 'none',
    borderRadius: 2,
    backgroundColor: props.hasValue ? '#FFFFFF' : '#F7F7F7',
    cursor: 'pointer'
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '0 10px',
  }),
  input: (base) => ({
    ...base,
    fontSize: '.875rem',
    margin: 0,
    height: 37,
    display: 'flex',
    alignItems: 'center',
  }),
  menu: (base) => ({
    ...base,
    marginTop: 1,
    borderRadius: '0 0 5px 5px',
    fontSize: '.875rem'
  })
}

const LabelStyled = styled.div`
  display: flex;
  align-items: flex-end;
  padding-bottom: 7px;
  height: 22px;
  & label {
    font-size: .75rem;
    color: #565656;
  }
`

const ErrorStyled = styled.div`
  font-size: .75rem;
  color: #CC0000;
  padding: 3px 2px;
  line-height: 1.3;
`

const Prompt = styled.div`
  cursor: help;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #D8D8D8;
  font-size: .5rem;
  width: 15px;
  height: 15px;
  border: 1px solid #D8D8D8;
  border-radius: 50%;
  margin-left: 6px;
  &:hover {
    color: #444444;
    border-color: #444444;
  }
`

const InputTooltip = styled(ReactTooltip)`
  max-width: 320px;
  & p {
    line-height: 1.35;
  }
`

export const Select = ({ label, name, options, formik, placeholder, withError, onChange, defaultValue, isLoading, isDisabled }: SelectProps) => {
  function selectChange(value: ValueType<Option, any>) {
    if (value && 'value' in value) {
      if (onChange) {
        onChange(value)
        return
      }
      formik.setFieldValue(name, value.value)
    }
    if (value && onChange) {
      onChange(value)
    }
  }

  return (
    <>
      {label && typeof label === 'string'
        ? (
          <LabelStyled>
            <label>{label}</label>
          </LabelStyled>
        )
        : typeof label === 'object' && (
        <LabelStyled>
          <label>{label.text}</label>
          {label.tooltip && (
            <>
              <Prompt data-tip data-for={`${name}-prompt`}>?</Prompt>
              <InputTooltip id={`${name}-prompt`} place='top' effect='solid'>
                <p>{label.tooltip}</p>
              </InputTooltip>
            </>
          )}
        </LabelStyled>
      )
      }
      <ReactSelect
        styles={customStyles}
        options={options}
        placeholder={placeholder}
        onChange={selectChange}
        defaultValue={defaultValue}
        isLoading={isLoading}
        isDisabled={isDisabled}
      />
      {withError && formik.touched[name] && (
        <ErrorStyled>
          {formik.errors[name]}
        </ErrorStyled>
      ) }
    </>
  )
}
