import React from 'react'
import Button from '../../UI/Button/Button'
import styled from 'styled-components'
import { Form, Formik } from 'formik'
import { Col, Paragraph, RadioTabGroup, Row, Title } from '../../UI/Form'
import * as Yup from 'yup'
import ym from 'react-yandex-metrika'
import ymGoals from '../../../analytics/ymGoals'
import { PlanBuilderStepProps } from './types'
import { usePlanBuilder } from '../../../services/PlanBuilder'

type Step2BValues = {
  includeTesting: string
}

const Buttons = styled.div`
  margin-top: 25px;
  display: flex;
  & button {
    margin-left: 25px;
    &:first-child {
      margin: 0;
    }
  }
`

const BackButton = styled(Button)`
  border: none;
  color: #2F80ED;
  &:hover {
    background: none;
    border: none;
    color: #2A75DB;
    text-decoration: underline;
  }
`

const LocalCol = styled(Col)`
  max-width: 620px;
  & > div > div > div > label {
    height: 55px;
    text-align: center;
  }
`

const Step2B = ({ tabSwitcher }: PlanBuilderStepProps) => {
  const { store } = usePlanBuilder()

  function formSubmit({ includeTesting }: Step2BValues, { setSubmitting }: any): void {
    setSubmitting(true)
    if (!!+includeTesting) {
      store.update({ includeTesting: true })
    }
    if (!+includeTesting) {
      store.update({ includeTesting: false })
    }

    setSubmitting(false)
    ym('reachGoal', ymGoals.planbuilder.chooseTrainingProgram)
    tabSwitcher('3')
  }

  function getRadioOptions() {
    return [
      { id: 'add', value: '1', labelValue: 'Добавить тестирование'},
      { id: 'refuse', value: '0', labelValue: 'Отказаться'}
    ]
  }

  return (
    <Formik
      initialValues={{
        includeTesting: '',
      }}
      onSubmit={formSubmit}
      validationSchema={Step2BSchema}
    >
      {() => {
        return (
          <Form>
            <Title style={{paddingBottom: 10}}>Выполнение тестирования</Title>
            <Row>
              <LocalCol>
                <Paragraph>Мы можем включить тестирования по бегу и велосипеду в первые три недели программы чтобы актуализировать ваши функциональные параметры.</Paragraph>
              </LocalCol>
            </Row>
            <Row>
              <LocalCol>
                <RadioTabGroup name='includeTesting' values={getRadioOptions()} withError/>
              </LocalCol>
            </Row>
            <Buttons>
              <Button type='submit' solid>Продолжить</Button>
              <BackButton type='button' onClick={() => tabSwitcher('2')}>Вернуться назад</BackButton>
            </Buttons>
          </Form>
        )
      }}
    </Formik>
  )
}

export default Step2B

const Step2BSchema = Yup.object().shape({
  includeTesting: Yup.string()
    .required('Добавьте или откажитесь от тестирования'),
})
