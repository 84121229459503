import { loader } from 'graphql.macro'
import { FetchResult, useMutation } from '@apollo/client'

const PAYMENT_CHANGE_METHOD = loader('../../apollo/mutations/payment/ChangeMethod.graphql')

export type UsePaymentData = {
  change: () => Promise<FetchResult<any>>
  loading: boolean
}

export function usePaymentData(): UsePaymentData {
  const [changePaymentData, { loading }] = useMutation(PAYMENT_CHANGE_METHOD)

  function change() {
    return changePaymentData()
  }

  return {
    change,
    loading
  }
}
