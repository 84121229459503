import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { routes } from '../routeConfig'
import Modal from '../components/UI/Modal/Modal'
import PlanBuilder from '../components/PlanBuilder/PlanBuilder'
import { addPaddingToShiftElements, getScrollBarWidth, removePaddingFromShiftElements } from '../utils/window'
import { useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'

const GET_USER = loader('../apollo/queries/User.graphql')

const PlanBuilderContainer = () => {
  const history = useHistory()
  const [isOpen, setIsOpen] = useState(true)
  const scrollBarWidth = getScrollBarWidth()

  const [skip, setSkip] = useState(false)
  const {data, loading:userLoading, error:userError } = useQuery(GET_USER, {
    onError: (error) => console.error(error.message),
    skip
  })

  useEffect(() => {
    addPaddingToShiftElements(scrollBarWidth)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (data?.user?.paymentSubscription?.status === 'ENDED') {
      history.push(routes.subscription)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.user?.paymentSubscription?.status])

  if (userError) {
    setSkip(true)
  }

  if (userLoading) {
    return null
  }

  function closeModal() {
    removePaddingFromShiftElements()
    setIsOpen(false)
    history.push(routes.calendar)
  }

  return (
    <Modal isOpen={isOpen} closeHandler={closeModal} portalName='PlanBuilderModal'>
      <PlanBuilder closeHandler={closeModal} />
    </Modal>
  )
}

export default PlanBuilderContainer
