import React, { useEffect } from 'react'
import styled from 'styled-components'
import getYear from 'date-fns/getYear'

const Container = styled.div`
  max-width: 760px;
  padding: 75px 0;
  margin: 0 auto;
  & a {
    color: #ff8562
  }
`

const H1 = styled.h1`
  font-size: 40px;
  margin: 0;
  padding: 0 0 30px;
`

const H2 = styled.h2`
  font-size: 24px;
  margin: 0;
  padding: 15px 0;
`

const H3 = styled.h3`
  font-size: 20px;
  margin: 0;
  padding: 15px 0;
`

const Paragraph = styled.p`
  margin: 0;
  padding: 10px 0;
  font-weight: 300;
  line-height: 1.55;
`

const Strong = styled.strong`
  font-weight: 700;
`

const NumList = styled.ol`
  padding: 0 0 0 20px;
`

const ListItem = styled.li`
  padding: 2px 0;
  font-weight: 300;
  line-height: 1.55;
`

const ToS = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Container>
      <H1>Terms of Service</H1>
      <Paragraph>
        The Trisystems websites, related mobile applications and services (collectively, the "Services") are made available to you by Trisystems, Inc. with its address at European pr. 9/1-109, Saint Petersburg, 188986, Russia, subject to these Terms of Service (the "Terms") and in accordance with the Trisystems Privacy Policy (the "Privacy Policy"). More information about the Services may be found at <a href='http://trisystems.ai/' target='_blank' rel='noreferrer'>http://trisystems.ai/</a>. You agree to comply with these Terms and any supplemental terms which Trisystems makes available to you on the Services which shall form part of the Terms. BY ACCESSING, USING OR UPLOADING OR DOWNLOADING ANY INFORMATION OR MATERIALS TO OR FROM THE SERVICES, OR BY INDICATING YOUR ASSENT TO THESE TERMS BY CREATING AN ACCOUNT, CLICKING "SIGN UP" OR ANY SIMILAR MECHANISM, YOU ARE AGREEING TO THE THESE TERMS. IF YOU DO NOT AGREE TO THE THESE TERMS, DO NOT ACCESS OR USE THE SERVICES. This agreement was written in English. To the extent a translated version of the Terms conflict with the English version, the English version controls.
      </Paragraph>
      <Paragraph>
        If you access or use the Services on behalf of a company or other entity, you represent that you have authority to bind such entity and its affiliates to these Terms and that it is fully binding on them. In such case, the term "you" will refer to such entity and its affiliates. If you do not have authority, you may not access or use the Services. These Terms contain disclaimers of warranties and limitations on liability that may be applicable to you.
      </Paragraph>
      <Paragraph>
        The Trisystems Services cannot be provided and the agreement described in these Terms of Service cannot be performed without Trisystems processing data about you, and other Trisystems athletes, including your location data. Processing of the data you share with Trisystems, including location data, is essential to the Services which we provide and a necessary part of our performance of the agreement we have with you.
      </Paragraph>
      <Paragraph>
        <Strong>
          Notice Regarding Dispute Resolution: These Terms contain provisions that govern how disputes between you and Trisystems are resolved, including an agreement to arbitrate, which will, unless you reside in the European Union, with limited exception, require you to submit claims you have against us to binding and final arbitration and limit you to claims against Trisystems on an individual basis, unless you opt-out in accordance with the instructions below.
        </Strong>
      </Paragraph>

      <H2>Registration and Accounts</H2>
      <Paragraph>
        The Services are intended solely for persons who are 13 years old or such higher age required in your country to use the Services. If you are under the legal age to form a binding contract in your jurisdiction, you represent that your parent or legal guardian has reviewed and agreed to these Terms on your behalf.
      </Paragraph>
      <Paragraph>
        To use the Services, you must register. You agree to: (a) provide true, accurate, current and complete information about yourself ("Athlete Data") and (b) maintain and promptly update the Athlete Data. You agree that Trisystems may use your Athlete Data to provide Services that you access or use and as otherwise set forth in these Terms. If you provide any Athlete Data that is inaccurate or not current, or Trisystems has reasonable grounds to suspect that such Athlete Data is inaccurate or not current, Trisystems has the right to suspend or terminate your account and refuse current or future use of the Services. In consideration of your use of the Services, you represent that you are not a person barred from receiving services under the laws of any applicable jurisdiction.
      </Paragraph>
      <Paragraph>
        You are solely responsible for maintaining the confidentiality of the password associated with your account and for restricting access to your password, your computer and mobile device while logged into the Services. You accept responsibility for all activities that occur under your account or from your computer and mobile devices. We endeavor to use reasonable security measures to protect against unauthorized access to your account. We cannot, however, guarantee absolute security of your account, your Content (as defined below) or the personal information you provide, and we cannot promise that our security measures will prevent third-party "hackers" from illegally accessing the Services or its contents. You agree to immediately notify Trisystems of any unauthorized use of your account or password, or any other breach of security, and confirm that you understand all risks of unauthorized access to Athlete Data and any other information or content you provide to Trisystems.
      </Paragraph>

      <H2>Payments and Fees</H2>
      <H3>Payments</H3>
      <Paragraph>To pay any fee, you must designate and provide information about your preferred payment method (e.g., credit card, online payment service, a third party, like iTunes or Google Play, or any other payment method made available by Trisystems) (the "Payment Method"). If you provide your payment information, you authorize us and certain third party service providers, payment card networks and payment processors to receive, store and encrypt your payment information. You may switch to a different Payment Method or update your information. If you paid through the Google Play or iTunes store, you may change your Payment Method through the corresponding store account. No refunds or credits will be provided by Trisystems, other than as set forth in these Terms. If your payment provider determines that there are fraudulent charges on your account resulting from use of your Payment Method at the Services, please contact us immediately sending us email to info.tri.systems@gmail.com</Paragraph>
      <H3>Fees</H3>
      <Paragraph>In order to access certain functionality of the Services, you may be required to pay subscription fees. Subscription fees, along with any required taxes, may be paid on a monthly or annual basis. All subscription fees are payable in advance. Athletes changing from monthly to annual subscriptions will have the annual rates take effect at the beginning of the next billing date. You agree to pay the subscription fees, and other charges you incur in connection with your Trisystems account, whether on a one-time or subscription basis. Trisystems reserves the right to increase subscription fees, any associated taxes, or to institute new fees at any time upon reasonable advance notice.</Paragraph>
      <H3>Auto-Renewal</H3>
      <Paragraph>Subscription fees will be billed automatically at the start of the monthly or annual period, as applicable. These fees will auto-renew until your subscription is downgraded or terminated. Your subscription fee will be the same as your initial charges unless you are otherwise notified in advance. You may cancel your subscription at any time, as described below.</Paragraph>
      <H3>Cooling Off</H3>
      <Paragraph>You may be entitled to change your mind and receive a full refund within fourteen (14) days (the "Cooling-off Period"), provided that you have not logged in or otherwise redeemed or started to use the Services as a subscriber during the Cooling-off Period.</Paragraph>
      <H2>Cancellation</H2>
      <H3>Cancellation of Subscription</H3>
      <Paragraph>If you signed up on trisystems.ai you may cancel your subscription by visiting your "Subscription" page and selecting "Unsubscribed." If you signed up through the Google Play or iTunes store, you may cancel your subscription by downgrading through the corresponding store. The cancellation of a subscription will go into effect at the end of your current billing cycle. When your subscription ends, your account will remain and become a free subscription. You can renew your subscription at any time without opening a new account, although the subscription fees may have increased. You can ask us to delete your account at any time and we do it within seven days.</Paragraph>
      <H3>Free Trials</H3>
      <Paragraph>Your subscription may start with a free trial. The free trial period for any subscription will last for the period of time specified when you signed up. Free trials may not be combined with certain other offers, as specified. You may cancel your subscription at any time by going to your account settings.</Paragraph>
      <H3>Conduct</H3>
      <Paragraph>The Services are for your personal and noncommercial use. You may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer or sell for any commercial purposes any portion of the Services, use of the Services or access to Content. You may not use the Services, or assist or encourage any other party, to engage in any of the following Prohibited Activities:</Paragraph>
      <NumList>
        <ListItem>Copying, framing or mirroring any part of the Services;</ListItem>
        <ListItem>Accessing the Services to monitor its availability, performance or functionality;</ListItem>
        <ListItem>Permitting any third party to access the Services;</ListItem>
        <ListItem>Using, copying, modifying, creating a derivative work of, reverse engineering, decompiling or otherwise attempting to extract the source code of the software underlying the Services or any part thereof, unless expressly permitted or required by law, and in any case, without providing prior written notice to Trisystems;</ListItem>
        <ListItem>Publishing, transmitting, distributing or storing content, material, information or data that: (1) is illegal, obscene, defamatory, threatening, harassing, abusive, or hateful or that advocates violence; (2) is harmful to or interferes with the Services or any third party's networks, equipment, applications, services or websites (e.g., viruses, worms, Trojan horses, etc.); (3) infringes, dilutes, misappropriates or otherwise violates any privacy, intellectual property, publicity or other personal rights including, without limitation, copyrights, patents, trademarks, trade secrets or other proprietary information (including unauthorized use of domain names); or (4) is fraudulent or contains false, deceptive or misleading statements, claims or representations (such as "phishing");</ListItem>
        <ListItem>Attempting to disrupt, degrade, impair or violate the integrity or security of the Services or the computers, services, Accounts or networks of any other party (including, without limitation, "hacking," "denial of service" attacks, etc.), including any activity that typically precedes attempts to breach security such as scanning, probing or other testing or vulnerability assessment activity, or engaging in or permitting any network or hosting activity that results in the blacklisting or other blockage of Trisystems internet protocol space;</ListItem>
        <ListItem>Avoiding payment of charges or fees payable by you with respect to the Services;</ListItem>
        <ListItem>Committing any act that may be harmful to minors;</ListItem>
        <ListItem>Distributing, or disclosing any part of the Services in any medium, including without limitation by any automated or non-automated "scraping";</ListItem>
        <ListItem>Using any automated system, including, without limitation, "robots," "spiders," "offline readers," etc., to access the Services in a manner that sends more request messages to Trisystems than a human can reasonably produce in the same period of time by using a conventional web browser;</ListItem>
        <ListItem>Taking any action that imposes, or may impose, at our sole discretion, an unreasonable or disproportionately large load on our infrastructure;</ListItem>
        <ListItem>Collecting or harvesting any information relating to an identified or identifiable individual, including account names and information about users of the Services, from the Services;</ListItem>
        <ListItem>Using the Services for any inappropriate commercial solicitation purposes;</ListItem>
        <ListItem>Accessing any content on the Services through any technology or means other than those provided or authorized by the Services;</ListItem>
        <ListItem>Submitting to the Services or to Trisystems any personally identifiable information, except as necessary for the establishment and operation of your account;</ListItem>
        <ListItem>Submitting to the Services or to Trisystems any information that may be protected from disclosure by applicable law;</ListItem>
        <ListItem>Bypassing the measures we may use to prevent or restrict access to the Services, including, without limitation, features that prevent or restrict use or copying of any content or enforce limitations on use of the Services or the content therein;</ListItem>
        <ListItem>Violating any applicable law, statute, ordinance or regulation, or encouraging any conduct that could constitute a criminal offense or give rise to civil liability;</ListItem>
        <ListItem>Removing any copyright, trademark or other proprietary rights notices contained in or on the Services;</ListItem>
        <ListItem>Executing any form of network monitoring or running a network analyzer or packet sniffer or other technology to intercept, decode, mine or display any packets used to communicate between the Service's servers or any data not intended for you.</ListItem>
      </NumList>
      <Paragraph>You are granted a limited, non-exclusive right to create a text hyperlink to the Services for non-commercial use only, provided such link does not portray Trisystems or any of its products or services in a false, misleading, derogatory or otherwise defamatory manner, and provided further that the linking site does not contain any pornographic, illegal, offensive, harassing or otherwise objectionable material. You are further granted a right to implement any RSS feeds located on the Services for your personal, non-commercial use, solely as described on the Services. We reserve the right to revoke these licenses generally, or your right to use specific links or RSS feeds, at any time, with or without cause.</Paragraph>
      <Paragraph>You understand that use of certain features of the Services may require you to purchase third party equipment or materials (e.g., GPS systems). While Trisystems may recommend the equipment or materials of certain third party suppliers, Trisystems shall have no responsibility for your acquisition or use of any third party equipment or materials and does not guarantee that third party equipment or materials will function with the Services or will be error-free.</Paragraph>
      <Paragraph>You understand that you are responsible for any charges associated with sending communications via your device. You hereby acknowledge that you have the right to communicate with your contacts via the Services.</Paragraph>
      <Paragraph>You represent and warrant that: (i) you are authorized to create your account, whether individually or on behalf of an organization; (ii) you own the Content posted by you on or through the Services or otherwise have the right to grant the rights and licenses set forth in these Terms; (iii) the posting and use of your Content on or through the Services does not and will not violate, misappropriate or infringe on the rights of any third party, including, without limitation, privacy and data protection rights, publicity rights, copyrights, trademark and/or other intellectual property rights; and (iv) you agree to pay for all royalties, fees, and any other monies owed by reason of Content you post on or through the Services.</Paragraph>
      <H3>Third Parties</H3>
      <Paragraph>Third party products and services made available on the Services are made and offered directly by the applicable third party. When you pursue or purchase any such product or service, you acknowledge that you are contracting directly with such third party and not with Trisystems. Your interaction with, or participation in promotions of, third parties found on or through the Services, including payment and delivery of goods or services, and any other terms, are solely between you and such third party. You are not obligated to use or transact business with any third party that appears on the Services. YOU AGREE THAT TRISYSTEMS SHALL NOT BE LIABLE FOR ANY LOSS OR DAMAGES OF ANY KIND INCURRED BY YOU AS THE RESULT OF ANY OF YOUR DEALINGS WITH THIRD PARTY SERVICE PROVIDERS OR ADVERTISERS AVAILABLE THROUGH THE SERVICES.</Paragraph>
      <Paragraph>Trisystems or third parties may provide links to other internet sites or resources through the Services. Trisystems does not endorse and is not responsible or liable for any content, information, advertising, products or other materials on or available from such sites or resources. You acknowledge and agree that Trisystems is not responsible for the availability of such external sites or resources.</Paragraph>
      <H3>Proprietary Rights</H3>
      <Paragraph>You acknowledge and agree that the Services, any necessary software used in connection with the Services (if any), any aggregated data based on Content on the Services, and any Content available or made available on the Services contain proprietary and confidential information that is protected by applicable intellectual property and other laws. Except as expressly permitted by applicable law or authorized by Trisystems or applicable third party service providers or advertisers, you agree not to modify, rent, lease, loan, sell, distribute or create derivative works based on the Services, the software or Content available on the Services (other than Content that you may submit), in whole or in part.</Paragraph>
      <Paragraph>Trisystems grants you a personal, revocable, non-transferable, non-sublicensable and non-exclusive right and license to access and use the Services; provided that you do not (and do not allow any third party to) copy, modify, create a derivative work from, reverse engineer, reverse assemble or otherwise attempt to discover any source code, sell, assign, sublicense, grant a security interest in or otherwise transfer any right in the Services, except where permitted by law. You agree not to access the Services by any means other than through the interfaces that are provided by Trisystems.</Paragraph>
      <Paragraph>The Trisystems logo and other Trisystems logos and product and service names are the exclusive trademarks of, and are owned by, Trisystems, Inc., and you may not use or display such trademarks in any manner without Trisystems's prior written permission. Any third party trademarks or service marks displayed on the Services are the property of their respective owners.</Paragraph>
      <Paragraph>Trisystems reserves all rights not expressly granted hereunder.</Paragraph>
      <H3>Claims of Infringement</H3>
      <Paragraph>If you believe in good faith that materials hosted by Trisystems infringe your copyright or trademark rights, you (or your agent) may send us a notice requesting that the material be removed, or access to it blocked. The notice must include the following information: (a) a physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed; (b) identification of the copyrighted work or trademark claimed to have been infringed (or, if multiple copyrighted works or trademarks located on the Services are covered by a single notification, a representative list of such works); (c) identification of the material that is claimed to be infringing or the subject of infringing activity, and information reasonably sufficient to allow Trisystems to locate the material on the Services; (d) the name, address, telephone number, and e-mail address (if available) of the complaining party; (e) a statement that the complaining party has a good-faith belief that use of the material in the manner complained of is not authorized by the copyright or trademark owner, its agent, or the law; and (f) a statement that the information in the notification is accurate and, under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed. Please be advised that Trisystems will not respond to complaints that do not meet these requirements. If Trisystems determines that the materials alleged to infringe your copyright or trademark rights do not require removal, Trisystems will remove those materials only pursuant to a court order declaring the content or use of the materials unlawful.</Paragraph>
      <Paragraph>If you believe in good faith that a notice of copyright infringement has been wrongly filed against you, the DMCA permits you to send us a counter-notice. Counter-notices must include the following information: (a) your name, address, and telephone number; (b) the source of the content that was removed; (c) a statement under penalty of perjury that you have a good-faith belief that the content was removed in error; (d) a statement that you consent to the jurisdiction of Federal District Court for the judicial district in which your address is located; or, if your address is outside of Russia, for any judicial district in which Trisystems may be found, and that you will accept service of process from the person who provided the original complaint; and (e) a physical or electronic signature (for example, typing your full name).</Paragraph>
      <Paragraph>Notices and counter-notices with respect to the Services must meet the then-current statutory requirements imposed by the DMCA (see <a href='https://copyright.gov/title17/92appb.html' target='_blank' rel='noreferrer'>https://copyright.gov/title17/92appb.html</a>) and should be sent to the agent identified below. We suggest that you consult your legal advisor before filing a notice or counter-notice. Also, be aware that there may be penalties for false claims under the DMCA.</Paragraph>
      <Paragraph>Agent to Receive Notices of Claimed Infringement:</Paragraph>
      <Paragraph>
        <Strong>Trisystems Copyright Agent</Strong>
        <br />
        European pr. 9/1-109
        <br />
        Saint Peterburg, 188986
        <br />
        Russia
        <br />
        info.tri.systems@gmail.com
      </Paragraph>

      <H2>Your Feedback</H2>
      <Paragraph>We welcome your comments, feedback, suggestions, and other communications regarding the Services and the information and services we make available through the Services (collectively, "Feedback"). If you provide Feedback, you hereby grant to Trisystems a worldwide, non-exclusive, transferable, assignable, sub-licensable, perpetual, irrevocable, royalty-free license to copy, distribute, create derivative works of, publicly display and perform and otherwise exploit such Feedback and to use, make, have made, sell, offer for sale, import and export products and services based on such Feedback. For this reason, we ask that you not send Trisystems any Feedback that you do not wish to license to us as set forth above.</Paragraph>
      <H2>Disclaimer of Warranties and Liability</H2>
      <Paragraph>THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND CONTENT AVAILABLE THROUGH THE SERVICES ARE PROVIDED TO YOU "AS IS" AND WITHOUT WARRANTY. TRISYSTEMS AND ITS SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS HEREBY DISCLAIM ALL WARRANTIES WITH REGARD TO SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND CONTENT, INCLUDING, WITHOUT LIMITATION, ALL IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NONINFRINGEMENT. TRISYSTEMS AND ITS SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS MAKE NO WARRANTY THAT: (a) THE SERVICE WILL MEET YOUR REQUIREMENTS; (b) YOUR CONTENT WILL BE AVAILABLE OR THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE; (c) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICE WILL BE ACCURATE OR RELIABLE; (d) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICE WILL MEET YOUR EXPECTATIONS; AND (e) ANY ERRORS IN THE SERVICES WILL BE CORRECTED.</Paragraph>
      <Paragraph>YOU EXPRESSLY AGREE THAT TRISYSTEMS IS NOT PROVIDING MEDICAL ADVICE VIA THE SERVICES. THE CONTENT PROVIDED THROUGH THE SERVICES, INCLUDING ALL TEXT, PHOTOGRAPHS, IMAGES, ILLUSTRATIONS, GRAPHICS, AUDIO, VIDEO AND AUDIO-VIDEO CLIPS, AND OTHER MATERIALS, WHETHER PROVIDED BY US OR BY OTHER ACCOUNT HOLDERS OR THIRD PARTIES IS NOT INTENDED TO BE AND SHOULD NOT BE USED IN PLACE OF (a) THE ADVICE OF YOUR PHYSICIAN OR OTHER MEDICAL PROFESSIONALS, (b) A VISIT, CALL OR CONSULTATION WITH YOUR PHYSICIAN OR OTHER MEDICAL PROFESSIONALS, OR (c) INFORMATION CONTAINED ON OR IN ANY PRODUCT PACKAGING OR LABEL. SHOULD YOU HAVE ANY HEALTH RELATED QUESTIONS, PLEASE CALL OR SEE YOUR PHYSICIAN OR OTHER HEALTHCARE PROVIDER PROMPTLY. SHOULD YOU HAVE AN EMERGENCY, CALL YOUR PHYSICIAN OR 112 IMMEDIATELY. YOU SHOULD NEVER DISREGARD MEDICAL ADVICE OR DELAY IN SEEKING MEDICAL ADVICE BECAUSE OF ANY CONTENT PRESENTED ON THE SERVICES, AND YOU SHOULD NOT USE THE SERVICES OR ANY CONTENT ON THE SERVICES FOR DIAGNOSING OR TREATING A HEALTH PROBLEM. THE TRANSMISSION AND RECEIPT OF OUR CONTENT, IN WHOLE OR IN PART, OR COMMUNICATION VIA THE INTERNET, E-MAIL OR OTHER MEANS DOES NOT CONSTITUTE OR CREATE A DOCTOR-PATIENT, THERAPIST-PATIENT OR OTHER HEALTHCARE PROFESSIONAL RELATIONSHIP BETWEEN YOU AND TRISYSTEMS.</Paragraph>
      <Paragraph>YOU EXPRESSLY AGREE THAT YOUR ATHLETIC ACTIVITIES (INCLUDING, BUT NOT LIMITED TO, CYCLING, RUNNING, SWIMMING, OR FOLLOWING A TRISYSTEMS TRAINING PLAN OFFERED ON THE SERVICES) CARRY CERTAIN INHERENT AND SIGNIFICANT RISKS OF PROPERTY DAMAGE, BODILY INJURY OR DEATH AND THAT YOU VOLUNTARILY ASSUME ALL KNOWN AND UNKNOWN RISKS ASSOCIATED WITH THESE ACTIVITIES EVEN IF CAUSED IN WHOLE OR PART BY THE ACTION, INACTION OR NEGLIGENCE OF TRISYSTEMS OR BY THE ACTION, INACTION OR NEGLIGENCE OF OTHERS.</Paragraph>
      <Paragraph>YOU EXPRESSLY AGREE THAT TRISYSTEMS DOES NOT ASSUME RESPONSIBILITY FOR THE INSPECTION, SUPERVISION, PREPARATION, OR CONDUCT OF ANY RACE, CONTEST, CHALLENGE, OR GROUP ACTIVITY THAT UTILIZES THE SERVICES, INCLUDING ANY THAT ARE ORGANIZED BY A CLUB ADMINISTRATOR.</Paragraph>
      <Paragraph>YOU EXPRESSLY AGREE TO RELEASE TRISYSTEMS, ITS SUBSIDIARIES, AFFILIATES, OFFICERS, AGENTS, REPRESENTATIVES, EMPLOYEES, PARTNERS AND LICENSORS (THE "RELEASED PARTIES") FROM ANY AND ALL LIABILITY IN CONNECTION WITH YOUR ATHLETIC ACTIVITIES AND/OR USE OF THE TRISYSTEMS WEBSITES, MOBILE APPLICATIONS, CONTENT, SERVICES OR PRODUCTS (INCLUDING, WITHOUT LIMITATION, ANY TRISYSTEMS TRAINING PLAN), AND PROMISE NOT TO SUE THE RELEASED PARTIES FOR ANY CLAIMS, ACTIONS, INJURIES, DAMAGES, OR LOSSES ASSOCIATED WITH SUCH USE. YOU ALSO AGREE THAT IN NO EVENT SHALL THE RELEASED PARTIES BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED WITH (a) YOUR USE OR MISUSE OF THE SERVICES, (b) YOUR USE OR MISUSE OF EQUIPMENT OR PROGRAMS CREATED OR LICENSED BY TRISYSTEMS (INCLUDING, WITHOUT LIMITATION, ANY TRISYSTEMS TRAINING PLAN) WHILE ENGAGED IN ATHLETIC ACTIVITIES, (c) YOUR DEALINGS WITH THIRD-PARTY SERVICE PROVIDERS OR ADVERTISERS AVAILABLE THROUGH THE SERVICES, (d) ANY DELAY OR INABILITY TO USE THE SERVICES EXPERIENCED BY YOU, OR (e) ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES OR CONTENT OBTAINED THROUGH THE SERVICES, WHETHER BASED ON CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE, EVEN IF TRISYSTEMS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES.</Paragraph>
      <Paragraph>TRISYSTEMS DOES NOT ENDORSE YOUR CONTENT OR ANY OTHER ATHLETE'S CONTENT AND SPECIFICALLY DISCLAIMS ANY RESPONSIBILITY OR LIABILITY TO ANY PERSON OR ENTITY FOR ANY LOSS, DAMAGE (WHETHER ACTUAL, CONSEQUENTIAL, INDIRECT, PUNITIVE OR OTHERWISE), INJURY, CLAIM, LIABILITY OR OTHER CAUSE OF ANY KIND OR CHARACTER BASED UPON OR RESULTING FROM ANY CONTENT ON THE SERVICES.</Paragraph>
      <Paragraph>THE COMPANY'S MAXIMUM AGGREGATE LIABILITY TO YOU PURSUANT TO THESE TERMS SHALL NOT EXCEED THE GREATER OF EITHER (a) FIFTY DOLLARS ($50) OR (b) THE AMOUNT OF FEES PAID BY YOU TO TRISYSTEMS IN THE 12-MONTHS PRIOR TO DATE THE CLAIM.</Paragraph>
      <Paragraph>IF YOU ARE A CONSUMER RESIDING IN THE EUROPEAN UNION, YOU HAVE LEGAL REMEDIES IF WE CAUSE YOU LOSS BY OUR BREACH OR NON-PERFORMANCE OF OUR LEGAL OBLIGATIONS, OR BY OUR NEGLIGENCE, AND THESE TERMS DO NOT AFFECT THOSE STATUTORY REMEDIES.</Paragraph>
      <H2>Indemnity</H2>
      <Paragraph>You agree to indemnify and hold Trisystems and its subsidiaries, affiliates, officers, agents, representatives, employees, partners and licensors harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of Content you submit, post, transmit or otherwise seek to make available through the Services, your use of the Services, your athletic activities which generate the Content you post or seek to post on the Services (including, but not limited to, athletic activities in connection with any contests, races, group rides, or other events which Trisystems sponsors, organizes, participates in, or whose Services are used in connection with), your connection to the Services, your violation of the Terms, your violation of any data protection or privacy laws, or your violation of any rights of another person or entity. Your rights with respect to Trisystems are not modified by the foregoing indemnification if the laws of your country of residence, applicable as a result of your use of our Services, do not permit it.</Paragraph>

      <H2>Dispute Resolution</H2>
      <H3>Arbitration</H3>
      <Paragraph>The parties shall use their best efforts to settle any dispute, claim, question, or disagreement directly through good-faith negotiations, which shall be a precondition to either party initiating a lawsuit or arbitration. Except for disputes relating to the Trisystems's intellectual property (such as trademarks, trade dress, domain names, trade secrets, copyrights, and patents) or if you opt out of this agreement to arbitrate, all claims arising out of or relating to this Agreement and your use of the Services shall be finally settled by binding arbitration administered by JAMS in accordance with its provisions and procedures for consumer-related disputes, excluding any rules or procedures governing or permitting class actions. The arbitrator, and not any court or agency, shall have exclusive authority to resolve all disputes arising out of or relating to this Agreement, including, but not limited to, any claim that all or any part of this Agreement is void or voidable. The arbitrator shall be empowered to grant whatever relief would be available in a court; provided, however, that the arbitrator will not have authority to award damages, remedies, or awards that conflict with this Agreement. The arbitrator's award shall be binding on the parties and may be entered as a judgment in any court of competent jurisdiction. To the extent the filing fee for the arbitration exceeds the cost of filing a lawsuit, Trisystems will pay the additional cost. You and Trisystems hereby expressly waive trial by jury. You also agree not to participate in claims brought in a private attorney general or representative capacity, or consolidated claims involving another person's account, if Trisystems is a party to the proceeding. This dispute resolution provision will be governed by the Federal Arbitration Act. This provision does not apply to users who live in the European Union.</Paragraph>
      <Paragraph>The parties understand that, absent this mandatory provision, they would have the right to sue in court and have a jury trial. They further understand that, in some instances, the costs of arbitration could exceed the costs of litigation and that the right to discovery may be more limited in arbitration than in court.</Paragraph>
      <H3>Class-Action Waiver</H3>
      <Paragraph>The parties further agree that any arbitration shall be conducted in their individual capacities only, and not as a class action or other representative action. If any court or arbitrator determines that the class-action waiver set forth in this section is void or unenforceable for any reason, or that an arbitration can proceed on a class basis, then the arbitration provision set forth above shall be deemed null and void in its entirety and the parties shall be deemed to have not agreed to arbitrate disputes.</Paragraph>
      <H3>Exception—Litigation of Small Claims Court Claims</H3>
      <Paragraph>Notwithstanding the parties' decision to resolve all disputes through arbitration, either party may also seek relief in a small claims court for disputes or claims within the scope of that court's jurisdiction.</Paragraph>
      <H3>Thirty-Day Right to Opt Out</H3>
      <Paragraph>You have the right to opt out and not be bound by the arbitration- and class-action-waiver provisions set forth in this section by sending written notice of your decision to opt out to the following address: Trisystems, European pr. 9/1-109, Saint Peterburg, 188986, Russia. The notice must be sent within 30 days of registering to use the Services; otherwise, you shall be bound to arbitrate disputes in accordance with these Terms. If you opt out of these arbitration provisions, Trisystems also will not be bound by them. In addition, if you elect to opt out of these arbitration provisions, Trisystems may terminate your use of the Services.</Paragraph>
      <H3>Time Limitation on Claims</H3>
      <Paragraph>You agree that any claim you may have arising out of or related to your relationship with Trisystems and these Terms must be filed within one year after such claim arose; otherwise, your claim is permanently barred. This provision does not apply if you are based in the European Union in which case time limitations shall be determined in accordance with governing law for EU users mentioned below.</Paragraph>

      <H2>Choice of Law and Forum</H2>
      <Paragraph>Any action related to the Terms, Content, the Services, and your relationship with the Trisystems shall be governed by, and construed and interpreted in accordance with, the laws of Russia without regard to its conflict of laws principles. Subject to the Section entitled "Dispute Resolution", the parties irrevocably consent to bring any action to enforce this Agreement in the federal or state courts embracing Saint Petersburg, Russia and you consent to the exclusive jurisdiction of the federal or state courts embracing Saint Petersburg, Russia. If any party hereto brings any suit or action against another for relief, declaratory or otherwise, arising out of these Terms, the prevailing party will have and recover against the other party, in addition to all court costs and disbursements, such sum as the court may adjudge to be reasonable attorneys' fees. Except to the extent prohibited by applicable law, the parties agree that any claim or cause of action arising out of or related to use of the Services or the Terms must be filed within one (1) year after such claim or cause of action arose or be forever barred. This paragraph does not apply to users who reside in the European Union.</Paragraph>
      <Paragraph>If you are a user based in the European Union, then Irish law shall apply to these Terms and the Irish courts shall have exclusive jurisdiction to hear disputes arising in relation to the Terms. Despite this, your local laws in your European Union Member State may allow you to take legal action against Trisystems in your Member State and to invoke certain local laws against Trisystems.</Paragraph>

      <H2>Russian Operation</H2>
      <Paragraph>This Services are controlled by Trisystems from its offices within Russia. Trisystems makes no representation that the Content or the Services are appropriate or available for use in other locations. Access to or use of the Content or the Services from territories where such access or use is illegal is prohibited. Those who choose to access the Services from locations outside of Russia do so on their own initiative and are responsible for compliance with applicable local laws. You may not use or export the Content in violation of Russian export laws and regulations.</Paragraph>

      <H2>Termination</H2>
      <Paragraph>You agree that Trisystems may, under certain serious circumstances and without prior notice, immediately suspend or terminate your account and/or access to the Services. Cause for such suspension or termination shall include, but not be limited to, (a) breaches or violations of the Terms or other incorporated agreements, policies or guidelines, (b) requests by law enforcement or other government agencies, (c) a request by you (self-initiated account deletions), (d) discontinuance or material modification to the Services (or any portion thereof), (e) unexpected technical or security issues or problems, (f) extended periods of inactivity, and/or (g) nonpayment of any fees owed by you in connection with the Services. Termination of your account may include (x) removal of access to all offerings within the Services, (y) deletion of your information, files and Content associated with your account, and (z) barring of further use of the Services. Further, you agree that all suspensions or terminations for cause shall be made in Trisystems's sole discretion and that Trisystems shall not be liable to you or any third party for any suspension or termination of your account or access to the Services. The following Sections shall survive termination of your account and/or the Terms: Content and Conduct, Clubs, Proprietary Rights, Your Feedback, Disclaimer of Warranties and Liability, Indemnity, Choice of Law and Forum, and General.</Paragraph>

      <H2>General</H2>
      <Paragraph>You agree that no joint venture, partnership, joint controllership, employment or agency relationship exists between you and Trisystems as a result of the Terms or your use of the Services. The Terms constitute the entire agreement between you and Trisystems with respect to your use of the Services. The failure of Trisystems to exercise or enforce any right or provision of the Terms shall not constitute a waiver of such right or provision. If any provision of the Terms is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties' intentions as reflected in the provision, and the other provisions of the Terms remain in full force and effect. You may not assign, delegate or otherwise transfer your account or your obligations under these Terms without the prior written consent of Trisystems. Trisystems has the right, in its sole discretion, to transfer or assign all or any part of its rights under these Terms and will have the right to delegate or use third party contractors to fulfill its duties and obligations under these Terms and in connection with the Services. Trisystems's notice to you via email, regular mail or notices, posts, or links on the Services shall constitute acceptable notice to you under the Terms. A printed version of the Terms and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to the Terms to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. Section titles and headings in the Terms are for convenience only and have no legal or contractual effect. Any rights not expressly granted herein are reserved.</Paragraph>

      <H2>Modification of the Terms and Services</H2>
      <Paragraph>Certain provisions of the Terms may be supplemented or superseded by expressly designated legal notices or terms located on particular pages on the Services. Trisystems reserves the right to update the Terms at any time and for any reason in its sole discretion. Trisystems will notify you of any material changes to the Terms or to any service or other features of the Services. By continuing to access or use the Services after we have provided you with notice of a modification, you are agreeing to be bound by the modified Terms. If the modified Terms are not acceptable to you, your only recourse is to cease using the Services.</Paragraph>
      <Paragraph>Trisystems and its third party service providers may make improvements and/or changes in the Services, products, services, mobile applications, features, programs, and prices described at any time and for any reason in its sole discretion. The mobile application may download and install upgrades, updates and additional features in order to improve, enhance, and further develop the Services. Trisystems reserves the right at any time to modify or discontinue, temporarily or permanently, the Services or any portion thereof with or without notice. You agree that Trisystems shall not be liable to you or to any third party for any modification, suspension or discontinuance of the Services.</Paragraph>

      <H2>Support and Questions</H2>
      <Paragraph>We will respond to any questions regarding the Services and these Terms via info.tri.systems@gmail.com</Paragraph>
      <Paragraph>© {getYear(new Date())} Trisystems</Paragraph>
    </Container>
  )
}

export default ToS
