import React from 'react'
import PlanPreviewPeriod from './PlanPreviewPeriod/PlanPreviewPeriod'
import PlanPreviewPeriodAllWorkouts from './PlanPreviewPeriodAllWorkouts/PlanPreviewPeriodAllWorkouts'

type PlanPreviewProps = {
  periods: Array<TrainingPlan>
  workouts: Array<PreviewWorkout>
  events: Array<UserEvent>
  showAllWorkouts: boolean
}

const PlanPreview = ({ periods, workouts, events, showAllWorkouts }: PlanPreviewProps) => {

  function getPeriodWorkouts(periodId: string) {
    const periodWorkouts = workouts.filter(({ plan }) => plan.id === periodId)
    if (!showAllWorkouts) {
      return periodWorkouts.map((workout) => ({
        ...workout,
        title: ''
      }))
    }
    return periodWorkouts
  }

  return (
    <>
      {periods.map(({ title, id, startDate, endDate }) => {
        const periodWorkouts = getPeriodWorkouts(id)
        return showAllWorkouts
          ? (
            <PlanPreviewPeriodAllWorkouts
              key={id}
              workouts={periodWorkouts}
              events={events}
              title={title}
              startDate={startDate}
              endDate={endDate}
            />
          )
          : (
            <PlanPreviewPeriod
              key={id}
              workouts={periodWorkouts}
              events={events}
              title={title}
              startDate={startDate}
              endDate={endDate}
            />
          )
      })}
    </>
  )
}

export default PlanPreview
