import React from 'react'
import { Form, Formik } from 'formik'
import { Col, Input, Paragraph, Row, Subtitle, Title, Wrapper } from '../../../UI/Form'
import Button from '../../../UI/Button/Button'
import styles from '../../SignUp/SignUpForm.module.css'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { normalizeZones } from '../../../../utils/user'
import { useApolloClient, useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
import { useTargets } from '../../../../hooks/useTargets'
import Skeleton from 'react-loading-skeleton'
import * as Yup from 'yup'
import { isAfterSurvey } from '../../../../apollo/cache'
import { routes } from '../../../../routeConfig'
import ym from 'react-yandex-metrika'
import ymGoals from '../../../../analytics/ymGoals'

const UPDATE_ZONE = loader('../../../../apollo/mutations/UpdateZone.graphql')

type Step2Values = {
  '1:1'?: string
  '1:2'?: string
  '2:2'?: string
  '2:3'?: string
  '3:5'?: string
}

const SurveySubtitle = styled(Subtitle)`
  padding: 10px 0 3px !important;
`

const Step2 = ({ handler }: { handler: (step:number) => void }) => {
  const history = useHistory()
  const client = useApolloClient()
  const [updateZone] = useMutation(UPDATE_ZONE)
  const [targets] = useTargets({
    mapTo: 'id'
  })

  if (!targets) {
    return <Skeleton count={1} height={400}/>
  }

  function updateZonesPromise(zones: Array<UserZone>): () => Promise<any> {
    return () => new Promise(function(resolve, reject) {
      const results: any = []
      zones.forEach((zone) => {
        updateZone({
          variables: {
            zone
          }
        })
          .then((result) => results.push(result))
          .catch((error) => reject(error.message))
      })
      resolve(results)
    })
  }

  function formSubmit(values: Step2Values, { setSubmitting }: any): void {
    setSubmitting(true)
    const normalizedZones = normalizeZones(values)
    const zonesPromise = updateZonesPromise(normalizedZones)
    zonesPromise()
      .then(() => {
        setSubmitting(false)
        ym('reachGoal', ymGoals.survey.complete)
        isAfterSurvey(true)
        client.resetStore().then(() => history.push(routes.calendar))
      })
      .catch((error) => {
        setSubmitting(false)
        console.error(error.message)
      })
  }

  return (
    <Formik
      initialValues={{
        '1:1': '',
        '1:2': '',
        '2:2': '',
        '2:3': '',
        '3:5': ''
      }}
      onSubmit={formSubmit}
      validationSchema={Step2Schema}
    >
      {(props) => (
        <Wrapper>
          <Form>
            <Title>Настройка профиля (2 и 2)</Title>
            <Paragraph>Зная ваши характеристики мы сможем предложить наиболее оптимальную нагрузку на тренировках. Вы сможете изменить эти значения позднее в настройках.</Paragraph>
            <Subtitle>Пороговые значения</Subtitle>
            <Paragraph>Функциональные параметры атлета (FTP, ПАНО, АнП). Ничего страшного если сейчас вы их не знаете, мы уточним эти значения после первого тестирования.</Paragraph>
            <SurveySubtitle>Велосипед</SurveySubtitle>
            <Row>
              <Col>
                <Input
                  name='1:1'
                  mask='999'
                  label={{text: targets[1].descriptionSurvey, tooltip: targets[1].descriptionHelp}}
                  placeholder='Не знаю'
                  formik={props}
                  units={targets[1].units}
                  withError
                />
              </Col>
              <Col>
                <Input
                  name='1:2'
                  mask='999'
                  label={{text: targets[2].descriptionSurvey, tooltip: targets[2].descriptionHelp}}
                  placeholder='Не знаю'
                  formik={props}
                  units={targets[2].units}
                  withError
                />
              </Col>
            </Row>
            <SurveySubtitle>Бег</SurveySubtitle>
            <Row>
              <Col>
                <Input
                  name='2:3'
                  mask='99:99'
                  maskChar='_'
                  label={{text: targets[3].descriptionSurvey, tooltip: targets[3].descriptionHelp}}
                  placeholder='Не знаю'
                  formik={props}
                  units={targets[3].units}
                  withError
                />
              </Col>
              <Col>
                <Input
                  name='2:2'
                  mask='999'
                  label={{text: targets[2].descriptionSurvey, tooltip: targets[2].descriptionHelp}}
                  placeholder='Не знаю'
                  formik={props}
                  units={targets[2].units}
                  withError
                />
              </Col>
            </Row>
            <SurveySubtitle>Плавание</SurveySubtitle>
            <Row>
              <Col style={{maxWidth: '47%'}}>
                <Input
                  name='3:5'
                  mask='99:99'
                  label={{text: targets[5].descriptionSurvey, tooltip: targets[5].descriptionHelp}}
                  maskChar='_'
                  placeholder='Не знаю'
                  formik={props}
                  units={targets[5].units}
                  withError/>
              </Col>
            </Row>

            <Button
              className={styles.submit}
              type='submit'
              solid
            >
              Продолжить
            </Button>
            <div className={styles.underline}>
              <span
                className={styles['underline__link']}
                onClick={() => handler(-1)}
              >
                Вернуться назад
              </span>
              <a
                href='https://trisystems.atlassian.net/servicedesk/customer/portals'
                target='_blank'
                rel='noreferrer'
                className={styles['underline__link']}
              >
                Нужна помощь?
              </a>
            </div>
          </Form>
        </Wrapper>
      )}
    </Formik>
  )

}

export default Step2

const Step2Schema = Yup.object().shape({
  '1:1': Yup.string()
    .min(2, 'Слишком маленькая мощность')
    .max(3, 'Слишком большая мощность'),
  '1:2': Yup.string()
    .min(2, 'Некорректное значение')
    .max(3, 'Некорректное значение'),
  '2:2': Yup.string()
    .min(2, 'Некорректное значение')
    .max(3, 'Некорректное значение'),
  '2:3': Yup.string()
    .matches(/^([0][0-9]:[0-5][0-9])$/, 'Введите время в формате ММ:СС'),
  '3:5': Yup.string()
    .matches(/^([0][0-6]:[0-5][0-9])$/, 'Введите темп в мин/100м')
})
