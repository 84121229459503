import React from 'react'
import { Form, Formik } from 'formik'
import { Col, Input, Paragraph, RadioTabGroup, Row, Title, Wrapper } from '../../../UI/Form'
import Button from '../../../UI/Button/Button'
import styles from '../../SignUp/SignUpForm.module.css'
import styled from 'styled-components'
import { normalizeBirthDate, separateName } from '../../../../utils/user'
import { useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
import * as Yup from 'yup'
import moment from 'moment'
import ym from 'react-yandex-metrika'
import ymGoals from '../../../../analytics/ymGoals'

const UPDATE_USER = loader('../../../../apollo/mutations/UpdateUser.graphql')

type Step1Values = {
  firstName: string
  birthDate: string
  gender: string
  height: string
  weight: string
}

const SurveyRow = styled(Row)`
  padding-top: 0 !important;
  padding-bottom: 0 !important;
`

const Step1 = ({ handler }: { handler: (addStepNum:number) => void }) => {
  const [updateUser] = useMutation(UPDATE_USER)

  function formSubmit(values: Step1Values, { setSubmitting }: any): void {
    setSubmitting(true)
    const { firstName, birthDate, gender, weight, height } = values
    const normalizedBirthDate = normalizeBirthDate(birthDate)
    const normalizedUser = {
      ...separateName(firstName),
      birthDate: normalizedBirthDate,
      gender,
      height: +height,
      weight: +weight,
    }

    updateUser({
      variables: {
        user: normalizedUser
      }
    })
      .then(() => {
        setSubmitting(false)
        ym('reachGoal', ymGoals.survey.firstStepSuccess)
        handler(1)
      })
      .catch((error) => {
        console.error(error.message)
        setSubmitting(false)
      })
  }

  return (
    <Formik
      initialValues={{
        firstName: '',
        birthDate: '',
        gender: '',
        height: '',
        weight: ''
      }}
      onSubmit={formSubmit}
      validationSchema={Step1Schema}
    >
      {(props) => (
        <Wrapper>
          <Form>
            <Title>Настройка профиля (1 и 2)</Title>
            <Paragraph>Добро пожаловать в семью Trisystems. Для начала работы нам нужно узнать немного о вас.</Paragraph>
            <Row>
              <Col>
                <Input name='firstName' placeholder='Ваше имя' formik={props} withError/>
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  name='birthDate'
                  mask='99.99.9999'
                  maskChar='_'
                  placeholder='дд.мм.гггг'
                  label='Дата рождения'
                  formik={props} withError
                />
              </Col>
            </Row>
            <SurveyRow>
              <Col>
                <RadioTabGroup
                  label='Пол'
                  name='gender'
                  values={[
                    { id: 'male', value: 'm', labelValue: 'Мужской'},
                    { id: 'female', value: 'f', labelValue: 'Женский'}
                  ]}
                  withError
                />
              </Col>
            </SurveyRow>
            <SurveyRow>
              <Col>
                <Input label='Рост' units='см' mask='999' name='height' placeholder='Ваш рост' formik={props} withError/>
              </Col>
              <Col>
                <Input label='Вес' units='кг' mask='999' name='weight' placeholder='Ваш вес' formik={props} withError/>
              </Col>
            </SurveyRow>
            <Button
              className={styles.submit}
              type='submit'
              solid
            >
              Продолжить
            </Button>
            <div className={styles.underline}>
              <a
                href='https://trisystems.atlassian.net/servicedesk/customer/portals'
                target='_blank'
                rel='noreferrer'
                className={styles['underline__link']}
              >
                Нужна помощь?
              </a>
            </div>
          </Form>
        </Wrapper>
      )}
    </Formik>
  )
}

export default Step1

const Step1Schema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[а-яА-ЯёЁa-zA-Z ]+$/, {
      message: 'Используйте только буквы',
    })
    .min(2, 'Слишком короткое имя')
    .max(50, 'Слишком длинное имя')
    .required('Введите ваше имя'),
  birthDate: Yup.string()
    .matches(/^\d{2}[.]\d{2}[.]\d{4}$/, {
      message: 'Некорректное значение',
    })
    .test('Existing date', 'Такой даты не существует', (value) => (
      moment(value, 'DD.MM.YYYY').format('YYYY-MM-DD') !== 'Invalid date'
    ))
    .required('Введите вашу дату рождения'),
  gender: Yup.string()
    .required('Выберите ваш пол'),
  height: Yup.number()
    .min(100, 'Слишком маленький рост')
    .max(250, 'Слишком большой рост')
    .required('Введите ваш рост'),
  weight: Yup.number()
    .min(30, 'Вес слишком маленький')
    .max(150, 'Вес слишком большой')
    .required('Введите ваш вес'),
})
