import styled from 'styled-components';

export const SubTitle = styled.h3`
  color: #828282;
  font-weight: 500;
  margin: 0;
  padding: 4px 0 3px;
  font-size: .75rem;
  text-transform: uppercase;
`

export const PlayerWrapper = styled.div`
  padding: 10px 0 0;
  margin: 10px 0;
  overflow: hidden;
`
