import {Row} from '../../../../UI/Modal';
import Skeleton from 'react-loading-skeleton';
import React from 'react';

export const PaymentPlanSkeleton = () => {
  return (
    <>
      <Row>
        <Skeleton height={50} />
      </Row>
      <Row>
        <Skeleton height={50} />
      </Row>
      <Row>
        <Skeleton height={50} />
      </Row>
    </>
  )
}
