import React, {memo, useState} from 'react';
import {useApolloClient, useMutation, useQuery, useReactiveVar} from '@apollo/client';
import {Option} from 'react-select/src/filters';
import {Form, Formik, FormikValues} from 'formik';
import * as Yup from 'yup';
import format from 'date-fns/format';
import ru from 'date-fns/locale/ru';
import Loader from 'react-loader-spinner'

import {AddEventProps} from './types';
import {Col, Input, Paragraph, Row, Select} from '../../UI/Form';
import {getSelectorOptions} from '../../../utils/planbuilder';

import {
  Wrapper,
  StartWrapper,
  Title,
  SmallText,
  DateTitle,
  TypesList,
  TypeButton,
  WithBottomText
} from './styled';
import {CREATE_EVENT, FETCH_PROGRAMS} from './graphql';
import {BackButton, Buttons} from './styled';
import Button from '../../UI/Button/Button';
import {userEvents} from '../../../apollo/cache';

export const AddEvent: React.FC<AddEventProps> = memo(function AddEvent({setCurrentEvent, date}) {
  const [isLoading, setIsLoading] = useState(false)
  const [programs, setPrograms] = useState([]);
  const [programType, setProgramType] = useState<string>();
  const client = useApolloClient()
  const userEventsList = useReactiveVar(userEvents);

  const [createEvent] = useMutation(CREATE_EVENT)

  useQuery(FETCH_PROGRAMS, {
    variables: {
      programType: programType
    },
    onCompleted: (result) => setPrograms(result.trainingProgramms),
    onError: (error) => console.error(error.message)
  })

  function formSubmit(values: FormikValues) {
    setIsLoading(true)
    const newEvent = {
      title: values.eventName,
      date: format(date, 'yyyy-MM-dd'),
      priority: 'B',
      programm: values.eventType,
      notes: values.notes
    }
    createEvent({
      variables: {
        event: newEvent
      }
    })
      .then((result) => {
        const newEvent = result.data.eventCreate.event
        userEvents([...userEventsList, newEvent])
        client.resetStore()
          .then(() => setCurrentEvent(newEvent))
      })
      .catch((error) => {
        console.error(error.message)
        setIsLoading(false)
      })
  }

  function handleTypeClick(event: React.MouseEvent<HTMLDivElement>) {
    setProgramType(event.currentTarget.dataset.type)
  }

  return programType
        ? (
          <Wrapper>
            <Formik
              initialValues={{
                eventName: '',
                eventType: '',
                eventDate: format(date, 'dd.MM.yyyy'),
                priority: '',
                notes: ''
              }}
              onSubmit={formSubmit}
              validationSchema={AddEventSchema}
              validateOnBlur={false}
            >
              {(props) => {
                function selectChange(value: Option) {
                  props.setFieldValue('eventType', `${value.value}`)
                }

                return (
                  <Form>
                    <Title style={{paddingBottom: 10}}>Добавить событие</Title>
                    <Row>
                      <Col>
                        <Input
                          name='eventName'
                          label='Название события'
                          placeholder='Укажите название события'
                          formik={props}
                          withError
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Select
                          name='eventType'
                          label='Тип события'
                          placeholder='Выберите тип'
                          options={getSelectorOptions(programs)}
                          onChange={selectChange}
                          formik={props}
                          isLoading={programs.length === 0}
                          withError
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Input
                          name='eventDate'
                          label='Дата события'
                          formik={props}
                          withError
                          disabled
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Input
                          name='priority'
                          label='Приоритет события'
                          placeholder='B — Второстепенные старты'
                          formik={props}
                          disabled
                          withError
                        />
                        <SmallText>
                          В данный момент поддерживаются только второстепенные события. Создание основного старта доступно через конструктор плана.
                        </SmallText>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Input
                          as='textarea'
                          name='notes'
                          label='Заметки'
                          formik={props}
                          withError
                        />
                      </Col>
                    </Row>
                    <Buttons>
                      <Button type='submit' solid>
                        {isLoading
                          ? <Loader type='ThreeDots' color='#FFFFFF' height={10}/>
                          : 'Добавить событие'
                        }
                      </Button>
                      <BackButton type='button' onClick={() => setProgramType(undefined)}>Отменить</BackButton>
                    </Buttons>
                  </Form>
                )
              }}
            </Formik>
          </Wrapper>
        )
        : (
          <StartWrapper>
            <DateTitle style={{paddingBottom: 10}}>{format(date, 'EEEE, d MMMM yyyy', {locale: ru})}</DateTitle>
            <Row>
              <Paragraph style={{paddingTop: 15}}>Добавить события</Paragraph>
            </Row>
            <Row>
              <TypesList>
                <TypeButton data-type='secondary' onClick={handleTypeClick}>
                  Промежуточный старт
                </TypeButton>
                <TypeButton data-type='camp' onClick={handleTypeClick}>
                  Тестирования и сборы
                </TypeButton>
                <WithBottomText>
                  <TypeButton data-type='illness' onClick={handleTypeClick}>
                    Возвращение к тренировкам
                  </TypeButton>
                  <span>После болезни или отдыха.</span>
                </WithBottomText>
              </TypesList>
            </Row>
          </StartWrapper>
        )
})

const AddEventSchema = Yup.object().shape({
  eventName: Yup.string()
    .required('Укажите название события'),
  eventType: Yup.string()
    .required('Выберите тип события'),
  eventDate: Yup.string()
    .required('Укажите дату старта')
})
