import React, { useEffect } from 'react'
import ym from 'react-yandex-metrika'
import ReactGA from 'react-ga'
import Privacy from '../../components/Privacy/Privacy'

const PrivacyPage = () => {
  useEffect(() => {
    ym('hit', '/')
    ReactGA.pageview('/')
  }, [])
  return <Privacy />
}

export default PrivacyPage
