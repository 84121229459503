import {gql} from '@apollo/client';

export const GET_PAYMENT_TRANSACTION = gql`
    query GetPaymentTransaction($id: ID!) {
        paymentTransaction(id: $id) {
            id
            paid
            confirmed
            date
            status {
                value
            }
        }
    }
`
