import React, { useEffect } from 'react'
import MainLayout from '../../components/Layouts/Main/MainLayout'
import CodeFormContainer from '../../containers/CodeFormContainer'
import ym from 'react-yandex-metrika'
import ReactGA from 'react-ga'

const CodePage = () => {
  useEffect(() => {
    ym('hit', '/code')
    ReactGA.pageview('/code')
  }, [])

  return (
    <MainLayout>
      <CodeFormContainer />
    </MainLayout>
  )
}

export default CodePage
