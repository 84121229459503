import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { routes } from '../routeConfig'
import Modal from '../components/UI/Modal/Modal'
import Subscription from '../components/Subscription/Subscription'
import { addPaddingToShiftElements, getScrollBarWidth, removePaddingFromShiftElements } from '../utils/window'

const SubscriptionContainer = () => {
  const history = useHistory()
  const [isOpen, setIsOpen] = useState(true)
  const scrollBarWidth = getScrollBarWidth()

  useEffect(() => {
    addPaddingToShiftElements(scrollBarWidth)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function closeModal() {
    removePaddingFromShiftElements()
    setIsOpen(false)
    history.push(routes.calendar)
  }

  return (
    <Modal isOpen={isOpen} closeHandler={closeModal} portalName='SubscriptionModal'>
      <Subscription/>
    </Modal>
  )
}

export default SubscriptionContainer
