import React, {memo, useState} from 'react';
import {gql, useApolloClient, useMutation, useReactiveVar} from '@apollo/client';
import Modal from '../../../UI/Modal/Modal'
import { Col, Paragraph, Row, Title } from '../../../UI/Form'

import {Wrapper, ModalStyles} from './styled';
import {DeleteModalProps} from './types';
import {SubmitButton, BackButton, Buttons} from './styled';
import {userEvents} from '../../../../apollo/cache';
import Loader from 'react-loader-spinner';

const DELETE_EVENT = gql`
    mutation DeleteEvent($eventId: ID!) {
        eventDelete(id: $eventId) {
            responce {
                success
                message
            }
        }
    }
`

export const DeleteEventModal: React.FC<DeleteModalProps> = memo(function DeleteModal(
  {
    isOpen,
    closeHandler,
    eventId
  }
) {
  const [isLoading, setIsLoading] = useState(false)
  const client = useApolloClient()
  const [deleteEvent] = useMutation(DELETE_EVENT)
  const userEventsList = useReactiveVar(userEvents);

  function handleDelete() {
    setIsLoading(true)
    deleteEvent({
      variables: {
        eventId
      }
    })
      .then((result) => {
        if (result.data.eventDelete.responce.success) {
          userEvents(userEventsList.filter(({id}) => id !== eventId))
          client.resetStore()
            .then(() => closeHandler({quite: true}))
        } else {
          console.error(result.data.eventDelete.responce.message)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        console.error(error.message)
        setIsLoading(false)
      })
  }

  return (
    <Modal
      isOpen={isOpen}
      closeHandler={closeHandler}
      portalName='ExportModal'
      customStyles={ModalStyles}
    >
      <Wrapper>
        <Title>Удалить событие</Title>
        <Row>
          <Col>
            <Paragraph style={{maxWidth: 400}}>Вы действительно хотите удалить событие? Тренировочная программа будет пересчитана.</Paragraph>
          </Col>
        </Row>
        <Row>
          <Buttons>
            <SubmitButton type='submit' solid onClick={handleDelete}>
              {isLoading
                ? <Loader type='ThreeDots' color='#FFFFFF' height={10}/>
                : 'Удалить'
              }
            </SubmitButton>
            <BackButton type='button' onClick={closeHandler}>Вернуться назад</BackButton>
          </Buttons>
        </Row>
      </Wrapper>
    </Modal>
  )
})
