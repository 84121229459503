import {useReactiveVar} from '@apollo/client';
import {userEvents} from '../apollo/cache'

import isFuture from 'date-fns/isFuture'
import parseISO from 'date-fns/parseISO'
import compareAsc from 'date-fns/compareAsc';

type useCalendarEventsOptions = {
  priority?: 'A' | 'B';
  time?: 'future' | 'past'
  nearest?: boolean;
}

export function useCalendarEvents({priority, time, nearest}: useCalendarEventsOptions): UserEvent[] {
  const events = useReactiveVar(userEvents)

  if (!events) {
    return []
  }

  const eventsAsc = [...events].sort((eventA, eventB) => (
    compareAsc(parseISO(eventA.date), parseISO(eventB.date))
  ))

  const eventsByCategory = priority
    ? eventsAsc.filter((event) => event.priority === priority)
    : eventsAsc

  const eventsByTime = time
    ? eventsByCategory.filter((event) => {
      const date = parseISO(event.date)
      return time === 'future' ? isFuture(date) : !isFuture(date)
    })
    : eventsByCategory

  if (nearest) {
    return [eventsByTime[0]]
  }

  return eventsByTime
}
