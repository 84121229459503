import {gql} from '@apollo/client';

export const FETCH_WEEK_CALENDAR = gql`
    query GetWeekCalendar($dateMin: Date, $dateMax: Date) {
        calendar(dateMin: $dateMin, dateMax: $dateMax) {
            workouts {
                id
                title
                order
                date
                sport {
                    id
                    title
                    short
                    slug
                }
                totalTimePlanned
                completed
                plot
                isPaywall
                plan {
                    id
                    title
                }
            }
            programms {
                id
                title
                startDate
                endDate
                plans {
                    id
                    title
                }
            }
            events {
                id
                title
                date
                priority
                programm {
                    id
                    title
                }
                plan {
                    title
                }
                notes
                description {
                    title
                    content
                    type
                    testingProtocol {
                        title
                        methodId
                        input {
                            title
                            helpText
                            type
                            target {
                                id
                            }
                        }
                    }
                }
            }
        }
    }
`
