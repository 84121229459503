import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { routes } from '../../routeConfig'
import getYear from 'date-fns/getYear'

const Container = styled.div`
  max-width: 760px;
  padding: 75px 0;
  margin: 0 auto;
  & a {
    color: #ff8562
  }
`

const H1 = styled.h1`
  font-size: 40px;
  margin: 0;
  padding: 0 0 30px;
`

const H2 = styled.h2`
  font-size: 24px;
  margin: 0;
  padding: 15px 0;
`

const H3 = styled.h3`
  font-size: 20px;
  margin: 0;
  padding: 15px 0;
`

const Paragraph = styled.p`
  margin: 0;
  padding: 10px 0;
  font-weight: 300;
  line-height: 1.55;
`

const Strong = styled.strong`
  font-weight: 700;
`

const BullList = styled.ul`
  padding: 0 0 0 20px;
`

const ListItem = styled.li`
  padding: 2px 0;
  font-weight: 300;
  line-height: 1.55;
`

const Privacy = () => {
  return (
    <Container>
      <H1>Privacy Policy Trisystems</H1>
      <H2>Introduction</H2>
      <Paragraph>Our privacy policy (the "Privacy Policy") explains the information we collect, how we use and share it, how to manage your privacy controls and your rights in connection with our websites and the related mobile applications and services (collectively, the "Services"). Please also read our Terms of Service which sets out the terms governing the Services.</Paragraph>
      <Paragraph>Trisystems is headquartered in Saint Petersburg and our Services are provided to you by Trisystems, Inc. If you are a resident of the European Economic Area ("EEA"), Trisystems, Inc. is the controller of your personal data for the purposes of EEA data protection law.</Paragraph>
      <Paragraph>This policy was written in English. To the extent a translated version conflicts with the English version, the English version controls. Unless indicated otherwise, this Privacy Policy does not apply to third party products or services or the practices of companies that we do not own or control, including other companies you might interact with on or through the Services.</Paragraph>
      <Paragraph>Questions or comments about this Privacy Policy may be submitted by mail to the address below.</Paragraph>
      <Paragraph>
        <Strong>Trisystems, Inc.</Strong>
        <br />
        European pr. 9/1-109
        <br />
        Saint Petersburg, 188986
        <br />
        Russia
        <br />
        Attn: Legal
        <br />
        info.tri.systems@gmail.com
      </Paragraph>

      <H2>Information Trisystems Collects</H2>
      <Paragraph>Trisystems collects information about you, including information that directly or indirectly identifies you, if you or your other members choose to share it with Trisystems. We receive information in a few different ways, including when you track, complete or upload activities using the Services. Trisystems also collects information about how you use the Services. There are also several opportunities for you to share information about yourself, your friends, and your activities with Trisystems. For example:</Paragraph>
      <H3>Account, Profile, Activity, and Use Information</H3>
      <Paragraph>We collect <Strong>basic account information</Strong> such as your name, email address, date of birth, gender, weight and password that helps secure and provide you with access to our Services.</Paragraph>
      <Paragraph><Strong>Activity and use information</Strong> is collected about you when you choose to upload a picture, activity (including date, time and geo-location information as well as your speed and pace and perceived exertion) or otherwise use the Services.</Paragraph>
      <Paragraph>We use your <Strong>contact information</Strong> so we can respond to your support requests and comments.</Paragraph>
      <H3>Connected Devices and Apps</H3>
      <Paragraph>Trisystems collects information from <Strong>devices and apps you connect to</Strong> Trisystems. For example, you may connect your Garmin watch to Trisystems and information from these devices and apps will be passed along to Trisystems.</Paragraph>
      <H3>Health Information</H3>
      <Paragraph>Trisystems may collect or infer <Strong>health information</Strong>. Certain health information may be inferred from sources such as heart rate or other measurements, including power, cadence, and weight or other indicators. By uploading health information to Trisystems, you giving your explicit consent to the processing of that health information by Trisystems. You can withdraw your consent to Trisystems processing your health information at any time.</Paragraph>
      <H3>Payment Information</H3>
      <Paragraph>When you make a payment on Trisystems, you may provide <Strong>payment information</Strong> such as your payment card or other payment details. We use Payment Card Industry compliant third-party payment services and we do not store your credit card information.</Paragraph>
      <H3>Technical Information and Log Files</H3>
      <Paragraph>We collect information from your browser, computer, or mobile device, which provide us with technical information when you access or use the Services. This technical information includes device and network information, cookies, log files and analytics information. Learn more about how we use cookies and manage your preferences by visiting our Cookies Policy.</Paragraph>
      <Paragraph>The Services use <Strong>log files</Strong>. The information stored in those files includes IP addresses, browser type, internet service provider (ISP), referring/exit pages, platform type, date/time stamp, and number of clicks. This information is used to analyze trends, administer, protect and secure the Services, track member movement in the aggregate, and gather broad demographic information for aggregate use. IP addresses may be linked to session IDs, athlete IDs and device identifiers.</Paragraph>
      <H3>Other Information</H3>
      <Paragraph>We may <Strong>collect information from you through third parties</Strong>, such as when we collect your feedback through surveys.</Paragraph>

      <H2>How Trisystems Uses Information</H2>
      <Paragraph>Trisystems uses the information we collect and receive as described below.</Paragraph>
      <H3>To provide the Services</H3>
      <Paragraph>We use the information we collect and receive to provide the Services, including providing you with the ability to manage your training.</Paragraph>
      <Paragraph>Subject to your privacy controls, your information, including parts of your profile, your activities will be shared on Trisystems so that you may be able to participate in the Services. Your precise location information, such as where you run or ride, may also be shared on Trisystems or to non-members, in accordance with your privacy controls.</Paragraph>
      <H3>To customize your experience</H3>
      <Paragraph>We use the information we collect about you, your activities to customize your experience.</Paragraph>
      <H3>To improve our Services</H3>
      <Paragraph>We also use the information we collect to analyze, develop and improve the Services. To do this, Trisystems may use third-party analytics providers to gain insights into how our Services are used and to help us improve the Services.</Paragraph>
      <H3>To communicate with you</H3>
      <Paragraph>We use the information we collect to provide support in response to your requests and comments.</Paragraph>
      <Paragraph>We may also use the information we collect to market and promote the Services, activities and events on Trisystems, and other commercial products or services. This includes marketing and push communications, where you have not opted out of receiving such messages and notifications.</Paragraph>
      <H3>To process your subscription</H3>
      <Paragraph>We use the information we collect to process your subscription.</Paragraph>
      <H3>Aggregate Information</H3>
      <Paragraph><Strong>We do not sell your personal information</Strong>. Trisystems may aggregate the information you and others make available in connection with the Services and post it publicly or share it with third parties. Examples of the type of information we may aggregate include information about equipment, usage, demographics and performance.</Paragraph>

      <H2>How Information is Shared</H2>
      <Paragraph>In addition to sharing aggregated data about our members as described above, we also share personal information in accordance with your preferences, as needed to run our business and provide the Services, and where required for legal purposes, as set forth below.</Paragraph>
      <H3>Service Providers</H3>
      <Paragraph>We may share your information with third parties who provide services to Trisystems such as supporting, improving, promoting and securing the Services, processing payments, or fulfilling orders. These service providers only have access to the information necessary to perform these limited functions on our behalf and are required to protect and secure your information. We may also engage service providers to collect information about your use of the Services over time on our behalf, so that we or they may promote Trisystems or display information that may be relevant to your interests on the Services or other websites or services.</Paragraph>
      <H3>Sharing Information and Activities</H3>
      <Paragraph>We don't share your information with other Trisystems members and the public.</Paragraph>
      <H3>Third Party Business via API or Other Integrations</H3>
      <Paragraph>We enable you to share your information and content with third party apps, plugins, or websites that integrate with the Services, as well as with third parties who work with Trisystems to offer an integrated feature, such as a challenge sponsor or tracking watch. You can choose to share your profile data and activity data (including private activities). Information collected by these third parties is subject to their terms and policies. Trisystems is not responsible for the terms or policies of third parties.</Paragraph>
      <H3>Affiliates and Acquirors of our Business or Assets</H3>
      <Paragraph>We may share your information with affiliates under common control with us, who are required to comply with the terms of this Privacy Policy with regard to your information. If Trisystems becomes involved in a business combination, securities offering, bankruptcy, reorganization, dissolution or other similar transaction, we may share or transfer your information in connection with such transaction.</Paragraph>
      <H3>Legal Requirements</H3>
      <Paragraph>We may preserve and share your information with third parties, including law enforcement, public or governmental agencies, or private litigants, within or outside your country of residence, if we determine that such disclosure is allowed by the law or reasonably necessary to comply with the law, including to respond to court orders, warrants, subpoenas, or other legal or regulatory process. We may also retain, preserve or disclose your information if we determine that this is reasonably necessary or appropriate to prevent any person from death or serious bodily injury, to address issues of national security or other issues of public importance, to prevent or detect violations of our <Link to={routes.tos}>Terms of Service</Link> or fraud or abuse of Trisystems or its members, or to protect our operations or our property or other legal rights, including by disclosure to our legal counsel and other consultants and third parties in connection with actual or potential litigation.</Paragraph>
      <H3>DMCA Notices</H3>
      <Paragraph>We may share your information with third parties when we forward Digital Millennium Copyright Act (DMCA) notifications, which will be forwarded as submitted to us without any deletions.</Paragraph>
      <H3>How We Protect Information</H3>
      <Paragraph>We take several measures to safeguard the collection, transmission and storage of the data we collect. We employ reasonable protections for your information that are appropriate to its sensitivity. The Services use industry standard Secure Sockets Layer (SSL) technology to allow for the encryption of personal information and credit card numbers. Trisystems engages providers that are industry leaders in online security, including Services verification, to strengthen the security of our Services. The Services are registered with site identification authorities so that your browser can confirm Trisystems's identity before any personal information is sent. In addition, Trisystems's secure servers protect this information using advanced firewall technology.</Paragraph>

      <H2>Managing Your Settings</H2>
      <H3>Updating Account Information</H3>
      <Paragraph>You may correct, amend or update profile information at any time by adjusting that information in your account settings.</Paragraph>
      <H3>Deleting Information and Accounts</H3>
      <Paragraph>You can delete your account in the settings.</Paragraph>
      <Paragraph>After you make a deletion request, we permanently and irreversibly delete your personal data from our systems, including backups. Once deleted, your data, including your account, activities and place on leaderboards cannot be reinstated. Following your deletion of your account, it may take up to 90 days to delete your personal information and system logs from our systems. Additionally, we may retain information where deletion requests are made to comply with the law and take other actions permitted by law.</Paragraph>
      <H3>Your Rights and Our Legal Bases</H3>
      <Paragraph>We provide the same suite of privacy tools and controls to all of our members worldwide.</Paragraph>
      <H3>Your Legal Rights in the EEA</H3>
      <Paragraph>If you are habitually located in the EEA, you have the right to access, rectify, download or erase your information, as well as the right to restrict and object to certain processing of your information.</Paragraph>
      <H3>Our Legal Bases</H3>
      <Paragraph>Trisystems relies on a number of legal bases to collect, use, share, and otherwise process the information we have about you for the purposes described in this Privacy Policy, including:</Paragraph>
      <BullList>
        <ListItem>as necessary to provide the Services and fulfill our obligations pursuant to the Terms of Service. For example, we cannot provide the Services unless we collect and use your health information;</ListItem>
        <ListItem>where you have consented to the processing;</ListItem>
        <ListItem>where necessary to comply with a legal obligation, a court order, or to exercise and defend legal claims;</ListItem>
        <ListItem>to protect your vital interests, or those of others, such as in the case of emergencies;</ListItem>
      </BullList>
      <H3>Transfers</H3>
      <Paragraph>The Services are operated from Russia. If you are located outside of Russia and choose to use the Services or provide information to us, you acknowledge and understand that your information will be transferred, processed and stored in Russia as it is necessary to provide the Services and perform the Terms of Service. Russian privacy laws may not be as protective as those in your jurisdiction.</Paragraph>
      <H3>Retention of Information</H3>
      <Paragraph>We retain information as long as it is necessary to provide the Services to you and others, subject to any legal obligations to further retain such information. Information associated with your account will generally be kept until it is no longer necessary to provide the Services or until your account is deleted. In addition, you can delete some items of information (e.g., profile information) and you can remove individual activities from view on the Services without deleting your account. For example, after you withdraw your consent to Trisystems processing your health-related information. Additionally, we may retain information to comply with the law, prevent fraud, collect fees, resolve disputes, troubleshoot problems, assist with investigations, enforce the Terms of Service and take other actions permitted by law. The information we retain will be handled in accordance with this Privacy Policy.</Paragraph>
      <Paragraph>Information connected to you that is no longer necessary and relevant to provide our Services may be de-identified or aggregated with other non-personal data to provide insights which are commercially valuable to Trisystems, such as statistics of the use of the Services. This information will be de-associated with your name and other identifiers.</Paragraph>
      <H3>Other Trisystems Sites</H3>
      <Paragraph>Trisystems maintains certain websites that can be accessed outside of <a href='https://trisystems.ru/' target='_blank' rel='noreferrer'>https://trisystems.ru/</a>. The Other Sites maintain the look and feel of the Services, but are hosted by outside service providers with their own terms and privacy policies. If you interact with the Other Sites, your information may be stored, processed, or shared outside of the Services. If you interact with the Other Sites, you acknowledge that you may be subject to the terms and conditions and policies applicable to such Other Site. Please be aware that any personal information you submit to the Other Sites may be read, collected, or used by other users of these forums indefinitely, and could be used to send you unsolicited messages. Trisystems is not responsible for the personal information you choose to submit via the Other Sites.</Paragraph>
      <H3>Privacy Policy Information</H3>
      <Paragraph>Trisystems reserves the right to modify this Privacy Policy at any time. Please review it occasionally. If Trisystems makes changes to this Privacy Policy, the updated Privacy Policy will be posted on the Services in a timely manner and, if we make material changes, we will provide a prominent notice. If you object to any of the changes to this Privacy Policy, you should stop using the Services and delete your account.</Paragraph>
      <Paragraph>© {getYear(new Date())} Trisystems</Paragraph>
    </Container>
  )
}

export default Privacy
