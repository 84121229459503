import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Modal from '../components/UI/Modal/Modal'
import {Event} from '../components/Event';
import { addPaddingToShiftElements, getScrollBarWidth, removePaddingFromShiftElements } from '../utils/window'

type EventContainerProps = {
  event?: UserEvent
  closeHandler: () => void
  date?: Date
}

const EventWrapper = styled.div`
  max-width: 950px;
  padding: 25px 20px;
`

export const EventContainer = ({ event, closeHandler, date }: EventContainerProps) => {
  const [currentEvent, setCurrentEvent] = useState(event)
  const [isOpen, setIsOpen] = useState(true)
  const scrollBarWidth = getScrollBarWidth()

  useEffect(() => {
    addPaddingToShiftElements(scrollBarWidth)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function closeEventModal() {
    removePaddingFromShiftElements()
    setIsOpen(false)
    closeHandler()
  }

  return (
    <Modal
      isOpen={isOpen}
      closeHandler={closeEventModal}
      portalName='EventModal'
    >
      <EventWrapper>
        <Event
          event={currentEvent}
          closeEventModal={closeEventModal}
          setCurrentEvent={setCurrentEvent}
          date={date}
        />
      </EventWrapper>
    </Modal>
  )
}
