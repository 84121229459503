import React, {memo} from 'react';
import ReactPlayer from 'react-player';

import {DescriptionTypes} from './enums';
import {EventDescriptionProps} from './types';
import {SubTitle, PlayerWrapper} from './styled';
import { Markdown } from '../../Markdown'

export const EventDescription: React.FC<EventDescriptionProps> = memo(function EventDescription({description}) {

  if (!description) {
    return null
  }

  return (
    <>
      {description.length !== 0 && <SubTitle>Описание</SubTitle>}
      {description.map(({ type, title, content, testingProtocol }, index) => {
        switch (type) {
          case DescriptionTypes.Text:
            return (
              <div key={index + type}>
                {title && <SubTitle>{title}</SubTitle>}
                <Markdown content={content}/>
              </div>
            )
          case DescriptionTypes.Media:
            return (
              <PlayerWrapper key={index + type}>
                {title && <SubTitle>{title}</SubTitle>}
                <ReactPlayer style={{marginTop: 8}} url={content} width='100%' height='480px' controls={true}/>
              </PlayerWrapper>
            )
          default: {
            return null
          }
        }
      })}
    </>
  )
})
