import React, { SyntheticEvent, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import styled from 'styled-components'
import Status from './tabs/Status'
import Activate from './tabs/Activate'
import CertificateForm from './tabs/Certificate'
import { PaymentPlan } from './tabs/PaymentPlan'
import Cancel from './tabs/Cancel'
import PaymentData from './tabs/PaymentData'
import { Content, Header, Main, Sidebar, Tab, Tabs } from '../UI/Modal'
import Skeleton from 'react-loading-skeleton'

const GET_USER = loader('../../apollo/queries/User.graphql')

const Wrapper = styled.div`
  max-width: 950px;
`

const SubscriptionContent = styled(Content)`
  min-width: 730px;
`

const tabs = [
  { slug: 'status', title: 'Статус', show: true },
  { slug: 'activate', title: 'Активировать', show: true },
  { slug: 'paymentData', title: 'Платежные данные', show: true },
  { slug: 'plan', title: 'Тарифный план', show: false },
  { slug: 'certificate', title: 'Подарочный сертификат', show: true },
  { slug: 'cancel', title: 'Отменить подписку', show: false }
]

const Subscription = () => {
  const history = useHistory()
  const urlParams = new URLSearchParams(history.location.search)
  const activeTab = urlParams.get('tab') || tabs[0].slug
  const location = useLocation()
  const [skip, setSkip] = useState(false)

  const { loading: getUserLoading, error: getUserError, data } = useQuery(GET_USER, {
    skip,
    onError: (error) => console.error(error.message)
  })

  useEffect(() => {
    if (!urlParams.get('tab')) {
      history.push({
        pathname: location.pathname,
        search: `?tab=${activeTab}`
      })
    }
  })

  if (getUserError) {
    setSkip(true)
  }

  const user = data?.user

  function tabsRender() {
    if (getUserLoading) {
      return <Skeleton height={30} count={4} style={{margin: '15px 0 0 20px'}} width={180}/>
    }
    return (
      <Tabs>
        {tabs.map((tab) => {
          const isShowTab = checkTabVisibleStatus(tab)
          return isShowTab && (
            <Tab
              key={tab.slug}
              active={tab.slug === activeTab}
              onClick={switchTab}
              data-tab={tab.slug}
            >
              {tab.title}
            </Tab>
          )
        })}
      </Tabs>
    )
  }

  function contentRender() {
    switch (activeTab) {
      case 'status':
        return <Status switchTab={switchTab} user={user} />
      case 'activate':
        return <Activate />
      case 'certificate':
        return <CertificateForm switchTab={switchTab} user={user}  />        
      case 'plan':
        return <PaymentPlan switchTab={switchTab} user={user} />
      case 'paymentData':
        return <PaymentData user={user} />
      case 'cancel':
        return <Cancel switchTab={switchTab} user={user}/>
      default: {
        return null
      }
    }
  }

  function checkTabVisibleStatus(tab: { slug: string; title: string; show: boolean; }) {
    if (!user && tab.slug !== 'status') {
      return false
    }

    if (user && user.paymentSubscription?.status === 'ACTIVE' && tab.slug === 'activate') {
      return false
    }

    if (user && !user.paymentMethod && tab.slug === 'paymentData') {
      return false
    }

    if (user && user.paymentSubscription?.status !== 'ACTIVE' && tab.slug === 'plan') {
      return false
    }

    return tab.show
  }

  function switchTab(event: SyntheticEvent | string) {
    let newTab
    if (typeof event !== 'string') {
      newTab = event.currentTarget.getAttribute('data-tab')
    } else {
      newTab = event
    }

    history.push({
      pathname: location.pathname,
      search: `?tab=${newTab}`
    })
  }

  return (
    <Wrapper>
      <Header>Подписка</Header>
      <Main>
        <Sidebar>
          { tabsRender() }
        </Sidebar>
        <SubscriptionContent>
          { contentRender() }
        </SubscriptionContent>
      </Main>
    </Wrapper>
  )
}

export default Subscription
