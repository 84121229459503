import styled from 'styled-components'

export const StyledChangePasswordToggle = styled.button<{active: boolean}>`
  position: relative;
  cursor: pointer;
  background: none;
  max-width: 112px;
  padding: 0;
  border: none;
  color: #565656;
  font-weight: bold;
  border-bottom: ${({active}) => active ? null : '1px dashed'};
  &:hover {
    border-color: transparent;
  }
`
