import React from 'react'
import ConfirmEmail from './ConfirmEmail/ConfirmEmail'
import ExpiringSubscription from './ExpiriningSubscription/ExpiringSubscription'
import styled from 'styled-components'

const ReminderWrapper = styled.div`
  position: absolute;
  top: 3px;
  left: 50%;
  margin-left: -265px;
  width: 600px;
`

const Reminder = () => {
  return (
    <ReminderWrapper>
      <ExpiringSubscription />
      <ConfirmEmail />
    </ReminderWrapper>
  )
}

export default Reminder
