import React from 'react'
import { Form, Formik, FormikValues } from 'formik'
import styles from '../Settings.module.css'
import { Input } from '../../UI/Form'
import { convertZoneThresholdToString, normalizeZones } from '../../../utils/user'
import { useApolloClient, useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
import { Col, Row } from '../../UI/Form'
import * as Yup from 'yup'
import { useTargets } from '../../../hooks/useTargets'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'

const UPDATE_ZONE = loader('../../../apollo/mutations/UpdateZone.graphql')

type ZonesFormProps = {
  zones: Array<UserZone>
  closeHandler: () => void
}

const InputWrapper = styled.div`
  max-width: 190px;
  position: relative;
`

const ZoneLvl = styled.span`
  cursor: help;
  position: absolute;
  right: 21px;
  top: 2px;
  font-size: .6875rem;
  font-weight: bold;
  color: #565656;
`

const ZonesForm = ({ zones: currentZones, closeHandler }: ZonesFormProps) => {
  const client = useApolloClient()
  const [updateZone] = useMutation(UPDATE_ZONE)
  const [targets] = useTargets({
    mapTo: 'id'
  })

  if (!targets || !currentZones) {
    return <ZonesFormSkeleton />
  }

  async function handleSubmit(values: FormikValues) {
    const newZones = normalizeZones(values)
    const updater = () => {
      let zonesUpdater: Array<Promise<any>> = []
      newZones.forEach((newZone) => {
        const prevZone = currentZones.find((prevZone) => prevZone.targetId === newZone.targetId && prevZone.sportId === newZone.sportId)
        if (prevZone?.threshold !== newZone.threshold) {
          zonesUpdater.push(updateZone({
            variables: {
              zone: newZone
            },
          }))
        }
      })
      return Promise.all(zonesUpdater)
    }

    try {
      await updater()
      await client.resetStore()
      closeHandler()
    } catch (error) {
      console.error(error.message)
    }
  }

  function renderZoneLvl(zones:Array<UserZone>, sid: string, tid: string) {
    const zone = currentZones.find(({ sportId, targetId }) => sportId === sid && targetId === tid)
    return zone?.lvl && <ZoneLvl title='Уровень зоны'>{zone?.lvl}</ZoneLvl>
  }

  function getInitialZones(zones: Array<UserZone>) {
    return zones.reduce((acc, zone) => {
      const key = `${zone.sportId}:${zone.targetId}`
      acc = {
        ...acc,
        [key]: convertZoneThresholdToString(zone.threshold, zone.targetId)
      }

      return acc
    }, {})
  }

  const initialValues = getInitialZones(currentZones)

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={SettingsZonesSchema}
    >
      {props => {
        return (
          <Form>
            <div className={`${styles.row} ${styles.zoneItemHeader}`}>
              <div className={styles.zoneTitle}>Велоспорт</div>
            </div>
            <Row>
              <Col>
                <InputWrapper>
                  <Input
                    label={{text: targets[1].descriptionSurvey, tooltip: targets[1].descriptionHelp}}
                    mask='999'
                    name='1:1'
                    placeholder='Мощность'
                    formik={props}
                    units={targets[1].units}
                    withError
                  />
                  {renderZoneLvl(currentZones, '1', '1')}
                </InputWrapper>
              </Col>
              <Col>
                <InputWrapper>
                  <Input
                    label={{text: targets[2].descriptionSurvey, tooltip: targets[2].descriptionHelp}}
                    mask='999'
                    name='1:2'
                    placeholder='Пульс'
                    formik={props}
                    className={styles.zoneField}
                    units={targets[2].units}
                    withError
                  />
                  {renderZoneLvl(currentZones, '1', '2')}
                </InputWrapper>
              </Col>
            </Row>
            <Row className={styles.zoneItemHeader}>
              <div className={styles.zoneTitle}>Бег</div>
            </Row>
            <Row>
              <Col>
                <InputWrapper>
                  <Input
                    label={{text: targets[3].descriptionSurvey, tooltip: targets[3].descriptionHelp}}
                    mask='99:99'
                    maskChar='_'
                    name='2:3'
                    placeholder='ММ:CC'
                    formik={props}
                    className={styles.zoneField}
                    units={targets[3].units}
                    withError
                  />
                  {renderZoneLvl(currentZones, '2', '3')}
                </InputWrapper>
              </Col>
              <Col>
                <InputWrapper>
                  <Input
                    label={{text: targets[2].descriptionSurvey, tooltip: targets[2].descriptionHelp}}
                    mask='999'
                    name='2:2'
                    placeholder='Пульс'
                    formik={props}
                    className={styles.zoneField}
                    units={targets[2].units}
                    withError
                  />
                  {renderZoneLvl(currentZones, '2', '2')}
                </InputWrapper>
              </Col>
            </Row>
            <Row className={styles.zoneItemHeader}>
              <div className={styles.zoneTitle}>Плавание</div>
            </Row>
            <Row>
              <Col>
                <InputWrapper>
                  <Input
                    label={{text: targets[5].descriptionSurvey, tooltip: targets[5].descriptionHelp}}
                    mask='99:99'
                    maskChar='_'
                    name='3:5'
                    placeholder='ММ:CC'
                    formik={props}
                    className={styles.zoneField}
                    units={targets[5].units}
                    withError
                  />
                </InputWrapper>
              </Col>
            </Row>
            <Row>
              <button type='submit' className={styles.submit}>Сохранить и закрыть</button>
            </Row>
          </Form>
        )
      }}
    </Formik>
  )
}

export default ZonesForm

const SettingsZonesSchema = Yup.object().shape({
  '1:1': Yup.string()
    .min(2, 'Слишком маленькая мощность')
    .max(3, 'Слишком большая мощность'),
  '1:2': Yup.string()
    .min(2, 'Слишком маленький пульс')
    .max(3, 'Слишком большой пульс'),
  '2:2': Yup.string()
    .min(2, 'Слишком маленький пульс')
    .max(3, 'Слишком большой пульс'),
  '2:3': Yup.string()
    .matches(/^([0-5][0-9]:[0-5][0-9]|_{2}:_{2})$/, 'Укажите время в формате ММ:СС'),
  '3:5': Yup.string()
    .matches(/^([0-5][0-9]:[0-5][0-9]|_{2}:_{2})$/, 'Укажите время в формате ММ:СС')
})

const ZonesFormSkeleton = () => {
  return (
    <>
      <Row>
        <Col>
          <Skeleton height={25} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Skeleton height={50} />
        </Col>
        <Col>
          <Skeleton height={50} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Skeleton height={25} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Skeleton height={50} />
        </Col>
        <Col>
          <Skeleton height={50} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Skeleton height={25} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Skeleton height={50} />
        </Col>
        <Col>
          <Skeleton height={50} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Skeleton height={50} />
        </Col>
      </Row>
    </>
  )
}
