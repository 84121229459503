import React from 'react'
import { ErrorMessage, Field } from 'formik'
import styled from 'styled-components'
import { Row, Col } from './index'

type FormTabRadioProps = {
  label?: string
  name: string
  values: Array<{
    id: string
    value: string
    labelValue: string
  }>
  withError?: boolean
}

const FormTabRadioStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 6px 0;
  & > label {
    display: block;
    font-size: .75rem;
    color: #565656;
    width: 100%;
    padding-bottom: 6px;
  }
`

const FormTabRadioInputStyled = styled(Field)`
  position: absolute;
  display: none;
  & + label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
    height: 35px;
    color: #8E8E8E;
    background: #F7F7F7;
    border-radius: 2px;
    padding: 6px 15px;
    border: 1px solid #C4C4C4;
    font-size: .875rem;
  }
  &:checked + label {
    color: #444444;
    border: 1px solid #444444;
  }
`

const ErrorStyled = styled(ErrorMessage)`
  font-size: .75rem;
  color: #CC0000;
  padding: 3px 2px;
  line-height: 1.3;
`

const RadioTabGroupRow = styled(Row)`
  padding-top: 0 !important;
`

export const RadioTabGroup = ({ label, name, values, withError }: FormTabRadioProps) => {
  return (
    <FormTabRadioStyled role='group' aria-labelledby={`${name}-group`}>
      {label && <label>{label}</label>}
      <RadioTabGroupRow>
        {values.map(({ id, value, labelValue }) => (
          <Col key={id}>
            <FormTabRadioInputStyled id={id} type='radio' name={name} value={value}/>
            <label htmlFor={id}>{labelValue}</label>
          </Col>
        ))}
      </RadioTabGroupRow>
      {withError && <ErrorStyled name={name} component='div' />}
    </FormTabRadioStyled>
  )
}
