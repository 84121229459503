import React, {memo} from 'react'

import CalendarWorkoutChart from '../Rechart/CalendarWorkoutChart/CalendarWorkoutChart'
import {Wrapper, Header, Title, Chart, Time, Sport, CompleteButton} from './styled';
import {CalendarWorkoutProps} from './types';

export const CalendarWorkout: React.FC<CalendarWorkoutProps> = memo(function CalendarWorkout(props) {
  const { workout, completeHandler, cursorStyle = 'default', borderStyle } = props

  if (!workout) {
    return <p>Workout doesn't exist</p>
  }

  const { title, totalTimePlanned, sport, completed, plot } = workout
  const { slug, short } = sport

  return (
    <Wrapper cursorStyle={cursorStyle} borderStyle={borderStyle}>
      <Header>
        <Sport sportSlug={slug}>{short}</Sport>
      </Header>
      {completeHandler && (
        <CompleteButton
          type='button'
          title={completed ? 'Снять отметку "Выполнено"' : 'Отметить "Выполнено"'}
          completed={completed}
          onClick={completeHandler}
        />
      )}
      <Title>{title}</Title>
      <Time>{totalTimePlanned}</Time>
      <Chart>{Array.isArray(plot) && (
        <CalendarWorkoutChart chartData={plot} cursorStyle={cursorStyle}/>
      )}</Chart>
    </Wrapper>
  )
})
