import styled from 'styled-components';
import Button from '../../UI/Button/Button';

export const Wrapper = styled.div`
  width: 320px;
  margin: 0 auto;
`

export const StartWrapper = styled.div`
  padding: 15px 20px;
`

export const Title = styled.span`
  color: #3E3E3E;
  font-weight: 500;
  font-size: .875rem;
`

export const DateTitle = styled.span`
  color: #3E3E3E;
  font-weight: 500;
  font-size: .875rem;
  &:first-letter {
    text-transform: uppercase;
  }
`

export const SmallText = styled.span`
  color: #BDBDBD;
  font-size: .75rem;
  font-weight: normal;
  padding-top: 6px;
  line-height: 1.4;
`

export const Buttons = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  & button {
    margin-left: 25px;
    &:first-child {
      margin: 0;
    }
  }
`

export const BackButton = styled(Button)`
  border: none;
  color: #2F80ED;
  padding: 0;
  &:hover {
    background: none;
    border: none;
    color: #2A75DB;
    text-decoration: underline;
  }
`

export const StartDate = styled.span<{error: boolean}>`
  display: inline-block;
  font-weight: ${props => props.error ? 700 : 500};
  margin-left: 5px;
  color: ${props => props.error && '#CC0000'};
`

export const TypesList = styled.div`
  width: 100%;
  display: flex;
`

export const TypeButton = styled.div<{disabled?: boolean}>`
  cursor: ${({disabled}) => disabled ? 'default' : 'pointer'};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({disabled}) => disabled ? '#A5A5A5' : '#000000'};
  background-color: #EBEBEB;
  padding: 18px 24px;
  font-size: .875rem;
  height: 52px;
  &:not(:first-child) {
    margin-left: 24px;
  }
  &:hover {
    text-decoration: none;
    background-color: ${({disabled}) => disabled ? '#EBEBEB' : '#E4E4E4'};
  }
`

export const WithBottomText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  & span {
    font-size: .75rem;
    color: #929292;
    margin-top: 16px;
  }
`
