import React from 'react'
import styles from './CalendarTop.module.css'
import Button from '../UI/Button/Button'
import addMonths from 'date-fns/addMonths'
import subMonths from 'date-fns/subMonths'
import parseISO from 'date-fns/parseISO'
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
import parse from 'date-fns/parse'
import format from 'date-fns/format'
import formatDuration from 'date-fns/formatDuration'
import ru from 'date-fns/locale/ru'
import { useReactiveVar } from '@apollo/client'
import { calendarCurrentMonth, userCurrentProgram } from '../../apollo/cache'
import { useCalendarTools } from '../../hooks/useCalendar'
import { useCalendarEvents } from '../../hooks/useCalendarEvents';

const weeklineElements = ['Недель', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье']

const CalendarTop = () => {
  const today = new Date()
  const calendarTools = useCalendarTools()
  const currentMonth = useReactiveVar(calendarCurrentMonth)
  const currentProgram = useReactiveVar(userCurrentProgram)
  const toDateCurrentMonth = parse(currentMonth!, 'yyyy/MM', new Date())

  function toPrevMonth() {
    const prevMonthDate = subMonths(toDateCurrentMonth, 1)
    const prevMonth = format(prevMonthDate, 'yyyy/MM')
    calendarTools.scrollToMonth(prevMonth)
  }

  function toNextMonth() {
    const nextMonthDate = addMonths(toDateCurrentMonth, 1)
    const nextMonth = format(nextMonthDate, 'yyyy/MM')
    calendarTools.scrollToMonth(nextMonth)
  }

  const [nearestEvent] = useCalendarEvents({
    time: 'future',
    nearest: true
  })

  return (
    <section className={styles.top} data-modal='shift'>
      <div className={styles.headline}>
        <div className={styles.left}>
          <div className={styles.monthSelector}>
            <span className={styles.prevMonth} onClick={toPrevMonth}/>
            <div className={styles.currentMonth}>
              <p>
                {currentMonth
                  ? (format(toDateCurrentMonth, 'LLLL yyyy', {locale: ru}))
                  : 'Loading...'
                }
              </p>
            </div>
            <span className={styles.nextMonth} onClick={toNextMonth}/>
          </div>
          <Button className={styles.topBtn} onClick={calendarTools.scrollToToday}>Сегодня</Button>
        </div>
        <div className={styles.right}>
          {currentProgram && (
            <div className={styles.currentProgram}>
              <p>Текущая программа</p>
              <span>{currentProgram}</span>
            </div>
          )}
          {nearestEvent && (
            <div className={styles.event}>
              <div className={styles.eventBlockItem}>
                <p>основное событие</p>
                <span>{nearestEvent.title}</span>
              </div>
              <div className={styles.eventBlockItem}>
                <p>осталось</p>
                <span>{formatDuration({days: differenceInCalendarDays(parseISO(nearestEvent.date), today)}, {locale: ru})}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={styles.weekline}>
        <ul className={styles.weeklineList}>
          {weeklineElements.map((element:string, index:number) => (
            <li key={element + index} className={styles.weeklineListItem}>{element}</li>
          ))}
        </ul>
      </div>
    </section>
  )
}

export default React.memo(CalendarTop)
